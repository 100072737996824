import "./css/Toggle.css"

export default function Toggle({ label, name, checked, onChange, warning, warningText }) {
    return (
        <div className="slider-container">
            <div>
                <p>{label}</p>
                {warning && (
                    <p className="slider-warning">
                        {warningText}
                    </p>
                )}
            </div>
            <label className="switch">
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="slider"></span>
            </label>
        </div>
    )
}