import React from "react";
import { withTranslation } from "react-i18next";
import "./FirstResponderManagement.css";
import FirstResponderMenuItem from "../../components/firstRespondersManagement/FirstResponderMenuItem";
import Loader from "../../constants/Loader";
import { useState, useEffect } from "react";
import * as Queries from "../../graphql/queries";
import {
  deleteContractorFirstResponderCustom,
  updateContractorFirstResponderCustom,
  createContractorFirstRespondersStorm,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import Modal from "../../components/Modal/Modal";
import FirstResponderFormProvider from "../../components/firstRespondersManagement/FirstResponderFormProvider";
import Layout from "../Layout/Layout";
import ItemsTable from "../../components/Table/ItemsTable";
import { StatusCodes } from "../../constants/StatusCodes";
import { FIRST_RESPONDER_HEADERS } from "../../constants/TableHeaders";

const FirstResponderManagement = () => {
  const [firstResponders, setFirstResponders] = useState();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [createResponderModalOpen, setCreateResponderModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("An error occured");

  async function getFirstResponders() {
    try {
      setIsLoading(true);
      const values = await API.graphql(
        graphqlOperation(
          Queries.listContractorFirstRespondersByContractorIDCustom
        )
      );
      let contractorFirstResponders =
        values.data.listContractorFirstRespondersByContractorID;
      if (contractorFirstResponders?.statusCode === StatusCodes.OK) {
        let data = JSON.parse(contractorFirstResponders?.body);
        data.items.sort((a, b) => a.Name.localeCompare(b.Name, undefined, { numeric: true, sensitivity: 'base' }));
        setFirstResponders(data.items);
      } else {
        throw new Error("Error getting first responders");
      }
    } catch (error) {
      setErrorMessage("An error occured");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getFirstResponders();
  }, []);

  const handleDelete = async (id) => {
    let input = { id: id };
    try {
      setFirstResponders(firstResponders.filter((item) => item.id !== id));
      const response = await API.graphql(
        graphqlOperation(deleteContractorFirstResponderCustom, {
          input: input,
        })
      );
      if (
        response.data.deleteContractorFirstRespondersStorm.statusCode !== 200
      ) {
        throw new Error("Error deleting first responder");
      }

    } catch (error) {
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    } finally {
      await getFirstResponders();
    }
  };

  const handleUpdate = async (responderDetails) => {
    setIsLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(updateContractorFirstResponderCustom, {
          input: responderDetails,
        })
      );
      if (
        response.data.updateContractorFirstRespondersStorm.statusCode !== 200
      ) {
        throw new Error("Error updating first responder");
      }

      getFirstResponders();
    } catch (error) {
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    } finally {
      await getFirstResponders();
      setIsLoading(false);
    }
  };

  const handleCreate = async (responderDetails) => {
    setCreateResponderModalOpen(false);
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(createContractorFirstRespondersStorm, {
          input: responderDetails,
        })
      );
      if (
        response.data.createContractorFirstRespondersStorm.statusCode !== 200
      ) {
        throw new Error("An error occured while creating the responder");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    } finally {
      await getFirstResponders();
      setIsLoading(false);
    }
  };

  const handleSortBy = (header, sortOrder) => {
    const sortedResponders = [...firstResponders];
    if (header === "Responder name" || header === "Phone") {
      sortedResponders.sort((a, b) => {
        if (sortOrder === "asc") {
          return a[FIRST_RESPONDER_HEADERS[header]].localeCompare(b[FIRST_RESPONDER_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
        } else {
          return b[FIRST_RESPONDER_HEADERS[header]].localeCompare(a[FIRST_RESPONDER_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
        }
      });
    } else {
        if (sortOrder === "asc") {
          sortedResponders.sort((a, b) => {
            const availabilityAString = a.Availability[header].length === 0
              ? "Close"
              : a.Availability[header].map(slot => `${slot.Start}-${slot.Finish}`).join('');
            const availabilityBString = b.Availability[header].length === 0
              ? "Close"
              : b.Availability[header].map(slot => `${slot.Start}-${slot.Finish}`).join('');
            return availabilityAString.localeCompare(availabilityBString, undefined, { numeric: true, sensitivity: 'base' });
          })
        } else {
          sortedResponders.sort((a, b) => {
            const availabilityAString = a.Availability[header].length === 0
              ? "Close"
              : a.Availability[header].map(slot => `${slot.Start}-${slot.Finish}`).join('');
            const availabilityBString = b.Availability[header].length === 0
              ? "Close"
              : b.Availability[header].map(slot => `${slot.Start}-${slot.Finish}`).join('');
            return availabilityBString.localeCompare(availabilityAString, undefined, { numeric: true, sensitivity: 'base' });
          })
        }
    }
    setFirstResponders(sortedResponders);
  };

  return (
    <Layout
      headerText={"ELEVATOR MECHANICS MANAGEMENT"}
      headerButtonText="Create a responder"
      showSidebar={true}
      showFooter={false}
      onHeaderButtonClick={() => setCreateResponderModalOpen(true)}
    >
      {!firstResponders || isLoading ? (
        <Loader />
      ) : (
        <ItemsTable
          headers={FIRST_RESPONDER_HEADERS}
          onSortBy={handleSortBy}
        >
          {firstResponders &&
            firstResponders.map((item) => (
              <FirstResponderMenuItem
                key={item.id}
                item={item}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            ))}
        </ItemsTable>
      )}
      <div className="error-modal">
        <Modal
          isOpen={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
        >
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="modal"
            />
            <p>{errorMessage}</p>
          </>
        </Modal>
      </div>
      <div className="create-responder-modal">
        <Modal
          isOpen={createResponderModalOpen}
          headerText="CREATE A RESPONDER"
          onClose={() => setCreateResponderModalOpen(false)}
        >
          <FirstResponderFormProvider onSubmit={handleCreate} />
        </Modal>
      </div>
    </Layout>
  );
};
export default withTranslation()(FirstResponderManagement);
