import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import './css/AddPositionIndicatorForm.css';
import { TRAVEL_INFO_COLORS, TRAVEL_INFO_FONT_FAMILIES, TRAVEL_INFO_FONT_SIZES } from '../../constants/FormOptions';
import { DEFAULT_TRAVEL_INFO_PATH } from '../../constants/DefaultValues';

function AddPositionIndicatorForm({item, onClick, onClose }) {
    const [positionIndicator, setPositionIndicator] = useState(item);
    const [digit, setDigit] = useState("");
    const [fontFamily, setFontFamily] = useState("");
    const [fontSize, setFontSize] = useState("");
    const [fontColor, setFontColor] = useState("");

    function handleChange(e) {
        const value = e.target.value;
        const property = e.target.id;
        setDigit(value);
        setPositionIndicator({ ...positionIndicator, [property]: e.target.value });
    }

    function handleButtonClicked() {
        const staFileName = `${fontFamily}_${fontSize}_${fontColor}.sta`;
        positionIndicator.source = `${DEFAULT_TRAVEL_INFO_PATH}${fontSize}\\${fontColor}\\${staFileName}`;
        onClick(positionIndicator);
    }

    return (
        <div id="add-position-indicator-form">
            <Modal
                headerText="CREATE A POSITION INDICATOR"
                isOpen={true}
                onClose={onClose}
                buttonDisabled={!digit || !fontFamily || !fontSize || !fontColor}
                buttonText="Create"
                buttonClick={handleButtonClicked}
            >
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="digit">Number of digits</label>
                    <select id="digit" className='form-control' onChange={handleChange}>
                        <option value="">Select the number of digits</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="font">Font family</label>
                    <select id="font" className='form-control' onChange={(e) => setFontFamily(e.target.value)}>
                        <option value="">Select the font</option>
                        {TRAVEL_INFO_FONT_FAMILIES.map((font, index) => (
                            <option key={index} value={font}>{font}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="font-size">Font size</label>
                    <select id="font-size" className='form-control' onChange={(e) => setFontSize(e.target.value)}>
                        <option value="">Select the font size</option>
                        {TRAVEL_INFO_FONT_SIZES.map((size, index) => (
                            <option key={index} value={size}>{size}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group-input">
                    <label className="titreInput" htmlFor="color">Color</label>
                    <select id="color" className='form-control' onChange={(e) => setFontColor(e.target.value)}>
                        <option value="">Select the color</option>
                        {TRAVEL_INFO_COLORS.map((color, index) => (
                            <option key={index} value={color}>{color}</option>
                        ))}
                    </select>
                </div>
            </Modal>
        </div>
    );
};

export default AddPositionIndicatorForm;
