import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatRoom from "./containers/Emergency/ChatRoom/ChatRoom";
import Building from "./containers/listBuilding/listBuilding";
import Product from "./containers/product/product";
import "./App.css";
import { Authenticator } from "@aws-amplify/ui-react";
import Contractor from "./components/contractor/Contractor";
import UserManagement from "./containers/UserManagement/UserManagement";
import FirstResponderManagement from "./containers/firstResponders/FirstResponderManagement";
import ConfirmUser from "./components/userManagement/confirmUser";
import Visual from "./containers/visual/visual";
import SendInvitation from "./components/contractor/SendInvitation";
import Callees from "./containers/Callees/Callees";
import { components, formFields } from "./constants/AuthenticatorOptions";
import awsExports from "./aws-exports";
import { Amplify, Auth } from "aws-amplify";
import { CustomAttributes } from "./constants/CustomAttributes";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import EditVisual from "./components/EditVisual/EditVisual";
import { SharedProvider } from "./context/SharedProvider";
import Library from "./components/Library/Library";
import VidatechAdmin from "./components/VidatechAdmin/VidatechAdmin";
import Login from "./Auth/Login";
import { COGNITO_GROUPS } from "./constants/DefaultValues";
import ErrorPage from "./containers/ErrorPages/ErrorPage";
import { StatusCodes } from "./constants/StatusCodes";

Amplify.configure({
  ...awsExports,
  API: {
    graphql_headers: async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        return {
          Authorization: user.getSignInUserSession().getIdToken().getJwtToken(),
        };
      } catch (error) {
        return {};
      }
    },
  },
});

const App = () => {
  return (
    <>
      <div className="appStyle">
        <BrowserRouter>
          <Routes>
            <Route path="/chatroom/:channel" element={<ChatRoom />} />
            <Route path="/unauthorized" element={<ErrorPage errorCode={StatusCodes.UNAUTHORIZED} />} />
            <Route
              path="/*"
              element={
                <Authenticator components={components} formFields={formFields}>
                  <Login >
                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route
                        path="/accept-invitation/:token"
                        element={<ConfirmUser />}
                      />
                      <Route
                        path="/send-invitation/:token"
                        element={<SendInvitation />}
                      />
                      <Route path="/contractor" element={<Contractor />} />
                      <Route path="/buildings">
                        <Route index={true} element={<Building />} />
                        <Route
                          path=":buildingId/*"
                          element = {
                            <>
                              <SharedProvider>
                                <Routes>
                                  <Route path="products">
                                    <Route index={true} element={<Product />} />
                                    <Route
                                      path=":deviceID/callees"
                                      element={
                                        <ProtectedRoute
                                          component={Callees}
                                          authorizedGroups={[
                                            COGNITO_GROUPS.VIDATECH_ADMIN,
                                            COGNITO_GROUPS.ADMIN,
                                            COGNITO_GROUPS.COLLABORATOR,
                                          ]}
                                        />
                                      }
                                    />
                                  </Route>
                                  <Route path="visuals">
                                    <Route index={true} element={<Visual />} />
                                    <Route path=":visualId" element={<EditVisual />} />
                                  </Route>
                                  <Route path="library" element={<Library />} />
                                  <Route path="*" element={<ErrorPage errorCode={StatusCodes.NOT_FOUND} />} />
                                </Routes>
                              </SharedProvider>
                            </>
                          }
                        >
                        </Route>
                      </Route>
                      <Route
                        path="/usermanagement"
                        element={
                          <ProtectedRoute
                            component={UserManagement}
                            authorizedGroups={[COGNITO_GROUPS.VIDATECH_ADMIN, COGNITO_GROUPS.ADMIN]}
                          />
                      }
                      />
                      <Route
                        path="/firstRespondermanagement"
                        element={
                          <ProtectedRoute
                            component={FirstResponderManagement}
                            customAttributes={CustomAttributes.CONTRACTOR}
                          />
                        }
                      />
                      <Route
                        path="/vidatechadmin"
                        element={
                          <ProtectedRoute
                            component={VidatechAdmin}
                            authorizedGroups={[COGNITO_GROUPS.VIDATECH_ADMIN]}
                          />
                        }
                      />
                      {/* <Route exact path="/log" element={<Log />} /> */}
                      <Route
                        path="*"
                        element={<ErrorPage errorCode={StatusCodes.NOT_FOUND} />}
                      />
                    </Routes>
                  </Login>
                </Authenticator>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};
export default App;
