import React from 'react';
import CreateButton from '../../components/Buttons/CreateButton';
import './css/MainContent.css';

const MainContent = (props) => {
  return (
    <div id='main-content'>
      {props.headerText && (
        <div id='main-content-header'>
          <h1 data-testid='page-title'>{props.headerText}</h1>
          <div id="header-buttons">
            {props.dropdownButton}
            {props.headerButtonText && props.onHeaderButtonClick && <CreateButton disabled={props.headerButtonDisabled} text={props.headerButtonText} onClick={props.onHeaderButtonClick} />}
          </div>
        </div>
      )}
      <div id='main-content-window'>
        {props.children}
      </div>
    </div>
  );
};

export default MainContent;
