import React, { useContext } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import classNames from "classnames";
import InputContextForm from "./InputContextForm";
import "./css/RadioGlobal.css";
import { FormContextGlobal } from "./FormProvider";

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    validators: PropTypes.arrayOf(PropTypes.func),
    disabledOption: PropTypes.string,
    defaultValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    optionsWithValue: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    dataTestId: PropTypes.string
};

const RadioGlobal = ({
    label,
    style,
    name,
    value,
    errors,
    onChange,
    required,
    validators,
    disabledOption,
    defaultValue,
    options,
    id,
    dataTestid,
    ...restProps
}) => {
    const { setFieldValue } = useContext(FormContextGlobal)
    const hasError = !isEmpty(errors);

    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
        onChange(e);
    };

    const renderErrors = () => {
        if (!hasError) return null;
        return (
            <ul className='error-messages'>
                {errors.map((errMsg, i) => (
                    <li key={`${name}-error-${i}`} className='error'>
                        {errMsg}
                    </li>
                ))}
            </ul>
        );
    };

    const klass = classNames(
        "form-group-input",
        { "has-error": hasError }
    );

    return (
        <>
            <div className={klass}>
                <fieldset>
                    <legend className="titreInput">{label}</legend>
                    <div data-testid={dataTestid} className="radio-group">
                        {options && options?.map((option) => (
                            <div className="radio-option" key={option.id}>
                                <input data-testid={`${dataTestid}-${option.id}`} type="radio" id={option.id} name={name} value={option.value} onChange={handleChange} checked={value === option.value} />
                                <label className="radio-label" htmlFor={option.id}>{option.text}</label>
                            </div>
                        ))}
                    </div>
                    {renderErrors()}
                </fieldset>
            </div>
        </>
    );
};

RadioGlobal.propTypes = propTypes;

const FormRadioGlobal = InputContextForm(RadioGlobal);

export { RadioGlobal as RadioGlobal };
export default FormRadioGlobal;
