import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Link } from "react-router-dom";
import "./IdConnect.css";

// Amplify.configure({
//   ...awsExports,
//   API: {
//     graphql_headers: async () => {
//       const session = await Auth.currentSession();
//       return {
//         Authorization: session.getIdToken().getJwtToken(),
//       };
//     },
//   },
// });

const IdConnect = () => {
  const [elevatorId, setElevatorId] = React.useState("");

  const handleRoomNameChange = (event) => {
    setElevatorId(event.target.value);
  };

  return (
    <>
        <div className='elevator-container'>
          <div className='connect-to-an-elevator'>Connect to an elevator</div>
          <div className='button-id-elevator'>
            <div className='id-elevator'>
              <div className='id-elevator-1 button--12pt'>ID elevator</div>
            </div>
            <input
              className='text_input roboto-normal-midnight-14px'
              name='1'
              placeholder='Insert ID'
              type='text'
              required
              value={elevatorId}
              onChange={handleRoomNameChange}
            />
            <div className='arrow-enter'>
              <div className='overlap-group1'>
                <div className='fond-1'></div>
                <div className='overlap-group-1'>
                  {elevatorId.trim() !== "" ? (
                    <Link
                      to={`/chatroom/${elevatorId}`}
                    >
                      Join
                    </Link>
                  ) : (
                    <div disabled className='join-elevator'>
                      <img
                        className='trac-363'
                        src={require("../../img/trac--363@1x.png")}
                        alt='Trac 363'
                      />
                      <img
                        className='ligne-22'
                        src={require("../../img/ligne-228-1x-png@1x.png")}
                        alt='Ligne 22'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default IdConnect;
