import React, { useState, useEffect } from 'react';
import FormInputGlobal from '../Form/InputGlobal';
import Tooltip from '../SharedComponents/Tooltip/Tooltip';
import { DISPLAY_ITEM_CATEGORY, DISPLAY_XML_ATTRIBUTE } from '../../constants/Fields';
import { FormProvider } from '../Form/FormProvider';
import { useSharedData } from "../../context/SharedProvider";
import { WIDGET_HOUR_DEFAULT_PATH, WIDGET_METEO_DEFAULT_PATH } from '../../constants/DefaultValues';
import './css/WidgetProperties.css';

const WidgetProperties = ({widget, onItemPropertyUpdated, widgetCategory, onWidgetUpdated}) => {
  const { getFolder, downloadImage } = useSharedData();
  const [widgets, setWidgets] = useState([]);

  async function downloadWidgetImages() {
    if (widgets.length > 1) {
      for (let i = 0; i < widgets.length; i++) {
        const image = widgets[i].content.find(i => i.path.endsWith(".png"));
        if (!image.data) {
          const data = await downloadImage(image.path);
          image.data = data;
        }
      }
    }
  }
  
  useEffect(() => {
    if (widget) {
      const widgetsFolderPath = widgetCategory === DISPLAY_ITEM_CATEGORY.WIDGET_METEO ? WIDGET_METEO_DEFAULT_PATH : WIDGET_HOUR_DEFAULT_PATH;
      const widgetFolder = getFolder(widgetsFolderPath);
      if (widgetFolder && !widget.unavailable) {
        setWidgets(widgetFolder.content);
      } else {
        setWidgets([]);
      }
    }
  }, [widget])

  useEffect(() => {
    downloadWidgetImages();
  }, [widgets])

  function handleValueChanged(e) {
    let value = e.target.value;
    let name = e.target.name;
    if (!isNaN(value)) {
        value = e.target.value.replace(/^0+/, "");
        value = e.target.value.length > 0 ? e.target.value : 0;
        value = parseInt(value);
        onItemPropertyUpdated(name, value);
    }
  }

  function handleWidgetClicked(widgetFolder) {
    if (widgetFolder.name !== widget.name) {
      onWidgetUpdated(widgetFolder, widget[DISPLAY_XML_ATTRIBUTE.ID]);
    }
  }

  return (
    <FormProvider initialValues={widget} onSubmit={(e) => { }}>
      <div id="panel-content" className="travel-info-properties widget-meteo-properties"> {/* TODO : change classname to be more generic */}
        <div id="item-content">
          <div className="panel-banner">
              <h3 data-testid="properties-title">{"PROPERTIES - "}
                {widgetCategory === DISPLAY_ITEM_CATEGORY.WIDGET_METEO
                  ? "WIDGET WEATHER"
                  : "WIDGET HOUR"}
              </h3>
          </div>
          <div className="property-options">
            {widgets.length > 1 &&
              <>
                <h4 className="property-title">Choose a widget</h4>
                <div id='widgets-list'>
                  {
                    widgets.map((w, index) => {
                      return (
                        <button onClick={() => handleWidgetClicked(w)} className={`widget-button ${widget.name === w.name ? "selected" : ""}`} key={index}>
                          {
                            w.content.find(i => i.name.endsWith(".png"))?.data
                              ? <img className='widget-image' src={w.content.find(i => i.path.endsWith(".png")).data} alt={w.name} />
                              : <div className="skeleton-image"></div>
                          }
                        </button>
                      )
                    })
                  }
                </div>
              </>
            }
            <h4 className="property-title">Zone options</h4>
            <Tooltip text={"The reference point is at the top-left corner of the image."} >
              <h5>Zone position</h5>
            </Tooltip>
            <div className="property-group small-input">
              <FormInputGlobal
                  type="number"
                  name="x_value"
                  validators={[]}
                  label={"X"}
                  onChange={handleValueChanged}
                  errors={[]}
              />
              <FormInputGlobal
                  type="number"
                  name="y_value"
                  validators={[]}
                  label={"Y"}
                  onChange={handleValueChanged}
                  errors={[]}
              />
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default WidgetProperties;
