import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { acceptUserInvitation } from "../../graphql/mutations";
import { useParams } from "react-router-dom";
import Modal from "../Modal/Modal";
import Loader from "../../constants/Loader";
import successImg from "../../img/image-success.svg";
import { StatusCodes } from "../../constants/StatusCodes";
import { useNavigate } from "react-router-dom";
import "./css/ConfirmUser.css";
import { Auth } from "@aws-amplify/auth";

function ConfirmUser(props) {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      confirmUser(token);
    }
  }, [token]);

  const confirmUser = async (token) => {
    setModalOpen(true);
    setIsLoading(true);
    try {
      const res = await API.graphql(
        graphqlOperation(acceptUserInvitation, { token: token })
      );
      setSuccess(res.data.acceptUserInvitation.statusCode === StatusCodes.OK);
    } catch (error) {
      console.error("Error confirming the user");
      setSuccess(false);
    } finally {
      setIsLoading(false);
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        navigate("/buildings");
      } catch (sessionError) {
        console.error("Error refreshing session");
      }
    }
  };

  return (
    <div className="confirm-user-modal">
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        buttonText={!success && "Close"}
        buttonClick={!success && (() => navigate("/"))}
      >
        <>
          {isLoading ? (
            <Loader />
          ) : success ? (
            <>
              <img src={successImg} alt="Confirmation" />
              <p>User confirmed successfully!</p>
            </>
          ) : (
            <>
              <img
                src={require("../../img/icon-material-warning@1x.png")}
                alt="Warning sign"
              />
              <p>Error confirming the user</p>
            </>
          )}
        </>
      </Modal>
    </div>
  );
}

export default ConfirmUser;
