import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './css/Pagination.css';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav className='pagination'>
        <ol>
            <li className='pagination-item'>
                <button
                    className={classnames('pagination-button', {
                        disabled: currentPage === 1
                        })}
                    onClick={onPrevious}
                    disabled={currentPage === 1}
                >{"<"}</button>
            </li>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return <li className="pagination-item"><p>&#8230;</p></li>;
                }

                return (
                <li key={index} className='pagination-item'>
                    <button
                        className={classnames('pagination-button', {
                            active: pageNumber === currentPage
                            })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                </li>
                );
            })}
            <li className='pagination-item'>
                <button
                    className={classnames('pagination-button', {
                        disabled: currentPage === lastPage
                        })}
                    onClick={onNext}
                    disabled={currentPage === lastPage}
                >{">"}</button>
            </li>
        </ol>
    </nav>
  );
};

export default Pagination;