import { COGNITO_GROUPS } from "./DefaultValues";

export const ROLES = [
    COGNITO_GROUPS.COLLABORATOR,
    COGNITO_GROUPS.DESIGNER,
    COGNITO_GROUPS.VIEWER
]

export const SCREEN_ORIENTATION = {
    PORTRAIT: "PORTRAIT",
    LANDSCAPE: "LANDSCAPE"
};

export const BUTTON_BEHAVIOURS = [
    {value: "stair_cop", text: "Normal"},
    {value: "stair_cop_rear", text: "Rear"},
];

export const TRAVEL_INFO_FONT_FAMILIES = [
    "Arial",
];

export const TEXT_FONT_FAMILIES = [
    "Arial",
    "Helvetica",
    "Roboto",
    "Times New Roman",
    "Lato-Bold"
];

export const TRAVEL_INFO_FONT_SIZES = [
    "112",
    "160",
    "216",
    "272",
    "328",
    "452",
]

export const TRAVEL_INFO_COLORS = [
    "Amber",
    "Black",
    "Blue",
    "Dark Gray",
    "Green",
    "Light Gray",
    "Red",
    "White",
];
