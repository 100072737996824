import React, { useContext } from "react";
import { FormContextGlobal } from "./FormProvider";
import PropTypes from "prop-types";

const propTypes = {
  name: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func),
};

const InputContextForm = (InputComponent) => {
  const WrappedWithForm = ({ onChange, ...props }) => {
    const { errors, data, setFieldValue } = useContext(FormContextGlobal);

    const handleChange = (e) => {
      const { value } = e.target;
      setFieldValue(props.name, value);
      if (onChange) {
        onChange(e);
      }
    };

    const inputValue = data[props.name] ?? "";

    return (
      <InputComponent
        {...props}
        errors={errors[props.name] || []}
        value={inputValue}
        onChange={handleChange}
      />
    );
  };

  WrappedWithForm.propTypes = propTypes;
  return WrappedWithForm;
};

export default InputContextForm;
