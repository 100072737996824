import React, { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { sendContractorInvitation } from "../../graphql/mutations";
import { useParams } from "react-router-dom";

function SendInvitation(props) {
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      confirmUser(token);
    }
  });
  const confirmUser = async (token) => {
    try {
      await API.graphql(
        graphqlOperation(sendContractorInvitation, { input: { token: token } })
      );

      alert("Send invitation contractor!");
    } catch (error) {
      console.error("Error Send invitation contractor:", error);
      alert("Error Send invitation contractor.");
    }
  };
  return (
    <div>
      <h1>Send invitation contractor...</h1>
    </div>
  );
}

export default SendInvitation;
