import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import { RenderUserSpecificMenu } from "../RenderUser/RenderUserSpecificMenu";

const ProtectedRoute = ({ component: Component, customAttributes, authorizedGroups }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  
  
  let isAuthorized = false;
  const hasRequiredAttribute = RenderUserSpecificMenu(user, customAttributes);
  isAuthorized = customAttributes ? hasRequiredAttribute : false;

  if (authorizedGroups) {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    isAuthorized = groups?.some((group) => authorizedGroups.includes(group));
  }

  return isAuthorized ? <Component /> : <Navigate to="/unauthorized" replace={true} />;
};

export default ProtectedRoute;
