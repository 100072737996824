import React, { useState } from 'react';

const DetailsButton = ({opened, onToggle}) => {
  const [isOpen, setIsOpen] = useState(opened);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    onToggle();
  };

  const commonBarStyles = {
    position: 'absolute',
    width: '16px',
    height: '2px',
    backgroundColor: '#333',
    transition: 'transform 0.3s ease',
  };

  const firstBarStyles = {
    ...commonBarStyles,
    transformOrigin: '50% 50%',
    transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
  };

  const containerStyles = {
    width: '16px',
    height: '2px',
    backgroundColor: '#333',
    position: 'relative',
  };

  const buttonStyles = {
    padding: '10px 0',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  };

  return (
    <button type="button" style={buttonStyles} onClick={handleToggle}>
      <div style={containerStyles}>
        <div style={firstBarStyles}></div>
        <div style={commonBarStyles}></div>
      </div>
    </button>
  );
};

export default DetailsButton;