import React from "react";
import { FormProvider } from '../Form/FormProvider';
import VisualForm from './VisualForm';
import { DEFAULT_VISUAL_SCREEN_ORIENTATION } from "../../constants/DefaultValues";

const VisualFormProvider = ({ onSubmit, models }) => {
    const initialValues = {
        name: "",
        modelId: models.length ? models[0].id : "",
        screenOrientation: DEFAULT_VISUAL_SCREEN_ORIENTATION
    };

    return (
        <FormProvider initialValues={initialValues} onSubmit={onSubmit}>
            <VisualForm models={models} onSubmit={onSubmit}/>
        </FormProvider>
    );
};

export default VisualFormProvider;
