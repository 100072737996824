import React, { useState, useEffect, useRef } from 'react';
import './css/Action.css';

const Action = ({ actionOptions }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };

    const closeModal = () => {
      setModalOpen(false);
    }
  
    const handleClick = (option) => {
      option.onClickOption();
      closeModal();
    }

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);
  
    return (
      <div ref={menuRef} className='action' >
        <button onClick={toggleModal}>
          <img className='settings-image' src={require("../../img/roue-12.png")} alt="settings"  />
        </button>
        {modalOpen && (
            <div className='action-options' >
                {actionOptions.map((option, index) => (
                  <div data-testid={`action-option-${option.text}`} key={index} className="action-option" onClick={() => handleClick(option)}>
                    <img src={option.img} alt={option.text} />
                    <p>{option.text}</p>
                  </div>
                ))}
            </div>
        )}
      </div>
    );
  };

export default Action;