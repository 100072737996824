import { useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/Button";
import { ERROR_TEXTS } from "../../constants/Messages";
import "./ErrorPage.css";

/**
 * Renders an error page with the specified error code.
 *
 * @param {Object} props - The component props.
 * @param {string} props.errorCode - The error code to display.
 * @returns {JSX.Element} The rendered error page.
 */
const ErrorPage = ({errorCode}) => {
  const navigate = useNavigate();

  return (
    <div id="error-page">
      <div id="error-container" data-testid="error-container" className={`error-${errorCode}`}>
        <div>
          <h1>{ERROR_TEXTS[errorCode].title}</h1>
          <p>{ERROR_TEXTS[errorCode].message}</p>
          <Button text="Go back to RC" onClick={() => navigate("/buildings", {replace: true}) } />
        </div>
      </div>
    </div>
  )

};

export default ErrorPage;
