import React, { useState, useContext } from 'react';
import FormInputGlobal from '../Form/InputGlobal';
import FormSelectGlobal from '../Form/SelectGlobal';
import { FormContextGlobal } from "../Form/FormProvider";
import { VALIDATOR_FORMATS, requiredValidator, fieldFormatValidator } from "../../constants/RequiredValidator";
import Button from '../Buttons/Button';
import { ROLES } from '../../constants/FormOptions';

const UserForm = (props) => {
    const { data, errors, registerInput } = useContext(FormContextGlobal);
    const [validators] = useState({
      name: [requiredValidator],
      email: [requiredValidator, ( (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.EMAIL))],
      role: [requiredValidator]
    });
    const isCreating = props.item ? false : true;
    const [submitButtonText] = useState(isCreating ? "Invite" : "Edit");

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorsBeforeSubmit = [];

        for (const validator in validators) {
          errorsBeforeSubmit.push(registerInput(validator, validators[validator])(data[validator]));
        }

        let isValid = true;
        for (const field in errorsBeforeSubmit) {
          if (errorsBeforeSubmit[field].length > 0) {
            isValid = false;
            break;
          }
        }

        if (isValid) {
          props.onSubmit(data);
        }

    };

    return (
      <form data-testid="user-form" noValidate className='user-form' onSubmit={(event) => handleSubmit(event)}>
        <div data-testid="user-name-input" className='classInputs'>
          <FormInputGlobal
            type='text'
            name='name'
            errors={errors.name}
            validators={validators.name}
            label="Name"
          />
        </div>
        <div data-testid="user-email-input" className='classInputs'>
          <FormInputGlobal
            type='email'
            name='email'
            errors={errors.email}
            disabled={!isCreating}
            validators={validators.email}
            label="Email"
          />
        </div>
          <FormSelectGlobal
            disabledOption={data.role === "Admin" ? "Admin" : data.role === "VidatechAdmin" ? "VidatechAdmin" : null}
            label="Select a role"
            name="role"
            errors={errors.role}
            validators={validators.role}
            required={true}
            options={ROLES}/>
        <Button text={submitButtonText} type="submit" />
      </form>
    );
};

export default UserForm;
