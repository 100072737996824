import { DISPLAY_ITEM_CATEGORY } from "./Fields";

export const COGNITO_GROUPS = {
    ADMIN: "Admin",
    COLLABORATOR: "Collaborator",
    VIDATECH_ADMIN: "VidatechAdmin",
    DESIGNER: "Designer",
    VIEWER: "Viewer",
};
export const S3_URL_RESSOURCES_EXPIRATION_IN_MS = 3600000;
export const DEFAULT_PLAYLIST_IMAGE_DURATION = "00:05";
export const DEFAULT_VISUAL_SCREEN_ORIENTATION = "PORTRAIT";
export const FILE_STORAGE_LIMIT_TEXT = "1 Go";
export const FILE_STORAGE_LIMIT_O = 1073741824;
export const RESOLUTION_RATIO_MIN = 0.25;
export const RESOLUTION_RATIO_MAX = 0.5;
export const BUILDING_REFRESH_RATE = 900;
export const PRODUCT_DOOR_NUMBER = 0;
export const RESOLUTION_RATIO = {
    min: 0.4,
    low: 0.5,
    medium: 0.75,
    max: 1
};
export const CORNER_SIZE = 7;
export const DEFAULT_BUTTON_WIDTH = 50;
export const DEFAULT_BUTTON_HEIGHT = 50;
export const DEFAULT_BUTTON_UNPRESSED_SOURCE = "\\library\\vidatech\\button\\button1_unpress.png";
export const DEFAULT_BUTTON_PRESSED_SOURCE = "\\library\\vidatech\\button\\button1_press.png";
export const DEFAULT_BUTTON_TYPE = "stair_cop";
export const DEFAULT_BUTTON_NAME = "Button";
export const DEFAULT_PLAYLIST_HEIGHT = 200;
export const DEFAULT_PLAYLIST_WIDTH = 500;
export const DEFAULT_TRAVEL_INFO_PATH = "\\library\\vidatech\\STA\\";
export const PORTAIT_VALUE = "90";
export const LANDSCAPE_VALUE = "0";
export const ADD_CONTENT_OPTIONS = [
    {
        value: DISPLAY_ITEM_CATEGORY.BACKGROUND,
        label: "Background"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.IMAGE,
        label: "Image"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.PLAYLIST,
        label: "Playlist"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.BUTTON,
        label: "Button"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.TRAVEL_INFO,
        label: "Position indicator"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.TEXT,
        label: "Text"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.WIDGET_HOUR,
        label: "Widget hour"
    },
    {
        value: DISPLAY_ITEM_CATEGORY.WIDGET_METEO,
        label: "Widget weather"
    }
]

export const TRAVEL_INFO_ARROW_POSITION = {
    DOWN: "down",
    LEFT: "left",
    RIGHT: "right"
};

export const TRAVEL_INFO_SCROLL_ANIMATIONS = {
    SCROLL: "scroll",
    NONE: "none"
};

export const TRAVEL_INFO_DEFAULT_HEIGHT = 452;
export const TRAVEL_INFO_DEFAULT_DIGIT_WIDTH = 315;
export const TRAVEL_INFO_DEFAULT_ARROW_WIDTH = 340;
export const TRAVEL_INFO_DEFAULT_ARROW_HEIGHT = 453;

export const CANVAS_ITEM_DEFAULT_POSITION_VALUE = 0;

export const WIDGET_METEO_DEFAULT_PATH = "/library/vidatech/widget/weather/";
export const WIDGET_HOUR_DEFAULT_PATH = "/library/vidatech/widget/hour/";
export const TEXT_FONT_DEFAULT_PATH = "\\library\\vidatech\\font\\";
export const TEXT_DEFAULT_FONT_FAMILY_FILE = "Arial.ttf";

export const TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION = 0.5;

export const SIPOWNER_VIDATECH = "VIDATECH";
export const SIPOWNER_CUSTOM = "CUSTOM";
export const SIPACCOUNT_TYPE_PUBLIC = "PUBLIC";
export const SIPACCOUNT_TYPE_PRIVATE = "PRIVATE";