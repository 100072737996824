import React from "react";
import { FormProvider } from "../Form/FormProvider";
import FormInputGlobal from "../Form/InputGlobal";
import { PORTAIT_VALUE, LANDSCAPE_VALUE } from "../../constants/DefaultValues";
import "./css/PageProperties.css";
import { RadioGlobal } from "../Form/RadioGlobal";
import { DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";

const PageProperties = ({pageProperties, onPagePropertyUpdated}) => {
    if (pageProperties) {
        pageProperties[DISPLAY_XML_ATTRIBUTE.SPECIAL_MESSAGE_ID] = pageProperties[DISPLAY_XML_ATTRIBUTE.SPECIAL_MESSAGE_ID] || "";
    }
    
    function handlePropertyChanged(e) {
        let value = e.target.value;
        let name = e.target.name;

        if (value.length > 0) {
            onPagePropertyUpdated(name, value);
        }
    }

    function handleToggleValue(event) {
        const { name, value } = event.target;
        onPagePropertyUpdated(name, value); 
    };

    return (
        pageProperties &&
            <FormProvider initialValues={pageProperties} onSubmit={(e) => { }}>
                <div id="panel-content" className="page-properties">
                    <div id="item-content">
                        <div className="panel-banner">
                            <h3 data-testid="properties-title">PROPERTIES - PAGE</h3>
                        </div>
                    <div className="property-options">
                        <h5>Page name</h5>
                        <div id="page-name-field" className="property-group">
                            <FormInputGlobal
                                type="text"
                                name={DISPLAY_XML_ATTRIBUTE.PAGE_NAME}
                                validators={[]}
                                onChange={handlePropertyChanged}
                                errors={[]}
                            />
                        </div>
                        <div id="orientation-field">
                            <h5>Orientation</h5>
                            <div className="property-group">
                                <RadioGlobal
                                    name={DISPLAY_XML_ATTRIBUTE.ROTATION}
                                    value={pageProperties[DISPLAY_XML_ATTRIBUTE.ROTATION]}
                                    onChange={(event) => handleToggleValue(event)}
                                    options={[
                                        {id: "portait", value: PORTAIT_VALUE, text: "Portrait"},
                                        {id: "landscape", value: LANDSCAPE_VALUE, text: "Landscape"},
                                    ]}
                                />
                            </div>
                        </div>
                        <h5>Special message</h5>
                        <div id="special-message-field" className="property-group">
                            <FormInputGlobal
                                type="text"
                                name={DISPLAY_XML_ATTRIBUTE.SPECIAL_MESSAGE_ID}
                                errors={[]}
                                validators={[]}
                                onChange={handlePropertyChanged}
                            />
                        </div>
                    </div>
                    </div>
                </div>
            </FormProvider>
    );
};

export default PageProperties;
