import React from 'react';
import './css/Loader.css'

const Loader = () => {
    return (
        <div data-testid="loader" className='loader-container'>
            <video id="loading-video" autoPlay loop muted>
                <source src="/videos/loading-video.webm" type="video/webm" />
                <source src="/videos/loading-video.mp4" type="video/mp4" />
            </video>
        </div>
    );
}

export default Loader;