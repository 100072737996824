import React from "react";
import { FormProvider } from "../Form/FormProvider";
import ProductForm from "./ProductForm";
import { PRODUCT_DOOR_NUMBER } from "../../constants/DefaultValues";

const ProductFormProvider = ({ item, onSubmit, productModels, devices }) => {
  const initialValues = item
    ? {
      Name: item.Name,
      PhysicalAddress: item.PhysicalAddress,
      modelsID: item.modelsID,
      id: item.id,
      DoorNumber: item.DoorNumber,
    }
    : {
      Name: "",
      PhysicalAddress: "",
      modelsID: productModels[0]?.id,
      id: "",
      DoorNumber: PRODUCT_DOOR_NUMBER,
    };

  const handleSubmit = (data) => {
    if (!isEqualToInitialValue(data)) {
      onSubmit(data);
    }
  };

  const isEqualToInitialValue = (data) => {
    return JSON.stringify(initialValues) === JSON.stringify(data);
  };

  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={(event) => handleSubmit(event)}
    >
      <ProductForm
        item={item}
        productModels={productModels}
        devices={devices}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};

export default ProductFormProvider;
