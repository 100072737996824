import React from "react";
import { withTranslation } from "react-i18next";
import "./css/PopupProductModelFileInfo.css";

const PopupProductsModelFileInfo = (props) => {
  const item = props.item;

  return (
    <div>
      <div>
        <div className='contenerClass'>
          <div className='col1'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                Physical address
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.PhysicalAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>IP address</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.IpAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                Stream IP address
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.StreamIpAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>Power cycles</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.PowerCycles}
                </div>
              </div>
            </div>
          </div>
          <div className='col2'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>Model of device</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>{item.Name}</div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>Mac address</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.MacAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>Stream port</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.StreamPort}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                SIP acount linked
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>{}</div>
              </div>
            </div>
          </div>
          <div className='col3'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                Screen resolution
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.ModelsResolution.slice(2)}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                Software version
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.FirmwareVersion}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>Group ID</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.GroupID}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PopupProductsModelFileInfo);
