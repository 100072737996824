import React, { useState } from "react";
import Action from "../Action/Action";
import Modal from "../Modal/Modal";
import pictoEdit from "../../img/picto-edit.svg";
// import pictoDuplicate from "../../img/picto-duplicate.svg";
// import pictoShare from "../../img/picto-share.svg";
// import pictoSchedule from "../../img/picto-schedule.svg";
import pictoDelete from "../../img/picto-delete.svg";
import { useNavigate } from 'react-router-dom';

const VisualMenuItem = (props) => {
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleteModalOpen(false);
    const visualName = props.item.resolution.length > 0 ? `${props.item.name}_R_${props.item.resolution}` : props.item.name;
    props.onDelete(visualName);
  };

  const actionOptions = [
    {
      text: "Edit",
      img: pictoEdit,
      // onClickOption: () => navigate(`${window.location.pathname}/${item.name}${item.resolution.length > 0 ? `_R_${item.resolution}` : ""}`),
      onClickOption: () => navigate(`${window.location.pathname}/${item.resolution.length > 0 ? `${item.name}_R_${item.resolution}` : item.name}`),
    },
    // {
    //   text: "Duplicate",
    //   img: pictoDuplicate,
    //   onClickOption: () => console.log("Duplicate"),
    // },
    // {
    //   text: "Share",
    //   img: pictoShare,
    //   onClickOption: () => console.log("Share"),
    // },
    // {
    //   text: "Add schedule",
    //   img: pictoSchedule,
    //   onClickOption: () => console.log("Add schedule"),
    // },
    {
      text: "Delete",
      img: pictoDelete,
      onClickOption: () => setDeleteModalOpen(true),
    },
  ];

  const item = props.item;

  return (
    <tr
        id={item.name}
        className='visual-row'
        data-testid={`visual-row-${item.name}`}
    >
          {
            Object.keys(item).map((key) => {
              return (
                <td key={key} className="cell-container">
                  <div>
                    <div className='table-cell'>{item[key]}</div>
                    <span className="separator"></span>
                  </div>
                </td>
              );
            })
          }
          <td className="action-cell">
          <Action actionOptions={actionOptions} />
          <div className='confirm-modal'>
            <Modal
              isOpen={DeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              buttonText='Delete'
              buttonClick={handleDelete}>
                <>
                  <img
                    src={require("../../img/icon-material-warning@1x.png")}
                    alt='confirm delete'
                  />
                  <p>Do you really want to remove this visual?</p>
                </>
            </Modal>
          </div>
        </td>
    </tr>
  );
};

export default VisualMenuItem;
