export const BUILDING_HEADERS = {
    "Building name": "Name",
    "Address": "Address",
    // "Contractor": "Contractor",
    "Phone": "Phone",
    "City": "City",
    "Country": "Country",
    "Status": "Status",
    "Action": "Action",
};

// export const USER_HEADERS = ["Name", "Email", "Organization", "Role", "Action"];

export const USER_HEADERS = {
    "Name": "name",
    "Email": "email",
    // "Organization": "Organization",
    "Role": "role",
    "Action": "Action",
};

// export const PRODUCT_HEADERS = [
//     "Cabin ID / Address",
//     "Name",
//     "Model",
//     "Theme Link",
//     "Status",
//     "Action",
// ];

export const PRODUCT_HEADERS = {
    "ID": "PhysicalAddress",
    "Name": "Name",
    "Model": "Models",
    "Theme Link": "ThemeFolderName",
    "Status": "Status",
    "Action": "Action",
};

// export const FIRST_RESPONDER_HEADERS = [
//     "Responder name",
//     "Phone",
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//     "Actions"
// ];
export const FIRST_RESPONDER_HEADERS = {
    "Responder name": "Name",
    "Phone": "Number",
    "Sunday": "Sunday",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Actions": "Actions",
};

// export const VISUAL_HEADERS = [
//     "Visual name",
//     "Screen model (Resolution)",
//     "Screen orientation",
//     "Position Indicator",
//     "Visual linked",
//     "Schedule linked",
//     "Actions"
// ];

export const VISUAL_HEADERS = {
    "Visual name": "name",
    "Screen model (Resolution)": "resolution",
    "Screen orientation": "screenOrientation",
    "Position Indicator": "positionIndicator",
    "Visual linked": "visualLinked",
    "Schedule linked": "scheduleLinked",
    "Actions": "Actions",
};