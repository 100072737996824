import React, { useState, useContext, useEffect } from "react";
import {
  MIN_VALUE,
  MAX_VALUE,
  MAX_LENGTH,
  requiredValidator,
  maxLengthValidator,
  rangeValidator,
  MIN_LENGTH,
  minLengthValidator,
} from "../../constants/RequiredValidator";
import FormInputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import Button from "../Buttons/Button";
import FormSelectGlobal from "../Form/SelectGlobal";

export default function ProductForm(props) {
  const phoneIds = props.productModels
    .filter(model => /^TP/.test(model.Name))
    .map(model => model.id);
  const modelsOptions = props.productModels.map((model) => ({
    text: `${model.Name} ${
      model.Description ? `(${model.Description})` : ""
    } (${model.Resolution.slice(2)})`,
    value: model.id,
  }));
  const isCreating = props.item ? false : true;
  const [submitButtonText] = useState(isCreating ? "Create" : "Edit");
  const { data, errors, registerInput } = useContext(FormContextGlobal);
  const unavailableAddressValidator = (e) => {
    let isUnavailable = props.devices.some(d => 
      d.id != data["id"]
      && d.modelsID === data["modelsID"]
      && d.DoorNumber === parseInt(data["DoorNumber"])
      && d.PhysicalAddress === parseInt(e)
    );

    if (isUnavailable) {
      return "Another device of the same model and with the same door number is already using this ID";
    }
  }

  const [validators, setValidators] = useState({
    Name: [
      requiredValidator,
      (e) => minLengthValidator(e, MIN_LENGTH.DEVICE_NAME) ,
      (e) => maxLengthValidator(e, MAX_LENGTH.DEVICE_NAME)
    ],
    PhysicalAddress: [
      requiredValidator,
      (e) =>
        rangeValidator(
          e,
          MIN_VALUE.PHYSICAL_ADDRESS,
          MAX_VALUE.CABIN_ID
        ),
      unavailableAddressValidator
    ],
    DoorNumber: [
      requiredValidator,
      (e) => rangeValidator(e, MIN_VALUE.DOOR_NUMBER, MAX_VALUE.DOOR_NUMBER),
    ],
    modelsID: [requiredValidator],
  });

  useEffect(() => {
    setValidators((prevValidators) => ({
      ...prevValidators,
      PhysicalAddress: [
        requiredValidator,
        (e) => rangeValidator(e, MIN_VALUE.PHYSICAL_ADDRESS, MAX_VALUE.CABIN_ID),
        unavailableAddressValidator
      ],
      DoorNumber: phoneIds.includes(data["modelsID"]) ? [] : [requiredValidator],
    }));
  }, [data["modelsID"], data["DoorNumber"]]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isCreating) {
      data.id = props.item.id;
    }

    //TODO: uncomment when door number is optional in the backend
    // if (phoneIds.includes(data["modelsID"])) {
    //   delete data["DoorNumber"];
    // }

    if (validateForm()) {
      props.onSubmit(data);
    }
  };

  const validateForm = () => {
    let errorsBeforeSubmit = [];

    for (const validator in validators) {
      errorsBeforeSubmit.push(
        registerInput(validator, validators[validator])(data[validator])
      );
    }

    let isValid = true;
    for (const field in errorsBeforeSubmit) {
      if (errorsBeforeSubmit[field].length > 0) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  return (
    <form
      noValidate
      className="product-form"
      onSubmit={(event) => handleSubmit(event)}
    >
      <div className="classInputs">
        <FormSelectGlobal
          label="Select a model"
          name="modelsID"
          errors={errors.modelsID}
          validators={validators.modelsID}
          optionsWithValue={modelsOptions}
          onBlur={() => {
            registerInput("PhysicalAddress", validators.PhysicalAddress.slice(-1))(data["PhysicalAddress"])
          }}
        />
      </div>
      <div className="classInputs">
        <FormInputGlobal
          type="text"
          name="Name"
          placeholder="Enter your product name"
          errors={errors.Name}
          validators={validators.Name}
          maxLength={MAX_LENGTH.TEXT}
          label="Product name"
        />
      </div>
      {
        !phoneIds.includes(data["modelsID"]) &&
          <div className="classInputs">
            <FormInputGlobal
              type="number"
              preventDecimal={true}
              max={MAX_VALUE.DOOR_NUMBER}
              min={MIN_VALUE.DOOR_NUMBER}
              name="DoorNumber"
              onBlur={() => {
                registerInput("PhysicalAddress", validators.PhysicalAddress)(data["PhysicalAddress"])
              }}
              placeholder="Enter your product door number"
              validators={validators.DoorNumber}
              label="Door number"
            />
          </div>
      }
      <div className="classInputs">
        <FormInputGlobal
          type="number"
          preventDecimal={true}
          max={MAX_VALUE.CABIN_ID}
          min={MIN_VALUE.PHYSICAL_ADDRESS}
          name="PhysicalAddress"
          placeholder="Enter your product physical address"
          validators={validators.PhysicalAddress}
          tooltipText="Address of the device's DIP switch"
          label={
            phoneIds.includes(data["modelsID"])
              ? "Phone ID"
              : "Cabin ID"
          }
        />
      </div>
      <Button text={submitButtonText} type="submit" />
    </form>
  );
}
