import "./css/EditVisual.css";
import React, { useState, useEffect, useRef } from "react";
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { XMLParser, XMLBuilder, XMLValidator } from "fast-xml-parser";
import { saveVisualStorm, publishVisualStorm } from "../../graphql/mutations";
import { getVisualStorm } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { StatusCodes } from "../../constants/StatusCodes";
import { DISPLAY_ITEM_CATEGORY, DISPLAY_XML_TYPE, DISPLAY_XML_ATTRIBUTE, DISPLAY_XML_PROPERTY } from "../../constants/Fields";
import Layout from "../../containers/Layout/Layout";
import BackButton from "../../components/Buttons/BackButton"
import Button from "../Buttons/Button";
import CreateButton from "../Buttons/CreateButton";
import AddPageForm from "./AddPageForm";
import EditVisualRightPanel from "./EditVisualRightPanel";
import EditVisualLeftPanel from "./EditVisualLeftPanel";
import Canvas from "./Canvas";
import Modal from "../Modal/Modal";
import { useSharedData } from "../../context/SharedProvider";
import VidatechLoader from "./VidatechLoader";
import {
  RESOLUTION_RATIO,
  PORTAIT_VALUE,
  DEFAULT_BUTTON_WIDTH,
  DEFAULT_BUTTON_HEIGHT,
  ADD_CONTENT_OPTIONS,
  DEFAULT_BUTTON_PRESSED_SOURCE,
  DEFAULT_BUTTON_UNPRESSED_SOURCE,
  DEFAULT_BUTTON_TYPE,
  DEFAULT_PLAYLIST_HEIGHT,
  DEFAULT_PLAYLIST_WIDTH,
  TRAVEL_INFO_ARROW_POSITION,
  TRAVEL_INFO_SCROLL_ANIMATIONS,
  DEFAULT_TRAVEL_INFO_PATH,
  LANDSCAPE_VALUE,
  DEFAULT_BUTTON_NAME,
  CANVAS_ITEM_DEFAULT_POSITION_VALUE,
  WIDGET_METEO_DEFAULT_PATH,
  WIDGET_HOUR_DEFAULT_PATH,
  TEXT_FONT_DEFAULT_PATH,
  TEXT_DEFAULT_FONT_FAMILY_FILE,
} from "../../constants/DefaultValues";
import { ERROR_MESSAGES, WARNING_MESSAGES } from "../../constants/Messages";
import AddPositionIndicatorForm from "./AddPositionIndicatorForm";
import { FilesName } from "../../constants/FilesName";
import JSZip from "jszip";
import imageSuccess from "../../img/image-success.svg";
import Loader from "../../constants/Loader";

const EditVisual = () => {
  const { sharedData, isBusy, downloadImage, downloadXml, downloadFont, getFolder } = useSharedData();
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const [visualData, setVisualData] = useState(null)
  const [originalPages, setOriginalPages] = useState(null);
  const [editedPages, setEditedPages] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [triggerRedraw, setTriggerRedraw] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);
  const [visualModified, setVisualModified] = useState(false);
  const visualContent = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resolutionRatio, setResolutionRatio] = useState(RESOLUTION_RATIO.medium);
  const [createComponent, setCreateComponent] = useState(false);
  const [pageRotation, setPageRotation] = useState(PORTAIT_VALUE);
  const { visualId: visualName } = useParams();
  const [contentOptions, setContentOptions] = useState(ADD_CONTENT_OPTIONS);
  const [confirmSaveModal, setConfirmSaveModal] = useState(false);
  const [confirmPublishModal, setConfirmPublishModal] = useState(false);
  const [confirmModalErrorMessages, setConfirmModalErrorMessages] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [previewTimer, setPreviewTimer] = useState(0);
  const [confirmSaveModalMessage, setConfirmSaveModalMessage] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");

  useBeforeUnload((event) => {
    if (visualModified) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  useEffect(() => {
    let intervalId;
  
    if (previewMode) {
      unselectItem();
      const currentPage = editedPages.find(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      currentPage.Playlist.forEach(playlist => {
        playlist.selectedImageId = playlist.Image[0] && playlist.Image[0][DISPLAY_XML_ATTRIBUTE.ID];
      });
      setTriggerRedraw(!triggerRedraw);
      intervalId = setInterval(() => {
        setPreviewTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else {
      setPreviewTimer(0);
    }
  
    return () => clearInterval(intervalId);
  }, [previewMode]);
  
  useEffect(() => {
    if (previewMode && editedPages) {
      const currentPage = editedPages.find(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      currentPage.Playlist.forEach(playlist => {
        if (playlist.Image.length > 1) {
          let [minutes, seconds] = playlist.Image[0].timespan.split(":").map(Number);
          let firstImageDuration = minutes * 60 + seconds;
          if (previewTimer === firstImageDuration) {
            const selectedImageIndex = playlist.Image.findIndex(image => image[DISPLAY_XML_ATTRIBUTE.ID] === playlist.selectedImageId);
            const nextImageIndex = selectedImageIndex === playlist.Image.length - 1 ? 0 : selectedImageIndex + 1;
            playlist.selectedImageId = playlist.Image[nextImageIndex][DISPLAY_XML_ATTRIBUTE.ID];
            setTriggerRedraw(!triggerRedraw);
          } else if (previewTimer > firstImageDuration) {
            let totalPlaylistDuration = firstImageDuration;
            let nextImageIndex = playlist.Image.length > 1 ? 1 : 0;
            do {
              [minutes, seconds] = playlist.Image[nextImageIndex].timespan.split(":").map(Number);
              totalPlaylistDuration += minutes * 60 + seconds;
              nextImageIndex = nextImageIndex === playlist.Image.length - 1 ? 0 : nextImageIndex + 1;
              if (totalPlaylistDuration === previewTimer) {
                playlist.selectedImageId = playlist.Image[nextImageIndex][DISPLAY_XML_ATTRIBUTE.ID];
                setTriggerRedraw(!triggerRedraw);
              }
            } while (totalPlaylistDuration < previewTimer);
          }
        }
      });

    }
  }, [previewTimer]);

  useEffect(() => {
    const getVisual = async () => {
      setIsLoading(true);
      try {
        const xmlString = await API.graphql(
          graphqlOperation(
            getVisualStorm,
            { input :
              {
                buildingID: buildingId,
                folderName: visualName,
              }
            }
          )
        );
        if (xmlString.data.getVisualStorm.statusCode !== StatusCodes.OK) {
          if (xmlString.data.getVisualStorm.statusCode === StatusCodes.NOT_FOUND) {
            navigate("/notfound", { replace: true });
          }
          if (xmlString.data.getVisualStorm.statusCode === StatusCodes.BAD_REQUEST) {
            navigate("/unauthorized", { replace: true });
          }
        }
    
        const xml = xmlString.data.getVisualStorm.body;
        const parsedResult = parseXMLFile(xml);
        const firstPage = parsedResult.Screen.Page[0];
        //check if buttons have same behavior value and add error :
        parsedResult.Screen.Page.forEach(page => {
          if (page.Button) {
            page.Button.forEach(button => {
              button.errors = []
              const buttonsWithSameFloorAddress =
                  page.Button.filter(b => b.behavior.value === button.behavior.value && b[DISPLAY_XML_ATTRIBUTE.ID] !== button[DISPLAY_XML_ATTRIBUTE.ID]);
                  if (buttonsWithSameFloorAddress.length > 0) {
                      button.errors.push(WARNING_MESSAGES.DUPLICATE_BUTTON_FLOOR_ADDRESS);
                  }
            });
          }
          if (page.Travel_Info) {
            page.Travel_Info.forEach(travelInfo => {
              // set x and y position values relative to the center of the image
              travelInfo.x_value = Math.round((travelInfo.x_value - travelInfo.width / 2));
              travelInfo.y_value = Math.round((travelInfo.y_value - travelInfo.height / 2));
            })
          }
        });
        setOriginalPages(parsedResult.Screen.Page);
        setVisualData(parsedResult);
        setSelectedPageId(firstPage[DISPLAY_XML_ATTRIBUTE.ID]);
        setPageRotation(firstPage[DISPLAY_XML_ATTRIBUTE.ROTATION]);
        setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
      } catch (error) {
        setErrorMessage("An error occurred while fetching the visual.");
        setErrorModalOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
    getVisual();
  }, []);

  const initResolution = () => {
    if (visualData && editedPages?.length > 0) {
      const pageOrientation = editedPages
        && editedPages[editedPages.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId)][DISPLAY_XML_ATTRIBUTE.ROTATION];
      const resolutionY = pageOrientation === PORTAIT_VALUE
        ? visualData && parseInt(visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION])
        : visualData && parseInt(visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION]);
      
      let resolutionRatio;

      if (resolutionY < 500) {
          resolutionRatio = RESOLUTION_RATIO.max;
      } else if (resolutionY < 1080) {
          resolutionRatio = RESOLUTION_RATIO.medium;
      } else if (resolutionY <= 1280) {
        resolutionRatio = RESOLUTION_RATIO.low;
      } else {
          resolutionRatio = RESOLUTION_RATIO.min;
      }

      setResolutionRatio(resolutionRatio);
    }
  }

  useEffect(() => {
    if (selectedPageId) {
      initResolution();
    }
    if (editedPages.length > 0 && selectedPageId) {
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      setPageRotation(editedPages[selectedPageIndex][DISPLAY_XML_ATTRIBUTE.ROTATION]);
      let options = ADD_CONTENT_OPTIONS;
      if (editedPages[selectedPageIndex].Image?.find(image => image.type === DISPLAY_XML_TYPE.BACKGROUND)) {
        options = options.filter(option => option.value !== DISPLAY_ITEM_CATEGORY.BACKGROUND);
      }
      if (editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.TRAVEL_INFO]?.length > 0) {
        options = options.filter(option => option.value !== DISPLAY_ITEM_CATEGORY.TRAVEL_INFO);
      }
      setContentOptions(options);
    }
  }, [editedPages, selectedPageId]);

  const parseXMLFile = (xml) => {
    const alwaysArray = [
      "Screen.Page",
      "Screen.Page.Image",
      "Screen.Page.Playlist",
      "Screen.Page.Travel_Info",
      "Screen.Page.Playlist.Image",
      "Screen.Page.Button",
      "Screen.Page.Widget_Hour",
      "Screen.Page.Widget_Meteo",
      "Screen.Page.Text"
    ];
    const options = {
      // preserveOrder: true,
      ignoreAttributes: false,
      attributeNamePrefix: "@_",
      //name: is either tagname, or attribute name
      //jPath: upto the tag name
      isArray: (name, jpath, isLeafNode, isAttribute) => { 
        if( alwaysArray.indexOf(jpath) !== -1) return true;
    }
    };
    if (XMLValidator.validate(xml)) {
      const parser = new XMLParser(options);
      return parser.parse(xml);
    }
  };

  useEffect(() => {
    if (originalPages && sharedData && editedPages.length === 0) {
      initPageItems();
    }

  }, [originalPages, sharedData]);

  function unselectItem() {
    setSelectedItem(null);
    setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
    setTriggerRedraw(!triggerRedraw);
  }

  // Event listeners for keyboard shortcuts and mouse clicks
  useEffect(() => {
    const handleMouseDown = (e) => {
      if ((e.target.id === "visual-content" || e.target.id === "canvas-container") && selectedItem) {
        unselectItem();
      }
    }
    const handleKeyDown = (e) => {
      const fileExplorerOpen = visualContent.current?.querySelector("#file-explorer");
      if (selectedItem && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE
        && e.currentTarget.activeElement.tagName !== "INPUT"
        && e.currentTarget.activeElement.tagName !== "TEXTAREA"
        && !fileExplorerOpen
        ) {
        const key = e.key;
        switch (key) {
          case "Escape":
              unselectItem();
            break;
          case "Delete":
              const itemCategory = selectedItemCategory === DISPLAY_ITEM_CATEGORY.BACKGROUND ? DISPLAY_ITEM_CATEGORY.IMAGE : selectedItemCategory;
              handleDeleteItem(itemCategory, selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
            break;
          case "Tab":
            e.preventDefault();
            const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
            const category = selectedItemCategory === DISPLAY_ITEM_CATEGORY.BACKGROUND || selectedItemCategory === DISPLAY_ITEM_CATEGORY.IMAGE ? DISPLAY_ITEM_CATEGORY.IMAGE : selectedItemCategory;
            const itemIndex = editedPages[selectedPageIndex][category].findIndex(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
            const nextItem = editedPages[selectedPageIndex][category][itemIndex + 1];
            if (nextItem) {
              handleItemClicked(category, nextItem[DISPLAY_XML_ATTRIBUTE.ID]);
            } else {
              handleItemClicked(category, editedPages[selectedPageIndex][category][0][DISPLAY_XML_ATTRIBUTE.ID]);
            }
            break;
          default:
            break;
        }
        if (key.includes("Arrow")
              && (selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.BACKGROUND)
        ) {
          const item = { ...selectedItem };
          switch (key) {
            case "ArrowUp":
              if (selectedItem && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE) {
                item.y_value -= 1;
              }
              break;
            case "ArrowDown":
              if (selectedItem && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE) {
                item.y_value += 1;
              }
              break;
            case "ArrowLeft":
              if (selectedItem && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE) {
                item.x_value -= 1;
              }
              break;
            case "ArrowRight":
              if (selectedItem && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.PAGE) {
                item.x_value += 1;
              }
              break;
            default:
              break;
          }
          let itemWidth = (item.img && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.TRAVEL_INFO)
            ? item.img.width
            : item.width;
          let itemHeight = (item.img && selectedItemCategory !== DISPLAY_ITEM_CATEGORY.TRAVEL_INFO)
            ? item.img.height
            : item.height;
          const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
          const selectedPage = editedPages[selectedPageIndex];
          
          const pageWidth = selectedPage[DISPLAY_XML_ATTRIBUTE.ROTATION] === LANDSCAPE_VALUE
            ? visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION]
            : visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION];
          const pageHeight = selectedPage[DISPLAY_XML_ATTRIBUTE.ROTATION] === LANDSCAPE_VALUE
            ? visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION]
            : visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION];
          item.x_value = item.x_value + itemWidth > pageWidth ? pageWidth - itemWidth : item.x_value;
          item.y_value = item.y_value + itemHeight > pageHeight ? pageHeight - itemHeight : item.y_value;
          item.x_value = item.x_value < 0 ? 0 : item.x_value;
          item.y_value = item.y_value < 0 ? 0 : item.y_value;
          if (item.x_value !== selectedItem.x_value || item.y_value !== selectedItem.y_value) {
            handleItemMoved(item);
          }
          e.preventDefault();
        }
      }
    }

    if (visualContent.current) {
      visualContent.current.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        visualContent.current?.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("keydown", handleKeyDown);
      }
    }
  }, [visualContent, triggerRedraw, selectedItem]);

  useEffect(() => {
    if (selectedItem && selectedPageId) {
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      const selectedPage = editedPages[selectedPageIndex];
      const selectedItemId = selectedItem[DISPLAY_XML_ATTRIBUTE.ID];
  
      const selectedCategory = Object.keys(selectedPage).find(key => {
        if (Array.isArray(selectedPage[key])) {
          const item = selectedPage[key].find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItemId);
          return item;
        }
      });
  
      if (selectedCategory) {
        switch (selectedCategory) {
          case DISPLAY_ITEM_CATEGORY.IMAGE:
            const imageCategory = selectedPage[selectedCategory].find(item =>
              item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItemId).type === DISPLAY_XML_TYPE.BACKGROUND
                ? DISPLAY_ITEM_CATEGORY.BACKGROUND
                : DISPLAY_ITEM_CATEGORY.IMAGE;
            setSelectedItemCategory(imageCategory);
            break;
          case DISPLAY_ITEM_CATEGORY.PLAYLIST:
            setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PLAYLIST);
            break;
          case DISPLAY_ITEM_CATEGORY.BUTTON:
            setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.BUTTON);
            break;
          default:
            break;
        }
      }
    }
  }, [selectedItem]);

  async function initPageItems() {
    setIsLoading(true);
    const pages = originalPages;
    try {
      await Promise.all(pages.map(async page => {
        if (page.Image) {
          for (const image of page.Image) {
            if (image.source) {
              let source = image.source.replace(/\\/g, "/");
              try {
                const imageFile = sharedData.find(file => file.path === source);
                if (!imageFile) {
                  image.unavailable = true;
                } else {
                  if(!imageFile.data) {
                    await downloadImage(source);
                  }
                  image.data = imageFile.data;
                }
                //TODO : remove temporary condition to convert display.xml images type from "image" to icone
                if (image.type === "image") {
                  image.type = DISPLAY_XML_TYPE.IMAGE;
                }
              } catch (error) {
                setErrorMessage(`An error occured while fetching the image from ${source}`);
                setErrorModalOpen(true);
              }
            }
          }
        } else {
          page.Image = [];
        }
        if (page.Playlist) {
          for (const playlist of page.Playlist) {
            if (playlist.Image) {
              playlist.selectedImageId = playlist.Image.length > 0 && playlist.Image[0][DISPLAY_XML_ATTRIBUTE.ID];
              for (const image of playlist.Image) {
                if (image.source) {
                  try {
                    let source = image.source.replace(/\\/g, "/");
                    const imageFile = sharedData.find(file => file.path === source);
                    if (!imageFile) {
                      image.unavailable = true;
                      playlist.unavailable = true;
                    } else {
                      if (!imageFile.data) {
                        await downloadImage(source);
                      }
                      image.data = imageFile.data;
                    }
                    //TODO : remove temporary condition to convert display.xml images type from "image" to icone
                    if (image.type === "image") {
                      image.type = DISPLAY_XML_TYPE.IMAGE;
                    }
                  } catch (error) {
                    setErrorMessage(`An error occured while fetching the image from ${image.source}`);
                    setErrorModalOpen(true);
                  }
                }
              }
            } else {
              playlist.Image = [];
            }
          }
        } else {
          page.Playlist = [];
        }
        if (page.Button) {
          for (const button of page.Button) {
            button.width = DEFAULT_BUTTON_WIDTH;
            button.height = DEFAULT_BUTTON_HEIGHT;
            if (button.pressed_image_source) {
              let source = button.pressed_image_source.replace(/\\/g, "/");
              try {
                const imageFile = sharedData.find(file => file.path === source);
                if (!imageFile) {
                  button.pressedUnavailable = true;
                } else {
                  if(!imageFile.data) {
                    await downloadImage(source);
                  }
                  button.pressedData = imageFile.data;
                }
              } catch (error) {
                setErrorMessage(`An error occured while fetching the image from ${source}`);
                setErrorModalOpen(true);
              }
            }
            if (button.unpressed_image_source) {
              let source = button.unpressed_image_source.replace(/\\/g, "/");
              try {
                const imageFile = sharedData.find(file => file.path === source);
                if (!imageFile) {
                  button.unpressedUnavailable = true;
                } else {
                  if(!imageFile.data) {
                    await downloadImage(source);
                  }
                  button.unpressedData = imageFile.data;
                }
              } catch (error) {
                setErrorMessage(`An error occured while fetching the image from ${source}`);
                setErrorModalOpen(true);
              }
            }
          }
        } else {
          page.Button = [];
        }
        if (page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO]) {
          for (const travelInfo of page.Travel_Info) {
            if (travelInfo.source) {
              let fileName = travelInfo.source.replace(DEFAULT_TRAVEL_INFO_PATH, "");
              fileName = fileName.substring(0, fileName.length - 4);
              const color = fileName.split("_")[2];
              const previewImageName = `${color}.png`;
              travelInfo.data = "/img/travel_info/" + previewImageName;
            }
          }
        } else {
          page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO] = [];
        }
        if (page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
          for (const widgetMeteo of page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
            // get the preview image in the vidatech folder using widget name
            const widgetFolder = getFolder(WIDGET_METEO_DEFAULT_PATH + widgetMeteo.name + "/");
            if (!widgetFolder) {
              widgetMeteo.unavailable = true;
              setErrorModalOpen(true);
              setErrorMessage("Could not find widget folder for the widget named " + widgetMeteo.name);
            } else {
              const imageFile = widgetFolder.content.find(file => file.name.endsWith(".png"));
              if (!imageFile) {
                widgetMeteo.unavailable = true;
              } else if (!imageFile.data) {
                try {
                  imageFile.data = await downloadImage(imageFile.path);
                } catch (error) {
                  setErrorMessage("Error downloading widget meteo preview for the widget named " + widgetMeteo.name);
                  setErrorModalOpen(true);
                }
              }
              widgetMeteo.data = imageFile.data;
              // find widget_def file
              const widgetDefPath = `${WIDGET_METEO_DEFAULT_PATH}${widgetMeteo.name}/${FilesName.WIDGET_DEFINITION}`;
              const widgetDefFile = sharedData.find(file => file.path === widgetDefPath);
              try {
                if (widgetDefFile) {
                  let widgetDefXml = await downloadXml(widgetDefPath);
                  // parse widget_def.xml
                  if (widgetDefXml) {
                    let parsedWidgetDef
                    if (XMLValidator.validate(widgetDefXml)) {
                      const parser = new XMLParser();
                      parsedWidgetDef = parser.parse(widgetDefXml).Widget_Meteo;
                      // update widget in display.xml with values from widget_def
                      if (parsedWidgetDef) {
                        widgetMeteo.temperature = parsedWidgetDef.temperature;
                        widgetMeteo.icon = parsedWidgetDef.icon;
                        const absolutePath = WIDGET_METEO_DEFAULT_PATH.replace(/\//g, "\\");
                        widgetMeteo.icon.source = `${absolutePath}${widgetMeteo.name}${widgetMeteo.icon.source}`; // path in widget_def is \\icons\\
                        widgetMeteo.temperature.font_source = `${absolutePath}${widgetMeteo.name}\\${widgetMeteo.temperature.font_source}`; //path in widget_def is font.ttf
                      }
                    }
                  } else {
                    throw new Error("Error downloading " + FilesName.WIDGET_DEFINITION + " for the widget named " + widgetMeteo.name);
                  }
                } else {
                  throw new Error("Could not find widget definition file for widget " + widgetMeteo.name);
                }
              } catch (error) {
                setErrorMessage(error.message);
                setErrorModalOpen(true);
              }
            }
          }
        } else {
          page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO] = [];
        }
        if (page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
          for (const widget of page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
            const widgetFolder = getFolder(WIDGET_HOUR_DEFAULT_PATH + widget.name + "/");
            const imageFile = widgetFolder.content.find(file => file.name.endsWith(".png"));
            if (!imageFile) {
              widget.unavailable = true;
            } else {
              if(!imageFile.data) {
                try {
                  imageFile.data = await downloadImage(imageFile.path);
                } catch (error) {
                  setErrorMessage("Error downloading widget hour preview for the widget named " + widget.name);
                  setErrorModalOpen(true);
                }
              }
              widget.data = imageFile.data;
              // find widget_def file
              const widgetDefPath = `${WIDGET_HOUR_DEFAULT_PATH}${widget.name}/${FilesName.WIDGET_DEFINITION}`;
              const widgetDefFile = sharedData.find(file => file.path === widgetDefPath);
              if (widgetDefFile) {
                try {
                  let widgetDefXml = await downloadXml(widgetDefPath);
                  // parse widget_def.xml
                  if (widgetDefXml) {
                    let parsedWidgetDef
                    if (XMLValidator.validate(widgetDefXml)) {
                      const parser = new XMLParser();
                      parsedWidgetDef = parser.parse(widgetDefXml).Widget_Hour;
                      //update widget in display.xml with values from widget_def
                      if (parsedWidgetDef) {
                        widget.hour = parsedWidgetDef.hour;
                        widget.date = parsedWidgetDef.date;
                        const absolutePath = WIDGET_HOUR_DEFAULT_PATH.replace(/\//g, "\\");
                        widget.hour.font_source = `${absolutePath}${widget.name}\\${widget.hour.font_source}`; //path in widget_def is font.ttf
                        widget.date.font_source = `${absolutePath}${widget.name}\\${widget.date.font_source}`; //path in widget_def is font.ttf
                      }
                    }
                  } else {
                    throw new Error("Error downloading " + FilesName.WIDGET_DEFINITION);
                  }
                } catch (error) {
                  setErrorMessage(error.message);
                  setErrorModalOpen(true);
                }
              }
            }
          }
        } else {
          page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR] = [];
        }
        if (!page[DISPLAY_ITEM_CATEGORY.TEXT]) {
          page[DISPLAY_ITEM_CATEGORY.TEXT] = [];
        } else {
          await Promise.all(page[DISPLAY_ITEM_CATEGORY.TEXT].map(async (text) => {
            try {
              text.errors = [];
              const fontUrl = await downloadFont(text.font_source.replace(/\\/g, "/"));
              const fontName = text.font_source.split("\\").pop().split(".")[0];
              text.fontFace = new FontFace(fontName, `url(${fontUrl})`);
              await text.fontFace.load();
              document.fonts.add(text.fontFace);
              text.errors = text.errors.filter(error => error !== WARNING_MESSAGES.FONT_MISSING);
            } catch (error) {
              setErrorMessage(ERROR_MESSAGES.DOWNLOAD_FONT_ERROR + text.font_source);
              setErrorModalOpen(true);
              text.errors = [WARNING_MESSAGES.FONT_MISSING]
            }
          }));
        }
      }));

      setEditedPages(pages);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("An error occurred while initializing page items.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleItemMoved(movedItem) {
    if(selectedItem.x_value !== movedItem.x_value || selectedItem.y_value !== movedItem.y_value) {
      selectedItem.x_value = movedItem.x_value;
      selectedItem.y_value = movedItem.y_value;
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      const category = selectedItemCategory === DISPLAY_ITEM_CATEGORY.BACKGROUND || selectedItemCategory === DISPLAY_ITEM_CATEGORY.IMAGE ? DISPLAY_ITEM_CATEGORY.IMAGE : selectedItemCategory;
      const itemIndex = editedPages[selectedPageIndex][category].findIndex(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
      editedPages[selectedPageIndex][category][itemIndex] = selectedItem;
      if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
        checkButtonOverlap();
      }
      setEditedPages([...editedPages]);
      setSelectedItem({...selectedItem});
      setVisualModified(true);
    }
  }

  function checkButtonOverlap() {
    const selectedPageIndex = editedPages.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);

    // Reset errors for all buttons
    editedPages[selectedPageIndex].Button.forEach(button => button.errors = button.errors.filter(error => error !== WARNING_MESSAGES.BUTTON_OVERLAP));

    editedPages[selectedPageIndex].Button.forEach((button, index) => {
        for (let otherIndex = index + 1; otherIndex < editedPages[selectedPageIndex].Button.length; otherIndex++) {
            const otherButton = editedPages[selectedPageIndex].Button[otherIndex];
            if (isOverlapping(button, otherButton)) {
                button.errors.push(WARNING_MESSAGES.BUTTON_OVERLAP);
                otherButton.errors.push(WARNING_MESSAGES.BUTTON_OVERLAP);
            }
        }
    });
}

  function isOverlapping(button1, button2) {
    // Calculate the positions of the buttons
    const button1Left = button1.x_value;
    const button1Right = button1.x_value + button1.width;
    const button1Top = button1.y_value;
    const button1Bottom = button1.y_value + button1.height;

    const button2Left = button2.x_value;
    const button2Right = button2.x_value + button2.width;
    const button2Top = button2.y_value;
    const button2Bottom = button2.y_value + button2.height;

    const isOverlapping =
      button1Left < button2Right &&
      button1Right > button2Left &&
      button1Top < button2Bottom &&
      button1Bottom > button2Top;
    
    return isOverlapping;
}


  function handleItemClicked(itemCategory, itemId) {
    if (itemCategory && itemId) {
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      const item = editedPages[selectedPageIndex][itemCategory].find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === itemId);
      itemCategory === DISPLAY_ITEM_CATEGORY.IMAGE && item.type === DISPLAY_XML_TYPE.BACKGROUND ? setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.BACKGROUND) : setSelectedItemCategory(itemCategory);
      setSelectedItem(item);
    } else {
      setSelectedItem(null);
      setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
      setTriggerRedraw(!triggerRedraw);
    }
  }

  function handlePageSelected(pageId) {
    if (!selectedPageId) {
      setSelectedPageId(pageId);
    } else if (selectedPageId !== pageId) {
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      //remove content without source
      editedPages[selectedPageIndex].Image = editedPages[selectedPageIndex].Image.filter(image => image.source);
      setEditedPages([...editedPages]);
      setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
      setSelectedPageId(pageId);
    }
    setSelectedItem(null);
    if (selectedItem) {
      setTriggerRedraw(!triggerRedraw);
    }
    setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
  }

  async function handleSave(force) {
    try {
      let isReadyToSave = checkDisplayBeforeSaving(force);
      if (isReadyToSave) {
        setIsLoading(true);

        if (selectedItem?.unavailable ||
            selectedItem?.pressedUnavailable ||
            selectedItem?.unpressedUnavailable ||
            (selectedItemCategory === DISPLAY_ITEM_CATEGORY.TEXT && selectedItem?.errors.length > 0)) {
          unselectItem();
        }

        const xml = buildXMLFile();
        
        setConfirmSaveModal(false);
        setConfirmSaveModalMessage("");
        setConfirmModalErrorMessages([]);
        setConfirmationModalOpen(true);
        
        const result = await API.graphql(
          graphqlOperation(
            saveVisualStorm,
            {
              input: { 
                buildingID: buildingId,
                folderName: visualName,
                xmlContent: xml
              }
            }
          )
        );
        if (result.data.saveVisualStorm.statusCode !== 200) {
          throw new Error(ERROR_MESSAGES.SAVE_ERROR);
        }
        setVisualModified(false);
        setConfirmationModalMessage("The visual has been saved successfully.");
      } else {
        setConfirmSaveModal(true);
        setConfirmSaveModalMessage(WARNING_MESSAGES.CONFIRM_SAVE_ERRORS);
      }
    } catch (error) {
      setConfirmationModalOpen(false);
      setConfirmationModalMessage("");
      setErrorMessage("An error occurred while saving the visual.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function checkDisplayBeforeSaving(force) {
    const imagesUnavailable = editedPages.some(page => page.Image.some(image => image.unavailable));
    const playlistsUnavailable = editedPages.some(page => page.Playlist && page.Playlist.some(playlist => playlist.unavailable));
    const buttonsUnavailable = editedPages.some(page => page.Button && page.Button.some(button => button.pressedUnavailable || button.unpressedUnavailable));
    const buttonsErrors = editedPages.some(page => page.Button && page.Button.some(button => button.errors.length > 0));
    const textErrors = editedPages.some(page => page.Text.some(text => text.errors.length > 0));
    const widgetsUnavailable = editedPages
      .some(page => page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]
        .some(widget => widget.unavailable) || page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR].some(widget => widget.unavailable));

    let errorMessageArray = [];
    if (imagesUnavailable || playlistsUnavailable) {
      errorMessageArray.push(WARNING_MESSAGES.SAVE_VISUAL_MISSING_IMAGES);
    }
    if (buttonsUnavailable) {
      errorMessageArray.push(WARNING_MESSAGES.BUTTON_IMAGE_MISSING);
    }
    if (buttonsErrors) {
      errorMessageArray.push(WARNING_MESSAGES.BUTTON_ERRORS);
    }
    if (textErrors) {
      errorMessageArray.push(WARNING_MESSAGES.TEXT_ERRORS);
    }

    if (widgetsUnavailable) {
      errorMessageArray.push(WARNING_MESSAGES.WIDGET_ERRORS);
    }

    let isReadyToSave = force || errorMessageArray.length === 0;

    if (!isReadyToSave) {
      setConfirmModalErrorMessages(errorMessageArray);
    } else if(imagesUnavailable || playlistsUnavailable || buttonsUnavailable || textErrors) {
      setTriggerRedraw(!triggerRedraw);
    }

    return isReadyToSave;
  }

  function buildXMLFile() {
    const options = {
      format: true,
      ignoreAttributes: false
    };
    try {
      const builder = new XMLBuilder(options);
      editedPages.forEach(page => {
        if (page.Image.length > 0)
        {
          page.Image = page.Image.filter(image => image.source && !image.unavailable);
        }
        if (page.Playlist.length > 0) {
          page.Playlist = page.Playlist.map(playlist => {
            playlist.Image = playlist.Image.filter(image => !image.unavailable);
            delete playlist.unavailable;
            return playlist;
          });
        }
        if (page.Button.length > 0) {
          page.Button = page.Button.filter(button => !button.pressedUnavailable && !button.unpressedUnavailable)
        }
        if (page[DISPLAY_ITEM_CATEGORY.TEXT].length > 0) {
          page[DISPLAY_ITEM_CATEGORY.TEXT] = page[DISPLAY_ITEM_CATEGORY.TEXT].filter(text => text.errors.length === 0);
        }
      });
      setEditedPages([...editedPages]);
      const savedPages = deepClone(editedPages);

      savedPages.forEach(page => {
        page.Image.forEach(image => {
          delete image.data;
          delete image.loaded;
          delete image.img;
        });

        if (page.Playlist) {
          page.Playlist.forEach(playlist => {
            delete playlist.unavailable;
            delete playlist.selectedImageId;
            playlist.Image.forEach(image => {
              delete image.data;
              delete image.img;
              delete image.loaded;
            });
          })
        };

        if (page.Button) {
          page.Button.forEach(button =>{
            delete button.width;
            delete button.height;
            delete button.unpressedUnavailable;
            delete button.pressedUnavailable;
            delete button.pressedData;
            delete button.unpressedData;
            delete button.img;
            delete button.loaded;
            delete button.isPressed;
            delete button.errors;
          });
        }

        if (page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO]) {
          page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO].forEach(travelInfo => {
            // restore x and y position before saving in display.xml
            travelInfo.x_value = Math.round((travelInfo.x_value + travelInfo.width / 2));
            travelInfo.y_value = Math.round((travelInfo.y_value + travelInfo.height / 2));
            travelInfo.width = Math.round(travelInfo.width);
            travelInfo.height = Math.round(travelInfo.height);
            delete travelInfo.img;
            delete travelInfo.loaded;
            delete travelInfo.arrowLoaded;
            delete travelInfo.data;
            delete travelInfo.arrowImg;
          });
        }

        if (page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
          page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO].forEach(widget => {
            delete widget.img;
            delete widget.loaded;
            delete widget.data;
          });
        }

        if (page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
          page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR].forEach(widget => {
            delete widget.img;
            delete widget.loaded;
            delete widget.data;
          });
        }
        
        page.Text.forEach(text => {
          delete text.width;
          delete text.height;
          delete text.fontFace;
          delete text.errors;
        });
      });

      const xml = builder.build({ Screen: { ...visualData.Screen, Page: savedPages } });

      return xml;
    } catch (error) {
      throw new Error("An error occurred while building the XML file.");
    }
  }

  async function handlePublish(force) {
    try {
      let isReadyToPublish = checkDisplayBeforeSaving(force);
      if (isReadyToPublish) {
        setIsLoading(true);

        if (selectedItem?.unavailable ||
            selectedItem?.pressedUnavailable ||
            selectedItem?.unpressedUnavailable ||
            (selectedItemCategory === DISPLAY_ITEM_CATEGORY.TEXT && selectedItem?.errors.length > 0)) {
          unselectItem();
        }

        const xml = buildXMLFile();

        setConfirmPublishModal(false);
        setConfirmModalErrorMessages([]);
        setConfirmationModalOpen(true);

        const resolution = `R_${visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION]}x${visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION]}`;
        
        const result = await API.graphql(
          graphqlOperation(
            publishVisualStorm,
            {
              input: { 
                buildingID: buildingId,
                folderName: visualName,
                xmlContent: xml
              }
            }
          )
        );
    
        if (result.data.publishVisualStorm.statusCode !== 200) {
          throw new Error(ERROR_MESSAGES.PUBLISH_ERROR);
        }
        setVisualModified(false);
        setConfirmationModalMessage("The visual has been published successfully.");
      } else {
        setConfirmPublishModal(true);
      }

    } catch (error) {
      setConfirmationModalOpen(false);
      setConfirmationModalMessage("");
      setErrorMessage("An error occurred while publishing the visual.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => deepClone(item));
    }

    if (typeof obj === "object") {
      const clonedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          clonedObj[key] = deepClone(obj[key]);
        }
      }
      return clonedObj;
    }
  }

  function handlePageAdded() {
    setCreateComponent(<AddPageForm onClick={addPage} onClose={() => setCreateComponent(null)}/>);
  }

  function addPage(pageName) {
    const maxPageId = editedPages.reduce((max, page) => Math.max(max, parseInt(page[DISPLAY_XML_ATTRIBUTE.ID])), 0);
    if (pageName) {
      editedPages.push({
        [DISPLAY_XML_ATTRIBUTE.ID]: `${maxPageId + 1}`, //Check if id should be assigned and, if so, if it can be different from the one in the original file
        [DISPLAY_XML_ATTRIBUTE.PAGE_NAME]: pageName,
        [DISPLAY_XML_ATTRIBUTE.ROTATION]: PORTAIT_VALUE,
        Image: [],
        Playlist: [],
        Button: [],
        Travel_Info: [],
        [DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]: [],
        [DISPLAY_ITEM_CATEGORY.WIDGET_METEO]: [],
        [DISPLAY_ITEM_CATEGORY.TEXT]: []
      });
      handlePageSelected(`${maxPageId + 1}`);
      setPageRotation(PORTAIT_VALUE);
      setEditedPages([...editedPages]);
      setVisualModified(true);
      setCreateComponent(null);
    }
  }

  function handleDeletePage(pageId) {
    const pageToDeleteIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === pageId);
    editedPages.splice(pageToDeleteIndex, 1);
    setEditedPages([...editedPages]);
    setVisualModified(true);
    if (pageId === selectedPageId) {
      let nextSelectedPageIndex = pageToDeleteIndex - 1;
      if (nextSelectedPageIndex < 0) {
        nextSelectedPageIndex = 0;
      }
      const nextSelectedPageId = editedPages.length > 0
        ? editedPages[nextSelectedPageIndex][DISPLAY_XML_ATTRIBUTE.ID]
        : null;
      setSelectedPageId(nextSelectedPageId);
      setSelectedItem(null);
      setTriggerRedraw(!triggerRedraw);
    }
  }

  async function handleAddContent(contentCategory) {
    if (contentCategory) {
      const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      const maxItemId = findCurrentPageItemsMaxId();
      let newItem = {
        [DISPLAY_XML_ATTRIBUTE.ID]: `${maxItemId + 1}`,
        x_value: CANVAS_ITEM_DEFAULT_POSITION_VALUE,
        y_value: CANVAS_ITEM_DEFAULT_POSITION_VALUE,
        visibility: true
      }
      switch (contentCategory) {
        case DISPLAY_ITEM_CATEGORY.BACKGROUND:
          newItem[DISPLAY_XML_ATTRIBUTE.ID] = "0";
          newItem.name = `None: please add a file`;
          newItem.type = DISPLAY_XML_TYPE.BACKGROUND;
          editedPages[selectedPageIndex].Image.unshift(newItem);
        break;
        case DISPLAY_ITEM_CATEGORY.IMAGE:
          newItem.name = `None: please add a file`;
          newItem.type = DISPLAY_XML_TYPE.IMAGE;
          editedPages[selectedPageIndex].Image.push(newItem);
          break;
        case DISPLAY_ITEM_CATEGORY.PLAYLIST:
          newItem.name = "Playlist";
          newItem.height = DEFAULT_PLAYLIST_HEIGHT; //TODO: use constants
          newItem.width = DEFAULT_PLAYLIST_WIDTH;
          newItem.Image = [];
          editedPages[selectedPageIndex].Playlist.push(newItem);
          break;
        case DISPLAY_ITEM_CATEGORY.BUTTON:
          addButton();
          break;
        case DISPLAY_ITEM_CATEGORY.TRAVEL_INFO:
          setCreateComponent(<AddPositionIndicatorForm onClick={addTravelInfo} item={newItem} onClose={() => setCreateComponent(null)}/>);
          break;
        case DISPLAY_ITEM_CATEGORY.TEXT:
          newItem.name= "Text";
          newItem.content= "My text";
          newItem.font_size= 20;
          newItem.font_source= TEXT_FONT_DEFAULT_PATH + TEXT_DEFAULT_FONT_FAMILY_FILE;
          newItem.color= "#000000";
          newItem.errors=[];
          try {
            setIsLoading(true);
            const fontUrl = await downloadFont(newItem.font_source.replace(/\\/g, "/"));
            newItem.fontFace = new FontFace(newItem.font_source.split("\\").pop().split(".")[0], `url(${fontUrl})`);
            newItem.fontFace.load();
            if (!editedPages[selectedPageIndex].Text) {
              editedPages[selectedPageIndex].Text = [];
            }
            editedPages[selectedPageIndex].Text.push(newItem);
          } catch (error) {
            setErrorMessage("Error loading font from : " + newItem.font_source);
            setErrorModalOpen(true);
            return;
          } finally {
            setIsLoading(false);
          }
          break;
        case DISPLAY_ITEM_CATEGORY.WIDGET_METEO:
          await addWidgetMeteo(newItem);
          break;
        case DISPLAY_ITEM_CATEGORY.WIDGET_HOUR:
          await addWidgetHour(newItem);
          break;
        default:
          break;
      }
      if (contentCategory !== DISPLAY_ITEM_CATEGORY.BUTTON && contentCategory !== DISPLAY_ITEM_CATEGORY.TRAVEL_INFO) {
        setSelectedItem(newItem);
        setSelectedItemCategory(contentCategory);
        setEditedPages(
          editedPages.map(page => {
            if (page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId) {
              return { ...page };
            }
            return page;
          }
        ));
        setVisualModified(true);
      }
    }
  }

  async function addButton() {
    const buttonId = findCurrentPageItemsMaxId() + 1;
    const maxFloorAddress = editedPages?.find(page => page["@_id"] === selectedPageId).Button?.reduce((max, button) => Math.max(max, parseInt(button.behavior.value)), 0);
    let newItem = {
      [DISPLAY_XML_ATTRIBUTE.ID]: `${buttonId}`,
      visibility: true,
      x_value: 0,
      y_value: 0,
      name: DEFAULT_BUTTON_NAME,
      pressed_image_source : DEFAULT_BUTTON_PRESSED_SOURCE,
      unpressed_image_source : DEFAULT_BUTTON_UNPRESSED_SOURCE,
      behavior : {
        type: DEFAULT_BUTTON_TYPE,
        value: maxFloorAddress + 1,
      },
      width : DEFAULT_BUTTON_WIDTH,
      height : DEFAULT_BUTTON_HEIGHT,
      errors : []
    }
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    setIsLoading(true);
    try {
      newItem.pressedData = await downloadImage(DEFAULT_BUTTON_PRESSED_SOURCE.replace(/\\/g, "/"));
      newItem.unpressedData = await downloadImage(DEFAULT_BUTTON_UNPRESSED_SOURCE.replace(/\\/g, "/"));
      editedPages[selectedPageIndex].Button.push(newItem);
      checkButtonOverlap();
      setSelectedItem(newItem);
      setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.BUTTON);
      setEditedPages(
        editedPages.map(page => {
          if (page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId) {
            return { ...page };
          }
          return page;
        }
      ));
      setVisualModified(true);
    } catch (error) {
      setErrorMessage(`${ERROR_MESSAGES.MISSING_DEFAULT_BUTTON_IMAGE} ${error.message}`);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function addWidgetMeteo(item) {
    setIsLoading(true);
    try {
      const widget = await getWidget(WIDGET_METEO_DEFAULT_PATH);
      const widgetWeather = widget[DISPLAY_ITEM_CATEGORY.WIDGET_METEO];
      if (widgetWeather) {
        item.name = widgetWeather.name;
        item.icon = widgetWeather.icon;
        item.temperature = widgetWeather.temperature;
        const absolutePath = WIDGET_METEO_DEFAULT_PATH.replace(/\//g, "\\");
        item.icon.source = `${absolutePath}${item.name}${item.icon.source}`; // path in widget_def is \\icons\\
        item.temperature.font_source = `${absolutePath}${item.name}\\${item.temperature.font_source}`; //path in widget_def is font.ttf
        const widgetWeatherFolder = getFolder(WIDGET_METEO_DEFAULT_PATH);
        const WidgetImagePath = widgetWeatherFolder.content[0].content.find(file => file.name.endsWith(".png")).path;
        item.data = await downloadImage(WidgetImagePath);
        const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.WIDGET_METEO].push(item);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function addWidgetHour(item) {
    setIsLoading(true);
    try {
      const widget = await getWidget(WIDGET_HOUR_DEFAULT_PATH);
      const widgetHour = widget[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]
      if (widgetHour) {
        const widgetHourFolder = getFolder(WIDGET_HOUR_DEFAULT_PATH);
        item.name = widgetHour.name;
        item.hour = widgetHour.hour;
        item.hour.font_source = widgetHourFolder.content[0].content
          .find(file => file.name === widgetHour.hour.font_source).path.replace(/\//g, "\\");
        item.date = widgetHour.date;
        item.date.font_source = widgetHourFolder.content[0].content
          .find(file => file.name === widgetHour.date.font_source).path.replace(/\//g, "\\");

        const widgetImagePath = widgetHourFolder.content[0].content.find(file => file.name.endsWith(".png")).path;
        item.data = await downloadImage(widgetImagePath);

        const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.WIDGET_HOUR].push(item);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getWidget(widgetFolderPath) {
    try {
      let parsedWidgetDef;

      const widgetFolder = getFolder(widgetFolderPath);

      if (!widgetFolder) {
        throw new Error(ERROR_MESSAGES.WIDGET_FOLDER_NOT_FOUND);
      }
      const firstWidget = widgetFolder.content[0];
      if (!firstWidget) {
        throw new Error(ERROR_MESSAGES.WIDGET_FOLDER_EMPTY);
      }

      const firstWidgetDefFile = firstWidget.content.find(file => file.name.endsWith(".xml"));
      if (!firstWidgetDefFile) {
        throw new Error(ERROR_MESSAGES.WIDGET_DEF_NOT_FOUND);
      }
      
      const widgetDefXml = await downloadXml(firstWidgetDefFile.path);

      if (widgetDefXml) {
        if (XMLValidator.validate(widgetDefXml)) {
          const parser = new XMLParser();
          parsedWidgetDef = parser.parse(widgetDefXml);
        }
      }

      return parsedWidgetDef;
    } catch (error) {
      throw error;
    }
  }

  async function addTravelInfo(item) {
    const fileName = item.source.split("\\").pop();
    const FileNameWithoutExtension = fileName.substring(0, fileName.length - 4);
    const color = FileNameWithoutExtension.split("_")[2];
    item.name = "TravelInfo";
    item.type = "stair";
    item.animation_speed = 0;
    item.transition_speed = 0;
    item.arrow_position = TRAVEL_INFO_ARROW_POSITION.RIGHT;
    item.arrow_animation = TRAVEL_INFO_SCROLL_ANIMATIONS.NONE;
    item.stair_transition = TRAVEL_INFO_SCROLL_ANIMATIONS.NONE;
    item.height = 0;
    item.width = 0;
    const previewImageName = `${color}.png`;
    item.data = "/img/travel_info/" + previewImageName;
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.TRAVEL_INFO].push(item);
    setSelectedItem(item);
    setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.TRAVEL_INFO);
    setEditedPages(
      editedPages.map(page => {
        if (page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId) {
          return { ...page };
        }
        return page;
      }
    ));
    setVisualModified(true);
    setCreateComponent(null);
  }

  function findCurrentPageItemsMaxId() {
    let maxId = 0;
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);

    if (editedPages[selectedPageIndex]) {
      Object.values(editedPages[selectedPageIndex]).forEach(value => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            const itemId = Number(item && item[DISPLAY_XML_ATTRIBUTE.ID]);
            if (!isNaN(itemId) && itemId > maxId) {
              maxId = itemId;
            }
          });
        }
      });
    }
  
    return maxId;
  }

  function handleItemPropertyUpdated(property, updatedValue) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
      //updated the item in editedPage
    const item = editedPages[selectedPageIndex][selectedItemCategory]
      .find(item => item["@_id"] === selectedItem["@_id"]);
    item[property] = updatedValue;
    selectedItem[property] = updatedValue;
    if (property === DISPLAY_XML_PROPERTY.X_VALUE || property === DISPLAY_XML_PROPERTY.Y_VALUE) {
      if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
        checkButtonOverlap();
        selectedItem.errors = item.errors;
      }
    }

    if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.TRAVEL_INFO && (property === "arrow_position" || property === "digit")) {
      let source = selectedItem.source.replace(DEFAULT_TRAVEL_INFO_PATH, "");
      source = source.substring(0, source.length - 4);
      const data = `/img/travel_info/${source}_${selectedItem.digit}_${selectedItem.arrow_position}.png`
      selectedItem.data = data;
      item.data = data;
      setTriggerRedraw(!triggerRedraw);
    }
    setSelectedItem({ ...selectedItem });
    setEditedPages([...editedPages]);
    setVisualModified(true);
  }

  function handleSelectedItemUpdated(item) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    let isVisualModified = true;
    if (previewMode) {
      const clickedButtonIndex = editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.BUTTON]
        .findIndex(button => button[DISPLAY_XML_ATTRIBUTE.ID] === item[DISPLAY_XML_ATTRIBUTE.ID]);
      if (clickedButtonIndex !== -1) {
        const updatedButton = editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.BUTTON][clickedButtonIndex];
        updatedButton.isPressed = !updatedButton.isPressed;
      }
      isVisualModified = visualModified;
    } else {
      const category = selectedItemCategory === DISPLAY_ITEM_CATEGORY.BACKGROUND || selectedItemCategory === DISPLAY_ITEM_CATEGORY.IMAGE ? DISPLAY_ITEM_CATEGORY.IMAGE : selectedItemCategory;
      const itemUpdatedIndex = editedPages[selectedPageIndex][category].findIndex(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
      let updatedItem = editedPages[selectedPageIndex][category][itemUpdatedIndex];
      if (!visualModified && selectedItemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
        isVisualModified = updatedItem.isPressed === item.isPressed;
      }
      editedPages[selectedPageIndex][category][itemUpdatedIndex] = item;
      setSelectedItem(item);
    }
    setEditedPages([...editedPages]);
    setTriggerRedraw(!triggerRedraw);
    setVisualModified(isVisualModified);
  }

  function handleDeleteCategory(deletedCategory) { //TODO: Refactor to send in EditVisualLeftPanel?
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    switch (deletedCategory) {
      case DISPLAY_ITEM_CATEGORY.BACKGROUND:
        editedPages[selectedPageIndex].Image = editedPages[selectedPageIndex].Image
          .filter(image => image.type !== DISPLAY_XML_TYPE.BACKGROUND);
        contentOptions.unshift(DISPLAY_ITEM_CATEGORY.BACKGROUND)
        setContentOptions(contentOptions);
        break;
      case DISPLAY_ITEM_CATEGORY.IMAGE:
        editedPages[selectedPageIndex].Image = editedPages[selectedPageIndex].Image
          .filter(image => image.type !== DISPLAY_XML_TYPE.IMAGE);
        break;
      case DISPLAY_ITEM_CATEGORY.PLAYLIST:
        editedPages[selectedPageIndex].Playlist = [];
        break;
      case DISPLAY_ITEM_CATEGORY.BUTTON:
        editedPages[selectedPageIndex].Button = [];
        break;
      case DISPLAY_ITEM_CATEGORY.TRAVEL_INFO:
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.TRAVEL_INFO] = [];
        contentOptions.push(DISPLAY_ITEM_CATEGORY.TRAVEL_INFO)
        setContentOptions(contentOptions);
        break;
      case DISPLAY_ITEM_CATEGORY.WIDGET_METEO:
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.WIDGET_METEO] = [];
        break;
      case DISPLAY_ITEM_CATEGORY.WIDGET_HOUR:
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.WIDGET_HOUR] = [];
        break;
      case DISPLAY_ITEM_CATEGORY.TEXT:
        editedPages[selectedPageIndex][DISPLAY_ITEM_CATEGORY.TEXT] = [];
      default:
        break;
    }
    if(selectedItemCategory === deletedCategory) {
      setSelectedItem(null);
      setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
    }
    setTriggerRedraw(!triggerRedraw);
    setEditedPages([...editedPages]);
    setVisualModified(true);
  }

  function handleDeleteItem(itemCategory, itemId) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    let itemIndex;
    if (itemCategory === DISPLAY_ITEM_CATEGORY.PLAYLIST_IMAGE) {
      const playlistIndex = editedPages[selectedPageIndex].Playlist.findIndex(playlist => playlist[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
      itemIndex = editedPages[selectedPageIndex].Playlist[playlistIndex].Image.findIndex(image => image[DISPLAY_XML_ATTRIBUTE.ID] === itemId);
      const deletedItem = editedPages[selectedPageIndex].Playlist[playlistIndex].Image.splice(itemIndex, 1);
      if (deletedItem[0].unavailable) {
        editedPages[selectedPageIndex].Playlist[playlistIndex].unavailable = false;
      }
      if (selectedItem[DISPLAY_XML_ATTRIBUTE.ID] === editedPages[selectedPageIndex].Playlist[playlistIndex][DISPLAY_XML_ATTRIBUTE.ID]) {
        if (itemId === selectedItem.selectedImageId) {
          editedPages[selectedPageIndex].Playlist[playlistIndex].selectedImageId = null;
        }
        setSelectedItem(editedPages[selectedPageIndex].Playlist[playlistIndex]);
      }
    } else {
      itemIndex = editedPages[selectedPageIndex][itemCategory].findIndex(item => item[DISPLAY_XML_ATTRIBUTE.ID] === itemId);
      editedPages[selectedPageIndex][itemCategory].splice(itemIndex, 1);
      if (selectedItem && selectedItem[DISPLAY_XML_ATTRIBUTE.ID] === itemId) {
        setSelectedItem(null);
        setSelectedItemCategory(DISPLAY_ITEM_CATEGORY.PAGE);
      }
    }

    if (itemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
      // Remove the error if the button is the only one with the same floor address
      editedPages[selectedPageIndex].Button.forEach(button => {
        const buttonsWithSameFloorAddress =
            editedPages[selectedPageIndex].Button.filter(b => b.behavior.value === button.behavior.value && b[DISPLAY_XML_ATTRIBUTE.ID] !== button[DISPLAY_XML_ATTRIBUTE.ID]);
            if (buttonsWithSameFloorAddress.length === 0) {
                button.errors = button.errors.filter(e => e !== WARNING_MESSAGES.DUPLICATE_BUTTON_FLOOR_ADDRESS);
            }
      });
      checkButtonFloorAddressGaps();
      checkButtonOverlap();
    }
    setEditedPages([...editedPages]);
    setTriggerRedraw(!triggerRedraw);
    setVisualModified(true);
  }

  function checkButtonFloorAddressGaps() {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    const buttons = editedPages[selectedPageIndex].Button;
    //remove errors
    buttons.forEach(button => button.errors = button.errors.filter(error => error !== WARNING_MESSAGES.BUTTON_FLOOR_ADDRESS_GAP));
    //sort buttons by floor address
    buttons.sort((a, b) => a.behavior.value - b.behavior.value);
    //check for gaps and add error on button following the gap
    for (let i = 0; i < buttons.length - 1; i++) {
      if (buttons[i].behavior.value + 1 !== buttons[i + 1].behavior.value) {
        buttons[i + 1].errors.push(WARNING_MESSAGES.BUTTON_FLOOR_ADDRESS_GAP);
      }
    }
    if (buttons.length > 0) {
      if (buttons[0].behavior.value !== 1) {
        buttons[0].errors.push(WARNING_MESSAGES.BUTTON_FLOOR_ADDRESS_GAP);
      }
    }
  }

  function handlePlaylistImageClicked(imageId) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    const playlistIndex = editedPages[selectedPageIndex].Playlist.findIndex(playlist => playlist[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
    editedPages[selectedPageIndex].Playlist[playlistIndex].selectedImageId = imageId;
    selectedItem.selectedImageId = imageId;
    setSelectedItem({ ...selectedItem });
    setEditedPages([...editedPages]);
    // setTriggerRedraw(!triggerRedraw);
  }

  function handlePagePropertyChanged(property, value) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    editedPages[selectedPageIndex][property] = value;
    if (property === DISPLAY_XML_ATTRIBUTE.ROTATION) {
      initResolution();
      setPageRotation(value);
    }
    setEditedPages([...editedPages]);
    setVisualModified(true);
  }

  async function exportConfiguration() {
    async function addFileToZip(zip, url, path) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        zip.file(path, blob);
      } catch (error) {
        throw new Error(`${ERROR_MESSAGES.ADD_FILE_TO_ZIP_ERROR} ${path}`);
      }
    }
    async function addPageImagesToZip(page, zip) {
      for (const image of page.Image) {
        try {
          if (image.source) {
            const imageUrl = await downloadImage(image.source.replace(/\\/g, "/"));
            await addFileToZip(zip, imageUrl, image.source.replace(/\\/g, "/").substring(1));
          }
        } catch (error) {
          throw error;
        }
      }
    }

    async function addPagePlaylistImagesToZip(page, zip) {
      for (const playlist of page.Playlist) {
        for (const image of playlist.Image) {
          try {
            const imageUrl = await downloadImage(image.source.replace(/\\/g, "/"));
            await addFileToZip(zip, imageUrl, image.source.replace(/\\/g, "/").substring(1));
          } catch (error) {
            throw error;
          }
        }
      }
    }

    async function addPageButtonsToZip(page, zip) {
      for (const button of page.Button) {
        try {
          const pressedImageUrl = await downloadImage(button.pressed_image_source.replace(/\\/g, "/"));
          await addFileToZip(zip, pressedImageUrl, button.pressed_image_source.replace(/\\/g, "/").substring(1));
          const unpressedImageUrl = await downloadImage(button.unpressed_image_source.replace(/\\/g, "/"));
          await addFileToZip(zip, unpressedImageUrl, button.unpressed_image_source.replace(/\\/g, "/").substring(1));
        } catch (error) {
          throw error;
        }
      }
    }

    async function addPageTextFontsToZip(page, zip) {
      for (const text of page.Text) {
        try {
          const fontUrl = await downloadFont(text.font_source.replace(/\\/g, "/"));
          await addFileToZip(zip, fontUrl, text.font_source.replace(/\\/g, "/").substring(1));
        } catch (error) {
          throw error;
        }
      }
    }

    async function addWidgetsToZip(page, zip) {
      for (const widget of page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
        const widgetFolder = getFolder(`${WIDGET_METEO_DEFAULT_PATH}${widget.name}/`);
        await addWidgetToZip(zip, widgetFolder);
      }
      for (const widget of page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
        const widgetFolder = getFolder(`${WIDGET_HOUR_DEFAULT_PATH}${widget.name}/`);
        await addWidgetToZip(zip, widgetFolder);
      }
    }

    async function addWidgetToZip(zip, widgetFolder) {
      try {
        // download widget icons
        const icons = widgetFolder.content.find(file => file.name === "icons");
        if (icons) {
          for (const icon of icons.content) {
            const iconUrl = await downloadImage(icon.path);
            await addFileToZip(zip, iconUrl, icon.path.substring(1));
          }
        }
        // download widget_def.xml
        const widgetDefFile = widgetFolder.content.find(file => file.name.endsWith(".xml"));
        const widgetDefXml = await downloadXml(widgetDefFile.path);
        zip.file(widgetDefFile.path.substring(1), widgetDefXml);
        //download font.ttf
        const widgetFontFile = widgetFolder.content.find(file => file.name.endsWith(".ttf"));
        const fontUrl = await downloadFont(widgetFontFile.path);
        await addFileToZip(zip, fontUrl, widgetFontFile.path.substring(1));
        // download preview.png
        const previewPath = widgetFolder.content.find(file => file.name.endsWith(".png")).path;
        const previewUrl = await downloadImage(previewPath);
        await addFileToZip(zip, previewUrl, previewPath.substring(1));
      } catch (error) {
        throw error;
      }
    }

    async function addTravelInfoToZip(page, zip) {
      if (page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO].length > 0) {
        try {
          const travelInfo = page[DISPLAY_ITEM_CATEGORY.TRAVEL_INFO][0];
          const travelInfoUrl = await downloadFont(travelInfo.source.replace(/\\/g, "/"));
          await addFileToZip(zip, travelInfoUrl, travelInfo.source.replace(/\\/g, "/").substring(1));
        } catch (error) {
          throw error;
        }
      }
    }

    setIsLoading(true);
    setConfirmationModalOpen(true);
    setConfirmationModalMessage("We are creating your configuration…");

    try {
      const zip = new JSZip();

      for (const page of editedPages) {
        //[Background and Image]
        await addPageImagesToZip(page, zip);
        //[Playlist]
        await addPagePlaylistImagesToZip(page, zip);
        //[Button]
        await addPageButtonsToZip(page, zip);
        //[Text]
        await addPageTextFontsToZip(page, zip);
        //[Widgets]
        await addWidgetsToZip(page, zip);
        //[Travel_info]
        await addTravelInfoToZip(page, zip);
      }

      const xml = buildXMLFile();
      zip.file("display.xml", xml);

      const content = await zip.generateAsync({ type: "blob" });
      const blob = new Blob([content]);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = url;
      a.download = `${visualName.replace(/_R_(\d+x\d+)$/, '')}.zip`;
  
      a.click();
      setConfirmationModalMessage("Creation done! You can find your configuration in your download folder.");
    } catch (error) {
      setConfirmationModalOpen(false);
      setConfirmationModalMessage("");
      setErrorMessage("The configuration could not be exported. " + error.message);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handlePreviewMode() {
    setPreviewMode(!previewMode);
  }

  function handleBackButtonClicked(e) {
    if (visualModified) {
      setConfirmSaveModal(true);
      setConfirmSaveModalMessage(WARNING_MESSAGES.CONFIRM_SAVE_BEFORE_LEAVING);
      e.preventDefault();
    }
  }

  async function handleWidgetUpdated(widgetFolder, widgetId) {
    const selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId);
    const widget = editedPages[selectedPageIndex][selectedItemCategory].find(widget => widget[DISPLAY_XML_ATTRIBUTE.ID] === widgetId);
    try {
      const widgetDefFile = widgetFolder.content.find(file => file.name.endsWith(".xml"));
      const widgetDefXml = await downloadXml(widgetDefFile.path);
      
      if (widgetDefXml) {
        if (XMLValidator.validate(widgetDefXml)) {
          const parser = new XMLParser();
          const parsedWidgetDef = parser.parse(widgetDefXml);
          const updatedWidget = parsedWidgetDef[selectedItemCategory];
          if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.WIDGET_METEO) {
            widget.name = updatedWidget.name;
            widget.icon = updatedWidget.icon;
            widget.temperature = updatedWidget.temperature;
            const absolutePath = widgetFolder.path.replace(/\//g, "\\");
            //remove leading double backward slashes if they're present in widget.icon.source
            if (widget.icon.source.startsWith("\\")) {
              widget.icon.source = widget.icon.source.substring(1);
            }
            widget.icon.source = `${absolutePath}${widget.icon.source}`;
            widget.temperature.font_source = `${absolutePath}${widget.temperature.font_source}`;
            const WidgetImagePath = widgetFolder.content.find(file => file.name.endsWith(".png")).path;
            widget.data = await downloadImage(WidgetImagePath);
          } else if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.WIDGET_HOUR) {
            widget.name = updatedWidget.name;
            widget.hour = updatedWidget.hour;
            widget.hour.font_source = widgetFolder.content
              .find(file => file.name === updatedWidget.hour.font_source).path.replace(/\//g, "\\");
            widget.date = updatedWidget.date;
            widget.date.font_source = widgetFolder.content
              .find(file => file.name === updatedWidget.date.font_source).path.replace(/\//g, "\\");
            const WidgetImagePath = widgetFolder.content.find(file => file.name.endsWith(".png")).path;
            widget.data = await downloadImage(WidgetImagePath);
          }
          setEditedPages([...editedPages]);
          setSelectedItem(widget);
          setVisualModified(true);
          setTriggerRedraw(!triggerRedraw);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the widget.");
      setErrorModalOpen(true);
    }
  }

  return (
    <Layout >
      <div data-testid="edit-visual-header" id="edit-visual-header">
        <div>
          <BackButton onClick={handleBackButtonClicked} to={`/buildings/${buildingId}/visuals`}/>
          <div id="visual-info">
            <h1>{visualName.replace(/_R_(\d+x\d+)$/, '')}</h1>
            <h2>{visualData && `(${visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION]} x ${visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION]})`}</h2>
          </div>
        </div>
        {previewMode && 
          <div id="preview-mode-title">
            <h2>Your are in preview mode</h2>
          </div>
        }
        <div id="header-button-zone">
          <Button text="Save" style={previewMode ? {display:"none"} : null}  onClick={() => handleSave(false)} disabled={!visualModified} />
          <Button text="Publish" style={previewMode ? {display:"none"} : null} onClick={() => handlePublish(false)} disabled={!selectedPageId || isBusy} />
          <Button text={previewMode ? "Return to editor" : "Preview"} onClick={handlePreviewMode} />
          <Button text="Export configuration" style={previewMode ? {visibility:"hidden"} : null} onClick={exportConfiguration} />
          <CreateButton text="Add content" style={previewMode ? {visibility:"hidden"} : null} onClick={handleAddContent} options={contentOptions} disabled={!selectedPageId} />
        </div>
      </div>
      <div id="visual-content" ref={visualContent}>
        {!previewMode && <EditVisualLeftPanel
          editedPages={editedPages}
          selectedPage={selectedPageId}
          selectedItem={selectedItem}
          onPageSelected={handlePageSelected}
          onPageAdded={handlePageAdded}
          onPanelItemClicked={handleItemClicked}
          onCategoryDeleted={handleDeleteCategory}
          onItemDeleted={handleDeleteItem}
          onPageDeleted={handleDeletePage}
        />}
        <div className="error-modal">
          <Modal
            isOpen={errorModalOpen}
            onClose={() => setErrorModalOpen(false)}
          >
            <>
              <img
                src={require("../../img/icon-material-warning@1x.png")}
                alt="warning sign"
              />
              <p>{errorMessage}</p>
            </>
          </Modal>
          <Modal
            isOpen={confirmSaveModal}
            onClose={() => {setConfirmSaveModal(false) ; setConfirmModalErrorMessages([])}}
            buttonText={"Save"}
            buttonClick={() => handleSave(true)}
          >
              <>
                <img
                      src={require("../../img/icon-material-warning@1x.png")}
                      alt="warning sign"
                />
                <p>{confirmSaveModalMessage}</p>
                <ul>
                  {
                    confirmModalErrorMessages?.map((message, index) => {
                      return <li key={index}>{message}</li>
                    })
                  }
                </ul>
              </>
          </Modal>
          <Modal
            isOpen={confirmPublishModal}
            onClose={() => {setConfirmPublishModal(false) ; setConfirmModalErrorMessages([])}}
            buttonText={"Publish"}
            buttonClick={() => handlePublish(true)}
            >
              <>
                <img
                      src={require("../../img/icon-material-warning@1x.png")}
                      alt="warning sign"
                />
                <p>There are some problems with your display:</p>
                <ul>
                  {
                    confirmModalErrorMessages.map((message, index) => {
                      return <li key={index}>{message}</li>
                    })
                  }
                </ul>
              </>
          </Modal>
        </div>
        <Canvas
          page={editedPages && editedPages[editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId)]}
          screenResolution={{ x: visualData && parseInt(visualData.Screen[DISPLAY_XML_ATTRIBUTE.X_RESOLUTION]), y: visualData && parseInt(visualData.Screen[DISPLAY_XML_ATTRIBUTE.Y_RESOLUTION]) }}
          resolutionRatio={resolutionRatio}
          onItemClicked={handleItemClicked}
          onItemMoved={handleItemMoved}
          triggerRedraw={triggerRedraw}
          selectedItem={selectedItem}
          selectedItemCategory={selectedItemCategory}
          onSelectedItemUpdated={handleSelectedItemUpdated}
          rotation={pageRotation}
          previewMode={previewMode}
        />
        {!previewMode && <EditVisualRightPanel
          selectedItem={selectedItem}
          buttons={editedPages && editedPages[editedPages?.findIndex(page => page["@_id"] === selectedPageId)]?.Button}
          onItemPropertyUpdated={handleItemPropertyUpdated}
          onSelectedItemUpdated={handleSelectedItemUpdated}
          category={selectedItemCategory}
          pageProperties={editedPages && editedPages[editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPageId)]}
          onDeleteItem={handleDeleteItem}
          onPlaylistImageClicked={handlePlaylistImageClicked}
          onPagePropertyUpdated={handlePagePropertyChanged}
          onWidgetUpdated={handleWidgetUpdated}
        />}
        {createComponent}
      </div>
      {(isBusy || isLoading && !confirmationModalOpen) && <VidatechLoader />}
      <div className="confirmation-modal">
        <Modal
          isOpen={confirmationModalOpen}
          onClose={() => {setConfirmationModalOpen(false); setConfirmationModalMessage("")}}
          showCloseButton={!isLoading}
        >
          {
            isLoading
              ?   <div>
                    <Loader />
                    <p>{confirmationModalMessage}</p>
                  </div>
              : <div>
                  <img src={imageSuccess}/>
                  <p>{confirmationModalMessage}</p>
              </div>
          }
        </Modal>
      </div>
    </Layout>
  );
};

export default EditVisual;
