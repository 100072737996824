import React, { useState, useRef, useEffect } from "react";
import ImageInfo from "./ImageInfo";
import infoTooltip from "../../img/info-tooltip.svg";
import FormInputGlobal from "../Form/InputGlobal";
import "./css/PlaylistProperties.css";
import { FormProvider } from "../Form/FormProvider";
import { fieldFormatValidator, VALIDATOR_FORMATS } from "../../constants/RequiredValidator";
import { DEFAULT_PLAYLIST_IMAGE_DURATION } from "../../constants/DefaultValues";
import Button from "../Buttons/Button";
import Modal from "../Modal/Modal";
import FileExplorer from "../FileExplorer/FileExplorer";
import { TOOLTIP_MESSAGES } from "../../constants/Messages";
import Sortable from 'sortablejs';
import { useSharedData } from "../../context/SharedProvider";
import { DISPLAY_XML_TYPE, DISPLAY_XML_ATTRIBUTE, DISPLAY_ITEM_CATEGORY } from "../../constants/Fields";
import { PLAYLIST_MIN_SIZE } from "../../constants/DataValidation";


const PlaylistProperties = ({ selectedItem, onDeleteItem, onSelectedItemUpdated, onItemPropertyUpdated, onPlaylistImageClicked }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileExplorerModalOpen, setFileExplorerModalOpen] = useState(false);
    const sortableImages = useRef(null);
    const { downloadImage } = useSharedData();

    useEffect(() => {
        let sortableInstance = null;
        if (selectedItem && selectedItem.selectedImageId) {
            setSelectedImage(selectedItem.Image.find(image => image[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem.selectedImageId));
        } else {
            setSelectedImage(null);
        }
        if (sortableImages.current && selectedItem) {
            sortableInstance = Sortable.create(sortableImages.current, {
                handle: ".drag-icon",
                animation: 150,
                onEnd: function (evt) {
                    evt.stopPropagation();
                    const movedImage = selectedItem.Image.splice(evt.oldIndex, 1);
                    selectedItem.Image.splice(evt.newIndex, 0, movedImage[0]);
                    onSelectedItemUpdated(selectedItem);
                }
            });
        }
        return () => {
            if (sortableInstance) {
                sortableInstance.destroy();
            }
        };
    }, [selectedItem]);


    function handleValueChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (!isNaN(value)) {
            value = e.target.value.replace(/^0+/, "");
            value = e.target.value.length > 0 ? e.target.value : 0;
            value = parseInt(value);
            onItemPropertyUpdated(name, value);
        }
    }

    function handleImagePropertyChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        const timespanRegex = new RegExp(VALIDATOR_FORMATS.PLAYLIST_IMAGE_DURATION.regex);
        if (timespanRegex.test(value)) {
            selectedItem.Image.find(image => image[DISPLAY_XML_ATTRIBUTE.ID] === selectedImage[DISPLAY_XML_ATTRIBUTE.ID])[name] = value;
            onSelectedItemUpdated(selectedItem);
        }
    }

    function handleNameChanged(e) {
        let value = e.target.value;
        let name = e.target.name;

        if (value.length > 0) {
            onItemPropertyUpdated(name, value);
        }
    }

    function handleImageClicked(imageId) {
        if (selectedItem.selectedImageId !== imageId) {
            setSelectedImage(selectedItem.Image.find(image => image[DISPLAY_XML_ATTRIBUTE.ID] === imageId));
            onPlaylistImageClicked(imageId);
        }
    }

    async function handleImageDoubleClicked(image) {
        try {
            const playlistImageMaxId = selectedItem.Image.reduce((max, image) => Math.max(max, image[DISPLAY_XML_ATTRIBUTE.ID]), 0);
            const playlistImage = {
                [DISPLAY_XML_ATTRIBUTE.ID]: `${playlistImageMaxId + 1}`,
                type: DISPLAY_XML_TYPE.IMAGE,
                data: image.data ? image.data : await downloadImage(image.path),
                name: image.name,
                source: image.path.split("/").join("\\"),
                timespan: DEFAULT_PLAYLIST_IMAGE_DURATION,
            }
            const img = new Image();
            img.src = playlistImage.data;
            playlistImage.img = img;
            selectedItem.Image.push(playlistImage);
            onSelectedItemUpdated(selectedItem);
            setFileExplorerModalOpen(false);
        } catch (error) {
            console.error("Error selecting image");
        }
    }

    return (
        <FormProvider initialValues={selectedItem} onSubmit={(e) => { }}>
            <div id="panel-content">
                {selectedItem && selectedItem.Image &&
                    <>
                        <div id="item-content">
                            <div className="panel-banner">
                                <h3 data-testid="properties-title">PROPERTIES - PLAYLIST</h3>
                            </div>
                            <h4 className="property-title">Build your playlist</h4>
                            <div id="playlist-images-list" ref={sortableImages}>
                                {selectedItem.Image.map((image) => {
                                    return (
                                        <div key={image[DISPLAY_XML_ATTRIBUTE.ID]}>
                                            <ImageInfo
                                                image={image}
                                                onImageDeleted={() => onDeleteItem(DISPLAY_ITEM_CATEGORY.PLAYLIST_IMAGE, image[DISPLAY_XML_ATTRIBUTE.ID])}
                                                onImageClicked={() => handleImageClicked(image[DISPLAY_XML_ATTRIBUTE.ID])}
                                                isSelected={selectedImage && selectedImage[DISPLAY_XML_ATTRIBUTE.ID] === image[DISPLAY_XML_ATTRIBUTE.ID]}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <Button dataTestid="add-image-button" text={"Add image"} onClick={() => setFileExplorerModalOpen(true)} />
                            {fileExplorerModalOpen &&
                                <Modal
                                    isOpen={fileExplorerModalOpen}
                                    onClose={() => setFileExplorerModalOpen(false)}
                                    headerText={"SELECT AN IMAGE"}
                                >
                                    <FileExplorer importButton={true} onItemDoubleClicked={handleImageDoubleClicked} />
                                </Modal>
                            }
                        </div>
                        <div id="playlist-options">
                            <div className="property-options">
                                <h4 className="property-title">General options</h4>
                                <h5>Playlist name</h5>
                                <div id="playlist-name-field" className="property-group">
                                    <FormInputGlobal
                                        type="text"
                                        name="name"
                                        validators={[]}
                                        onChange={handleNameChanged}
                                        errors={[]}
                                        data-testid="playlist-name-input"
                                    />
                                </div>
                                <h4 className="property-title">Zone options</h4>
                                <h5>Zone resolution</h5>
                                <div className="property-group small-input">
                                    <FormInputGlobal
                                        type="number"
                                        name="width"
                                        min={PLAYLIST_MIN_SIZE}
                                        max={1920}
                                        validators={[]}
                                        label={"Width"}
                                        onChange={handleValueChanged}
                                        errors={[]}
                                        data-testid="playlist-width-input"
                                    />
                                    <FormInputGlobal
                                        type="number"
                                        name="height"
                                        min={PLAYLIST_MIN_SIZE}
                                        max={1920}
                                        validators={[]}
                                        label={"Height"}
                                        onChange={handleValueChanged}
                                        errors={[]}
                                        data-testid="playlist-height-input"
                                    />
                                </div>
                                <div className="title-tooltip">
                                    <h5>Zone position</h5>
                                    <div className="tooltip-container">
                                        <img src={infoTooltip} className="tooltip-icon" />
                                        <span className="tooltiptext">{TOOLTIP_MESSAGES.ZONE_POSITION_INFO}</span>
                                    </div>
                                </div>
                                <div className="property-group small-input">
                                    <FormInputGlobal
                                        type="number"
                                        name="x_value"
                                        min={0}
                                        max={1920}
                                        validators={[]}
                                        label={"X"}
                                        onChange={handleValueChanged}
                                        errors={[]}
                                        data-testid="x-value-input"
                                    />
                                    <FormInputGlobal
                                        type="number"
                                        name="y_value"
                                        min={0}
                                        max={1920}
                                        validators={[]}
                                        label={"Y"}
                                        onChange={handleValueChanged}
                                        errors={[]}
                                        data-testid="y-value-input"
                                    />
                                </div>
                                {selectedImage &&
                                    <>
                                        <h4 className="property-title">Image option and details</h4>
                                        <div className="property-group">
                                            <FormProvider initialValues={selectedItem.Image.find(image => image[DISPLAY_XML_ATTRIBUTE.ID] === selectedImage[DISPLAY_XML_ATTRIBUTE.ID])} onSubmit={(e) => { }}>
                                                <FormInputGlobal
                                                    type="text"
                                                    name="timespan"
                                                    value={selectedImage.timespan}
                                                    placeholder="mm:ss"
                                                    validators={[(e) => fieldFormatValidator(e, VALIDATOR_FORMATS.PLAYLIST_IMAGE_DURATION)]}
                                                    label={"Duration"}
                                                    onChange={handleImagePropertyChanged}
                                                    errors={[]}
                                                    data-testid="image-timespan-input"
                                                />
                                            </FormProvider>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </FormProvider>
    );
};

export default PlaylistProperties;
