import React from 'react';
import FormInputGlobal from '../Form/InputGlobal';
import './css/CalleeCardForm.css';
import FormSelectGlobal from '../Form/SelectGlobal';

const CalleeCardForm = (props) => {

    function handleChange(event) {
        const { name, value } = event.target;
        const updatedCallee = { ...props.callee, [name]: value };
        props.onUpdate(updatedCallee);
    };
    
    return (
        <div data-testid="callee-card" className='callee-card-form'>
            <div className='drag-icon'>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='priority'>
                <p>{props.priority}</p>
            </div>
            <div className='card-fields'>
                <div className='classInputs'>
                    <FormInputGlobal
                        type='text'
                        name='Number'
                        required={true}
                        placeholder='Enter extension (number or name)'
                        label="Phone number:"
                        validators={props.validators.Number}
                        tooltipText="Phone number or extension to call when the button is pressed"
                        onChange={handleChange} />
                </div>
                <div className='classInputs'>
                    <FormInputGlobal
                        type='text'
                        name='LinkEmail'
                        placeholder='Enter email'
                        label="Link Email:"
                        pattern="\S+@\S+\.\S+"
                        validators={props.validators.LinkEmail}
                        tooltipText="Email where the clickable link will be sent. This link will include the video feed and the messaging."
                        onChange={handleChange} />
                </div>
                <div className='classInputs'>
                    <FormInputGlobal
                        type='tel'
                        name='LinkNumber'
                        placeholder='Enter phone number'
                        label="Link number:"
                        pattern="^\([2-9][0-9]{2}\) [2-9](?!11)[0-9]{2}-\d{4}$"
                        validators={props.validators.LinkNumber}
                        tooltipText="Phone number where the clickable link will be sent, via text messaging. This link will include the video feed and the messaging."
                        onChange={handleChange} />
                </div>
                <div className='classInputs'>
                    <FormSelectGlobal
                        name='calleesSIPAccountId'
                        label="SIP Account:"
                        optionsWithValue={props.sipAccounts.map((sipAccount) => {
                            return {
                                value: sipAccount.id,
                                text: sipAccount.Name
                            };
                        })}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div data-testid="delete-button" className='delete-card' onClick={() => props.onDelete(props.callee.id)}>
                <img src={require("../../img/picto-delete.png")} alt='delete' />
            </div>
        </div>
    );
};

export default CalleeCardForm;
