import React from 'react';
import './css/Modal.css';
import Button from '../Buttons/Button';

const Modal = ({ headerText, isOpen, onClose, buttonText, buttonClick, children, buttonDisabled, showCloseButton=true }) => {
  const handleCloseClick = (event) => {
    event.stopPropagation();
    onClose();
  };
  
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-banner">
                <h1 data-testid="modal-header-text">{headerText}</h1>
                {showCloseButton &&
                  <button data-testid="modal-close-button" className="close-button" onClick={handleCloseClick}>
                    &#x2715;
                  </button>
                }
            </div>
            <div className="modal-body">
                <div className="modal-content">
                  {children}
                </div>
                {buttonClick && (<Button dataTestid={"modal-button"} autoFocus={true} disabled={buttonDisabled} text={buttonText} onClick={buttonClick} />)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;