export const MAX_LENGTH = {
    FILE_EXPLORER_ITEM_NAME : {
        ICON: 45,
        DETAILS: 40,
    },
    FILE_EXPLORER_ITEM_PATH : 37,
    CHATROOM_BUILDING_ADDRESS : 65,
    CHATROOM_RESPONDER_NAME : 25,
    DEFAULT_INPUT: 255,
}

export const PLAYLIST_MIN_SIZE = 40;
export const RESTRICTED_FOLDER_NAMES = ["vidatech", "firmware"];
export const MAXIMUM_FILE_SIZE = 2097152;