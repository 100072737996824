import React, { useState, useEffect } from "react";
import LeftPanelItem from "./LeftPanelItem";
import createButtonImage from "../../img/create-button.png";
import deleteIcon from "../../img/delete-icon.svg";
import "./css/EditVisualLeftPanel.css";
import { DISPLAY_XML_TYPE, DISPLAY_ITEM_CATEGORY, DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";

const EditVisualLeftPanel = ({ editedPages, selectedPage, onPageSelected, onPanelItemClicked, selectedItem, onPageAdded, onItemDeleted, onCategoryDeleted, onPageDeleted }) => {
  const [pages, setPages] = useState([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);

  function handleDeletePage(e, pageId) {
    e.stopPropagation();
    onPageDeleted(pageId);
  }

  useEffect(() => {
    if (selectedItem && editedPages) {
      let selectedPageIndex = editedPages?.findIndex(page => page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPage);
      let category = Object.keys(editedPages[selectedPageIndex]).find(key =>
        Array.isArray(editedPages[selectedPageIndex][key]) &&
        editedPages[selectedPageIndex][key].some(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID])
      );
      category = category === DISPLAY_ITEM_CATEGORY.IMAGE && selectedItem.type === DISPLAY_XML_TYPE.BACKGROUND ? DISPLAY_ITEM_CATEGORY.BACKGROUND : category;
      setSelectedItemCategory(category);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (editedPages && editedPages.length > 0) {
      setPages(editedPages);
    }
  }, [editedPages]);

  function handlePageClicked(pageId) {
    onPageSelected(pageId);
  }

  return (
    <div id="left-panel" className="edit-visual-pannel">
      <div id="screen-pages" className="left-panel-window">
        <div className="panel-banner">
          <h3>SCREEN'S PAGES</h3>
          <button className="add-page-button" onClick={onPageAdded}>
            <img src={createButtonImage} alt="plus sign"></img>
          </button>
        </div>
        <div className="left-panel-content">
          <ul className="left-panel-item">
            {pages && pages.map((page, index) => (
              <li
                key={page[DISPLAY_XML_ATTRIBUTE.ID]}
                className={`page-item ${page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPage ? "selected-item" : ""}`}
                onClick={() => handlePageClicked(page[DISPLAY_XML_ATTRIBUTE.ID])}
              >
                <div>
                  <span className="page-number">Page {index + 1}</span><span> - {page[DISPLAY_XML_ATTRIBUTE.PAGE_NAME]}</span>
                </div>
                <div className="delete-icon-container">
                  <button
                    type="button"
                    onClick={(e) => handleDeletePage(e, page[DISPLAY_XML_ATTRIBUTE.ID])}
                  >
                    <img src={deleteIcon} alt={"delete"} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div id="screen-content" className="left-panel-window">
        <div className="panel-banner">
          <h3>PAGE'S CONTENT</h3>
        </div>
        <div className="left-panel-content">
          <ul className="left-panel-item">
            {selectedPage && editedPages && editedPages.length > 0 && (
              <>
                {editedPages.map((page) => {
                  if (page[DISPLAY_XML_ATTRIBUTE.ID] === selectedPage) {
                    return (
                      <React.Fragment key={page[DISPLAY_XML_ATTRIBUTE.ID]}>
                        {Object.entries(page).map(([category, items]) => {
                          if (Array.isArray(items) && items.length > 0) {
                            if (category === DISPLAY_ITEM_CATEGORY.IMAGE) {
                              const backgroundItems = items.filter(item => item.type === DISPLAY_XML_TYPE.BACKGROUND);
                              const otherItems = items.filter(item => item.type !== DISPLAY_XML_TYPE.BACKGROUND);
                              return (
                                <React.Fragment key={category}>
                                  {backgroundItems.length > 0 && (
                                    <LeftPanelItem
                                      key="background"
                                      category={DISPLAY_ITEM_CATEGORY.BACKGROUND}
                                      items={backgroundItems}
                                      onPanelItemClicked={onPanelItemClicked}
                                      selectedItem={selectedItem}
                                      onItemDeleted={onItemDeleted}
                                      onCategoryDeleted={onCategoryDeleted}
                                      selectedItemCategory={selectedItemCategory}
                                    />
                                  )}
                                  {otherItems.length > 0 && (
                                    <LeftPanelItem
                                      key={category}
                                      category={category}
                                      items={otherItems}
                                      onPanelItemClicked={onPanelItemClicked}
                                      selectedItem={selectedItem}
                                      onItemDeleted={onItemDeleted}
                                      onCategoryDeleted={onCategoryDeleted}
                                      selectedItemCategory={selectedItemCategory}
                                    />
                                  )}
                                </React.Fragment>
                              );
                            }
                            return (
                              <LeftPanelItem
                                key={category}
                                category={category}
                                items={items}
                                onPanelItemClicked={onPanelItemClicked}
                                selectedItem={selectedItem}
                                onItemDeleted={onItemDeleted}
                                onCategoryDeleted={onCategoryDeleted}
                                selectedItemCategory={selectedItemCategory}
                              />
                            );
                          }
                          return null;
                        })}
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditVisualLeftPanel;
