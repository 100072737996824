import { useTheme, Heading } from "@aws-amplify/ui-react";
import { View } from "@aws-amplify/ui-react";
import IdConnect from "../components/IdConnect/IdConnect";

export const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="right" padding={tokens.space.xxl} >
          <IdConnect></IdConnect>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      }
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <div className="verification-info">
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}>
                Confirm your account
            </Heading>
          </div>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };
  
export  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your Email",
      },
    },
    signUp: {
      password: {
        label: "Password",
        placeholder: "Enter your Password",
        isRequired: false,
        displayOrder: 2,
      },
      confirm_password: {
        label: "Confirm Password",
        displayOrder: 3,
        isRequired: true
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code",
        label: "New Label",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please",
        isRequired: true,
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "test issuer",
        totpUsername: "amplify_qr_test_user",
      },
    },
  };

export const theme = {
    name: "custom-theme",
    tokens: {
      components: {
        Button: {
          primary: {
            backgroundColor: { value: "{colors.green.80}" },
            _hover: {
              backgroundColor: { value: "{colors.green.60}" },
            },
          },
          link: {
            color: { value: "{colors.green.80}" },
            _hover: {
              color: { value: "{colors.green.80}" },
              backgroundColor: { value: "{colors.green.10}" },
            },
          },
        },
        Tabs: {
          borderColor: { value: "{colors.green.80}" },
          item: {
            color: { value: "{colors.green.80}" },
            fontSize: { value: "{fontSizes.xl}" },
            fontWeight: { value: "{fontWeights.normal}" },
  
            _hover: {
              color: { value: "{colors.green.60}" },
            },
            _focus: {
              color: { value: "{colors.green.60}" },
            },
            _active: {
              color: { value: "{colors.green.80}" },
              borderColor: { value: "{colors.green.80}" },
              backgroundColor: { value: "{colors.green.10}" },
            },
            _disabled: {
              color: { value: "gray" },
              backgroundColor: { value: "transparent" },
            },
          },
        },
      },
    },
  };