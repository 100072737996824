import React from "react";
import { withTranslation } from "react-i18next";
import { FormProvider } from '../Form/FormProvider';
import { InputGlobal } from "../Form/InputGlobal";

const LinkVisualMenuItem = (props) => {
  const item = props.item;

  const handleSelectVisual = () => {
    props.onSelectVisual(item);
  }

  return (
    <tr
      key={props.key}
      className={`visual-row${props.isSelected && " selected-visual"}`} >
      {
        Object.keys(item).map((key) => {
          return (
            <td key={key} className="cell-container">
              <div>
                <div className='table-cell'>{item[key]}</div>
                <span className="separator"></span>
              </div>
            </td>
          );
        })
      }
      <td className="cell-container">
        <div>
          <div className='table-cell'>
            <FormProvider initialValues={{isSelected : props.isSelected}}>
              <InputGlobal
                className={props.isSelected ? "checked" : ""}
                name={"isSelected"}
                type='checkbox'
                checked={props.isSelected}
                onChange={handleSelectVisual}
              />
            </FormProvider>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default withTranslation()(LinkVisualMenuItem);
