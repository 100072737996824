import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import MainContent from "../MainContent/MainContent";
import "./Layout.css";

/**
 * Renders the layout component with a optional sidebar and footer.
 *
 * @param {boolean} props.showSidebar - Determines whether to show the sidebar.
 * @param {boolean} props.showFooter - Determines whether to show the footer.
 * @param {ReactNode} props.children - The content to be rendered inside the MainContent component of the Layout.
 * @param {Function} props.onHeaderButtonClick - The function to be called when the header button is clicked.
 * @param {string} props.headerText - The text to be displayed in the header.
 * @param {string} props.headerButtonText - The text to be displayed in the header button.
 * @param {Object} props - The component props.
 * @returns {ReactNode} The rendered layout component.
 */
const Layout = ({
  showSidebar,
  showFooter,
  children,
  headerText,
  headerButtonText,
  onHeaderButtonClick,
  headerButtonDisabled,
  elevatorID,
  dropdownButton,
}) => {
  return (
    <>
      <div id="layout">
        <div id="main">
          {showSidebar && <Sidebar />}
          <MainContent
            headerText={headerText}
            headerButtonText={headerButtonText}
            onHeaderButtonClick={onHeaderButtonClick}
            headerButtonDisabled={headerButtonDisabled}
            dropdownButton={dropdownButton}
          >
            {children}
          </MainContent>
        </div>
        {showFooter && (
          <Footer elevatorID={elevatorID} logoutButton={!showSidebar} />
        )}
      </div>
    </>
  );
};

export default Layout;
