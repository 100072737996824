import React, { useEffect, useState, useRef } from 'react';

const Conversation = (props) => {
    const conversation = props.conversation;
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef(null);

    const senderStyle = {
        color: "#00142B",
        fontWeight: "bold",
        fontSize: "12px",
        marginBottom: "5px",
    };

    const messageStyle = {
        color: "#00142B",
        fontSize: "12px",
        marginBottom: "5px",
        fontStyle: "italic",
    };


    useEffect(() => {
        setMessages(conversation);
    }, [conversation]);

    useEffect(() => {
        if (messagesRef.current)
        {
            messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
        }
    }, [messages]);

    return (
        <>
            <div id="conversation" ref={messagesRef}>
                {messages.map((msg, index) => (
                    <div key={index}>
                        {
                            index === 0 ? (
                                <div style={senderStyle}>
                                    {msg.sender}
                                </div>
                            ) :
                            (
                                messages[index - 1].sender !== msg.sender && (
                                    <div style={senderStyle}>
                                        {msg.sender}
                                    </div>
                                )
                            )
                        }
                        <div style={messageStyle}>
                            {msg.message}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Conversation;
