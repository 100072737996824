import React, { useEffect, useRef } from 'react';

const InfoModal = (props) => {
    const wrapperRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            props.onClose();
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
  
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []);


    return (
        <div ref={wrapperRef} className='info-modal-body' >
            <div className="info-modal-content">
                {props.children}
            </div>
        </div>
    );
};

export default InfoModal;
