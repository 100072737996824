import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

const FormContextGlobal = React.createContext();

const FormProvider = ({ children, initialValues }) => {
  const [formData, setFormData] = useState(initialValues || {});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(initialValues || {});
  }, [initialValues]);

  const setFieldValue = useCallback((name, value) => {
    setFormData((currentData) => ({ ...currentData, [name]: value }));
    setErrors((currentErrors) => ({ ...currentErrors, [name]: undefined }));
  }, []);

  const registerInput = useCallback(
    (name, validators = []) => {
      const validateField = (val) => {
        const value = val;
        const fieldErrors = validators
          .map((validator) => validator(value, formData))
          .filter((error) => error);
        setErrors((currentErrors) => ({
          ...currentErrors,
          [name]: fieldErrors,
        }));
        return fieldErrors;
      };

      return validateField;
    },
    [formData]
  );

  const validateForm = useCallback(() => {
    const formErrors = {};
    let isValid = true;

    Object.keys(formData).forEach((name) => {
      const fieldErrors = registerInput(name)();
      if (fieldErrors.length > 0) {
        formErrors[name] = fieldErrors;
        isValid = false;
      }
    });

    setErrors(formErrors);
    return isValid;
  }, [formData, registerInput]);

  const contextValue = {
    data: formData,
    errors,
    setFieldValue,
    registerInput,
    validateForm,
  };

  return (
    <FormContextGlobal.Provider value={contextValue}>
      {children}
    </FormContextGlobal.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object,
};

export { FormContextGlobal, FormProvider };
