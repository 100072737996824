import React, { useState, useEffect } from 'react';
import Layout from '../../containers/Layout/Layout';
import { 
    listContractorsStormCustom,
    listOwnersStormCustom,
    listPageBuildingsByUser
    
} from '../../graphql/queries';
import { takeContractorStorm, transferBuildingOwnershipStorm } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import "./css/VidatechAdmin.css"
import Button from '../Buttons/Button';
import { Auth } from "@aws-amplify/auth";
import DetailsButton from "../../components/Buttons/DetailsButton";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CustomAttributes } from '../../constants/CustomAttributes';
import Modal from "../../components/Modal/Modal";

const VidatechAdmin = () => {
    const [owners, setOwners] = useState([]);
    const [ownerChecked, setOwnerChecked] = useState(false);
    const [contractorChecked, setContractorChecked] = useState(false);
    const [contractors, setContractors] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showTransferDetails, setShowTransferDetails] = useState(false);
    const [buildings, setBuildings] = useState(null);
    const [selectedBuildingId, setSelectedBuildingId] = useState(null);
    const { user } = useAuthenticator((context) => [context.user]);
    const [currentUserID, setCurrentUserID] = useState(null);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("An error occured");

    useEffect(() => {
        if (user) {
            const userId = user.attributes[CustomAttributes.CONTRACTOR] || user.attributes[CustomAttributes.OWNER];
            setCurrentUserID(userId);
        }
    }, [user]);

    async function getOwners() {
        setIsLoading(true);
        try {
            const values = await API.graphql(
                graphqlOperation(listOwnersStormCustom)
            );
            if (values.data.listOwnersStorm.items) {
                setOwners(values.data.listOwnersStorm.items);
            }
        } catch (error) {
            setErrorMessage("An error occurred while trying to get owners.");
            setErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function getContractors() {
        setIsLoading(true);
        try {
            const values = await API.graphql(
                graphqlOperation(listContractorsStormCustom)
            );
            if (values.data.listContractorsStorm) {
                setContractors(values.data.listContractorsStorm.items);
            }
        } catch (error) {
            setErrorMessage("An error occurred while trying to get contractors.");
            setErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    }

    function handleCheckboxChange(event) {
        if (event.target.checked) {
            if (event.target.name === "owner") {
                setOwnerChecked(true);
                setContractorChecked(false);
                if (owners.length === 0) {
                    getOwners();
                }
            } else {
                setContractorChecked(true);
                setOwnerChecked(false);
                if (contractors.length === 0) {
                    getContractors();
                }
            }
        } else {
            if (event.target.name === "owner") {
                setOwnerChecked(false);
            } else {
                setContractorChecked(false);
            }
        }
        setSelectedUserId(null);
    }

    function handleTransferDetailsToggle() {
        setShowTransferDetails(!showTransferDetails);
        if (!showTransferDetails && !buildings) {
            getBuildings();
        }
    }
        
    async function takeControl(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const input = contractorChecked
                ? { contractorsID: selectedUserId }
                : { ownersID: selectedUserId };

            const result = await API.graphql(
                graphqlOperation(takeContractorStorm, {
                    input
                })
            );

            if (result.data.takeContractorStorm.statusCode !== 200) {
                throw new Error("An error occurred while trying to change session.")
            }

            //reset token
            await Auth.currentAuthenticatedUser({ bypassCache: true });

            if (showTransferDetails) {
                setSelectedBuildingId(null);
                getBuildings();
            }
            setSelectedUserId(null);
            setSelectedBuildingId(null);
            setBuildings(null);
        } catch (e) {
            setErrorMessage("An error occurred while trying to change session.");
            setErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function getBuildings() {
        setIsLoading(true);
        try {
          const values = await API.graphql(
            graphqlOperation(listPageBuildingsByUser)
          );
          setBuildings(values.data.listBuildingsByUser.items);
        } catch (error) {
            setErrorMessage("An error occurred while trying to get buildings.");
            setErrorModalOpen(true);
        } finally {
          setIsLoading(false);
        }
    }

    async function transferOwnership(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            let isSelectedUserOwner = owners.find(owner => owner.id === selectedUserId);
            const input = {
                buildingID: selectedBuildingId,
                ownersID: isSelectedUserOwner ? selectedUserId : null,
                contractorsID: isSelectedUserOwner ? null : selectedUserId
            };
            const result = await API.graphql(
                graphqlOperation(transferBuildingOwnershipStorm, {
                    input
                })
            );
            if (result.data.transferBuildingOwnershipStorm.statusCode !== 200) {
                throw new Error("An error occurred while trying to transfer ownership.")
            }
            setSelectedBuildingId(null);
            setBuildings(null);
        } catch (error) {
            setErrorMessage("An error occurred while trying to transfer ownership.");
            setErrorModalOpen(true);
        } finally {
            setIsLoading(false);
            getBuildings();
        }
    }

    return (
        <Layout
            headerText="VIDATECH ADMIN DASHBOARD"
            showSidebar={true}
            showFooter={false}
        >
            <div id='vidatech-admin-content'>
                <form id='take-control-form' onSubmit={takeControl}>
                    <h2>Select a user</h2>
                    <div className="user-selection">
                        <div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="owner"
                                    checked={ownerChecked}
                                    className={ownerChecked ? "checked" : null}
                                    name="owner"
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <label className='titreInput' htmlFor="owner">Owner</label>
                        </div>
                        <UserSelect
                            name="owners"
                            userChecked={ownerChecked}
                            users={owners.filter(owner => owner.id !== currentUserID)}
                            isLoading={isLoading}
                            handleUserSelected={(e) => setSelectedUserId(e.target.value)}
                        />
                    </div>
                    <div className="user-selection">
                        <div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="contractor"
                                    name="contractor"
                                    className={contractorChecked ? "checked" : ""}
                                    checked={contractorChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <label className='titreInput' htmlFor="contractor">Contractor</label>
                        </div>
                        <UserSelect
                            name="contractors"
                            userChecked={contractorChecked}
                            users={contractors.filter(contractor => contractor.id !== currentUserID)}
                            isLoading={isLoading}
                            handleUserSelected={(e) => setSelectedUserId(e.target.value)}
                        />
                    </div>
                    <Button
                        disabled={!selectedUserId || isLoading}
                        type={"submit"}
                        text={"Take control"}
                        />
                </form>
                <div id="transfer-ownership">
                    <div id="transfer-header">
                        <DetailsButton
                            opened={false}
                            onToggle={handleTransferDetailsToggle}
                        />
                        <h2>Transfer ownership</h2>
                    </div>
                    {
                        showTransferDetails && (
                            <form id="transfer-ownership-form" onSubmit={transferOwnership} >
                                <div>
                                    <label htmlFor="buildings">Building</label>
                                    <div className={`user-selection-spinner ${isLoading && !buildings ? "visible" : ""}`}/>
                                    {<select
                                        name="buildings"
                                        id="buildings"
                                        value={selectedBuildingId || ""}
                                        disabled={!buildings || (buildings && buildings.length === 0) || isLoading}
                                        onChange={(e) => setSelectedBuildingId(e.target.value)}
                                        className={`select-control ${isLoading ? "disabled" : ""}`}
                                    >
                                        {
                                            !buildings
                                                ? <option value="">Please select a building</option>
                                                : buildings.length === 0
                                                    ? <option value="">No buildings available</option>
                                                    : <option value="">Please select a building</option>
                                        }
                                        {
                                            buildings && buildings.map((building) => (
                                                <option key={building.id} value={building.id}>{building.Name}</option>
                                            ))
                                        }
                                    </select>}
                                </div>
                                <Button
                                    disabled={(!selectedBuildingId || !selectedUserId) || isLoading}
                                    type={"submit"}
                                    text={"Transfer ownership"}
                                />
                            </form>
                        )
                    }
                </div>
            </div>
            <div className="error-modal">
                <Modal
                isOpen={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                >
                <>
                    <img
                    src={require("../../img/icon-material-warning@1x.png")}
                    alt="warning sign"
                    />
                    <p>{errorMessage}</p>
                </>
                </Modal>
            </div>
        </Layout>
    );
};

const UserSelect = ({name, userChecked, users, isLoading, handleUserSelected}) => (
    <div className='user-select'>
        <div className={`user-selection-spinner ${isLoading && userChecked ? "visible" : ""}`}/>
        <select
            name={name}
            id={name}
            disabled={!userChecked || users.length === 0}
            onChange={handleUserSelected}
            className={`select-control ${!userChecked || (userChecked && users.length === 0) ? "disabled" : ""}`}
        >
            {
                userChecked && !isLoading && users.length === 0
                    ? <option value="">No users available</option>
                    : <option value="">Please select a user</option>
            }
            {
                userChecked && users.map((user) => (
                    <option key={user.id} value={user.id}>{name === "owners" ? user.BusinessName : user.Name} - {user.Email}</option>
                ))
            }
        </select>
    </div>
)

export default VidatechAdmin;
