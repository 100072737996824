import React, { useState } from "react";
import ImageInfo from "./ImageInfo";
import FormInputGlobal from "../Form/InputGlobal";
import "./css/ImageProperties.css";
import Button from "../Buttons/Button";
import FileExplorer from "../FileExplorer/FileExplorer";
import Modal from "../Modal/Modal";
import Tooltip from "../SharedComponents/Tooltip/Tooltip";
import { useSharedData } from "../../context/SharedProvider";
import { DISPLAY_XML_TYPE, DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";
import { FormProvider } from "../Form/FormProvider";
import { CANVAS_IMAGE_ALLOWED_FILE_TYPES } from "../../constants/AllowedFileTypes";

const ImageProperties = ({ selectedItem, onDeleteItem, onSelectedItemUpdated, onItemPropertyUpdated }) => {
    const [fileExplorerModalOpen, setFileExplorerModalOpen] = useState(false);
    const { downloadImage } = useSharedData();

    function handlePositionChanged(e) {
        let value = e.target.value;
        let name = e.target.name;

        // e.target.value = e.target.value.replace(/^0+/, "");
        value = e.target.value.length > 0 ? e.target.value : 0;
        value = parseInt(value);

        onItemPropertyUpdated(name, value);
    }

    async function handleImageDoubleClicked(image) {
        if (CANVAS_IMAGE_ALLOWED_FILE_TYPES.includes(image.type)) {
            const img = new Image();
            try {
                if (!image.data) {
                    img.src = await downloadImage(image.path);
                    image.data = img.src;
                } else {
                    img.src = image.data;
                }
                selectedItem.source = image.path;
                selectedItem.source = selectedItem.source.split("/").join("\\");
                selectedItem.name = image.name;
                selectedItem.data = image.data;
                img.onload = () => {
                    selectedItem.loaded = true;
                    delete selectedItem.unavailable;
                    onSelectedItemUpdated(selectedItem);
                }
                selectedItem.img = img;
                setFileExplorerModalOpen(false);
            } catch (error) {
                console.error("Error selecting image");
            }
        }
    }
    
    return (
        <FormProvider initialValues={selectedItem} onSubmit={(e) => { }}>
            <div id="panel-content">
                <div id="item-content">
                    <div className="panel-banner">
                        <h3 data-testid="properties-title">PROPERTIES - {selectedItem[DISPLAY_XML_ATTRIBUTE.ID] === "0" ? "BACKGROUND" : "IMAGE"}</h3>
                    </div>
                    <h4 className="property-title">{selectedItem[DISPLAY_XML_ATTRIBUTE.ID] === "0" ? "Custom your background" : "Your image"}</h4>
                    {selectedItem.source &&
                        <ImageInfo
                            image={selectedItem}
                            onImageDeleted={onDeleteItem}
                        />
                    }
                    <Button
                        text={selectedItem.source ? "Change image" : "Add image"}
                        onClick={() => setFileExplorerModalOpen(true)}
                        dataTestid="select-image-button"
                    />
                    {fileExplorerModalOpen &&
                        <Modal 
                            isOpen={fileExplorerModalOpen}
                            onClose={() => setFileExplorerModalOpen(false)}
                            headerText={"SELECT AN IMAGE"}
                        >
                            <FileExplorer importButton={true} onItemDoubleClicked={handleImageDoubleClicked}/>
                        </Modal>
                    }
                </div>
                { selectedItem.img &&
                    <div className="property-options">
                        <h4 className="property-title">Options</h4>
                        <h5>Zone resolution</h5>
                        <div className="property-group">
                            <div className="horizontal-property">
                                <p className="property-label">Width</p>
                                <p className="property-value">{selectedItem.img.width} px</p>
                            </div>
                            <div className="horizontal-property">
                                <p className="property-label">Height</p>
                                <p className="property-value">{selectedItem.img.height} px</p>
                            </div>
                        </div>
                        {selectedItem.type === DISPLAY_XML_TYPE.IMAGE &&
                            <>
                                <Tooltip text={"The reference point is at the top-left corner of the image."} >
                                    <h5>Zone position</h5>
                                </Tooltip>
                                <div className="property-group small-input">
                                    <FormInputGlobal
                                        type="number"
                                        name="x_value"
                                        min={0}
                                        max={1920}
                                        validators={[]}
                                        label={"X"}
                                        onChange={handlePositionChanged}
                                        errors={[]}
                                        data-testid="x-value-input"
                                    />
                                    <FormInputGlobal
                                        type="number"
                                        name="y_value"
                                        validators={[]}
                                        min={0}
                                        max={1920}
                                        label={"Y"}
                                        onChange={handlePositionChanged}
                                        errors={[]}
                                        data-testid="y-value-input"
                                    />
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        </FormProvider>
    );
};

export default ImageProperties;
