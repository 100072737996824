import React, { useState } from "react";
import { useNavigate } from "react-router";
import { API, graphqlOperation } from "aws-amplify";
import { createContractorWithAddressCustom } from "../../graphql/mutations";
import Layout from "../../containers/Layout/Layout";
import { FormProvider } from "../Form/FormProvider";
import ContractorForm from "./ContractorForm";
import Modal from "../Modal/Modal";
import messageSentImage from "../../img/message-sent.svg";
import warningSignImage from "../../img/warning-sign.svg";
import "./css/Contractor.css";
import Loader from "../../constants/Loader";

const Contractor = () => {
  const navigate = useNavigate();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    ContractorName: "",
    Street1: "",
    City: "",
    Province: "",
    PostalCode: "",
    Country: "",
  };

  const handleCloseClick = (event) => {
    navigate("/");
  };

  const handleCreateContractor = async (contractor) => {
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(createContractorWithAddressCustom, {
          input: contractor,
        })
      );
      if (response.data.createContractorWithAddress.statusCode !== 200) {
        throw new Error("Error creating contractor");
      }
      setConfirmationModal(true);
    } catch (error) {
      // Handle any errors that occurred during the mutation
      console.error("Error creating contractor:", error);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout showFooter={true}>
      <div id="contractor-page">
        {isLoading ? (
          <Loader />
        ) : (
          <FormProvider initialValues={initialValues}>
            <ContractorForm onSubmit={handleCreateContractor} />
          </FormProvider>
        )}
      </div>
      <div id="contractor-confirmation">
        <Modal isOpen={confirmationModal} onClose={handleCloseClick}>
          <>
            <img src={messageSentImage} alt="confirmation sign" />
            <p>Your request has been sent.</p>
            <p>We will contact you by email.</p>
          </>
        </Modal>
        <Modal
          isOpen={errorModalOpen}
          onClose={(e) => setErrorModalOpen(false)}
        >
          <>
            <img
              style={{ width: "50px" }}
              src={warningSignImage}
              alt="warning sign"
            />
            <p>An error occured.</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};

export default Contractor;
