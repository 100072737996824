import React, { useState, useRef, useEffect } from "react";
import "./css/AddressAutocomplete.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export default ({initialAddress, onChange, showAptField, addressErrors}) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_API_KEY_ADDRESS,
        language: "en",
        options: {
            types: ["geocode", "establishment"],
            componentRestrictions: { country: ["ca", "us"] },
        },
        debounce: 300,
    });
    const [errors, setErrors] = useState(addressErrors || {});
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);
    const [address, setAddress] = useState("");
    const [apt, setApt] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
          if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
            setShowSuggestions(false);
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (initialAddress) {
            setAddress(initialAddress.address);
            setApt(initialAddress.apt);
            setCity(initialAddress.city);
            setState(initialAddress.state);
            setZip(initialAddress.zip);
            setCountry(initialAddress.country);
        }
    }, [initialAddress]);

    useEffect(() => {
        if (addressErrors) {
            setErrors(addressErrors);
        }
    }, [addressErrors]);

    function handleSuggestionClicked(placeId) {
        placesService.getDetails(
            {
                placeId,
            },
            (placeDetails) => {
                fillInAddress(placeDetails);
                setShowSuggestions(false);
            }
        );
    }

    function fillInAddress(place) {
        const adressInput = inputRef.current;
        const addressArray = place.address_components;
        const number = addressArray.find((element) => element.types.includes("street_number"))?.long_name;
        const street = addressArray.find((element) => element.types.includes("route"))?.long_name;
        const shortAddress = number && street
            ? `${number} ${street}`
            : place.formatted_address;
        const subpremise = addressArray.find((element) => element.types.includes("subpremise"))?.long_name;
        const cityName = addressArray.find((element) => element.types.includes("locality"))?.long_name;
        const stateCode = addressArray.find((element) => element.types.includes("administrative_area_level_1"))?.short_name;
        const postalCode = addressArray.find((element) => element.types.includes("postal_code"))?.long_name;
        const countryName = addressArray.find((element) => element.types.includes("country"))?.long_name

        if (subpremise) {
            setApt(subpremise);
            if (onChange) {
                onChange({ target: { name: "apt", value: subpremise } });
            }
        }
        if (cityName) {
            setCity(cityName);
            if (onChange) {
                onChange({ target: { name: "city", value: cityName } });
            }
        }
        if (stateCode) {
            setState(stateCode);
            if (onChange) {
                onChange({ target: { name: "state", value: stateCode } });
            }
        }
        if (postalCode) {
            setZip(postalCode);
            if (onChange) {
                onChange({ target: { name: "zip", value: postalCode } });
            }
        }
        if (countryName) {
            setCountry(countryName);
            if (onChange) {
                onChange({ target: { name: "country", value: countryName } });
            }
        }
        
        adressInput.value = shortAddress;
        setAddress(shortAddress);
        if (onChange) {
            onChange({ target: { name: "address", value: shortAddress } })
        }
    }

    const renderItem = (item) => {
        const matchedSubstrings = item?.matched_substrings;
        if (matchedSubstrings) {
            const mainText = item?.structured_formatting?.main_text;
            let renderedText = [];
            let currentIndex = 0;

            matchedSubstrings.forEach((substring, index) => {
                // Add the text before the matched substring
                renderedText.push(
                    <span key={`text-${index}-${currentIndex}`}>
                        {mainText.substring(currentIndex, substring.offset)}
                    </span>
                );

                // Add the matched substring with bold style
                renderedText.push(
                    <span
                        key={`matched-${index}`}
                        className="google-suggestion-matched"
                    >
                        {mainText.substring(
                            substring.offset,
                            substring.offset + substring.length
                        )}
                    </span>
                );

                // Update the current index
                currentIndex = substring.offset + substring.length;
            });

            // Add the remaining text after the last matched substring
            renderedText.push(
                <span key={mainText}>{mainText.substring(currentIndex)}</span>
            );

            renderedText.push(
                <span key={`suggestion-${item.place_id}`} className="google-suggestion-secondary">
                    {` ${item?.structured_formatting?.secondary_text}`}
                </span>
            );

            return (
                <div className="google-suggestion-item" onClick={() => handleSuggestionClicked(item.place_id)} key={item.place_id}>
                    {renderedText}
                </div>
            );
            
        }
    };

    function handleBlur(e) {
        if (e.target.value.length === 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [e.target.name]: ["* This field is required"]
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [e.target.name]: []
            }));
        }
    }
    
    return (
        <>
            <div data-testid="address-input" className={`form-group-input address-input ${errors.address && errors.address.length > 0 ? "has-error" : ""}`}>
                <label className="titreInput">Address</label>
                <input
                    ref={inputRef}
                    value={address}
                    name="address"
                    className="form-control"
                    placeholder="Enter your address"
                    onChange={(evt) => {
                        getPlacePredictions({ input: evt.target.value });
                        setShowSuggestions(true);
                        if (onChange) {
                            onChange(evt);
                        } else {
                            setAddress(evt.target.value);
                        }
                    }}
                    onBlur={handleBlur}
                />
                {showSuggestions &&
                    <div ref={suggestionsRef} className="google-suggestion-container">
                        {placePredictions.map((item, index) => (
                            <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
                        ))}
                    </div>
                }
                {
                    errors.address && errors.address[0] &&
                    <ul className="error-messages">
                        <li className="error">{errors.address[0]}</li>
                    </ul>
                }
            </div>
            {showAptField &&
                <div className={`form-group-input apt-input ${errors.apt && errors.apt.length > 0 ? "has-error" : ""}`}>
                    <label className="titreInput">Apt, Suite, etc</label>
                    <input
                        name="apt"
                        className="form-control"
                        value={apt}
                        type="text"
                        onChange={onChange ? onChange : (e) => {setApt(e.target.value)}}
                        onBlur={handleBlur}
                        placeholder="Apt, Suite, etc (optional)"
                        
                    />
                    { 
                        errors.apt && errors.apt[0] &&
                            <ul className="error-messages">
                                <li className="error">{errors.apt[0]}</li>
                            </ul>
                    }
                </div>
            }
            <div data-testid="city-input" className={`form-group-input city-input ${errors.city && errors.city.length > 0 ? "has-error" : ""}`}>
                <label className="titreInput">City</label>
                <input
                    name="city"
                    className="form-control"
                    value={city}
                    type="text"
                    onChange={onChange ? onChange : (e) => {setCity(e.target.value)}}
                    onBlur={handleBlur}
                    placeholder="City"
                />
                {
                    errors.city && errors.city[0] &&
                        <ul className="error-messages">
                            <li className="error">{errors.city[0]}</li>
                        </ul>
                }
            </div>
            <div data-testid="state-input" className={`form-group-input state-input ${errors.state && errors.state.length > 0 ? "has-error" : ""}`}>
                <label className="titreInput">State or province</label>
                <input
                    name="state"
                    className="form-control"
                    value={state}
                    type="text"
                    onChange={onChange ? onChange : (e) => {setState(e.target.value)}}
                    onBlur={handleBlur}
                    placeholder="State"
                />
                {
                    errors.state && errors.state[0] &&
                        <ul className="error-messages">
                            <li className="error">{errors.state[0]}</li>
                        </ul>
                }
            </div>
            <div data-testid="zip-input" className={`form-group-input postal-code-input ${errors.zip && errors.zip.length > 0 ? "has-error" : ""}`}>
                <label className="titreInput">Postal Code</label>
                <input
                    name="zip"
                    className="form-control"
                    value={zip}
                    type="text"
                    onChange={onChange ? onChange : (e) => {setZip(e.target.value)}}
                    onBlur={handleBlur}
                    placeholder="Zip/Postal Code"
                />
                {
                    errors.zip && errors.zip[0] &&
                        <ul className="error-messages">
                            <li className="error">{errors.zip[0]}</li>
                        </ul>
                    
                }
            </div>
            <div data-testid="country-input" className={`form-group-input country-input ${errors.country && errors.country.length > 0 ? "has-error" : ""}`}>
                <label className="titreInput">Country</label>
                <input
                    name="country"
                    className="form-control"
                    value={country}
                    type="text"
                    onChange={onChange ? onChange : (e) => {setCountry(e.target.value)}}
                    onBlur={handleBlur}
                    placeholder="Country"
                />
                {
                    errors.country && errors.country[0] &&
                        <ul className="error-messages">
                            <li className="error">{errors.country[0]}</li>
                        </ul>
                }
            </div>
        </>
    );
};