import React, { useContext, useEffect, useState } from "react";
import { FormContextGlobal } from "../Form/FormProvider";
import FormInputGlobal from "../Form/InputGlobal";
import {
  MAX_LENGTH,
  VALIDATOR_FORMATS,
  requiredValidator,
  fieldFormatValidator,
} from "../../constants/RequiredValidator";
import Button from "../Buttons/Button";
import "./css/ContractorForm.css";
import AddressAutocomplete from "../Form/AddressAutocomplete";

const ContractorForm = ({ onSubmit }) => {
  const { data, registerInput } = useContext(FormContextGlobal);
  const [addressErrors, setAddressErrors] = useState({});
  const [initialAddress, setInitialAddress] = useState(
    {
        address: data["Street1"] ? data["Street1"] : "",
        apt: data["Street2"] ? data["Street2"] : "",
        city: data["City"] ? data["City"] : "",
        state: data["Province"] ? data["Province"] : "",
        country: data["Country"] ? data["Country"] : "",
        zip: data["PostalCode"] ? data["PostalCode"] : "",
    }
  );
  const postalCodeFormat =
    data["Country"] === "Canada"
      ? VALIDATOR_FORMATS.POSTAL_CODE_CA
      : VALIDATOR_FORMATS.POSTAL_CODE_US;
  const [validators, setValidators] = useState({
    ContractorName: [requiredValidator],
    Street1: [requiredValidator],
    City: [requiredValidator],
    Province: [requiredValidator],
    Country: [requiredValidator],
    PostalCode: [
      requiredValidator,
      (e) =>
        fieldFormatValidator(
          e,
          data["Country"] === "Canada"
            ? VALIDATOR_FORMATS.POSTAL_CODE_CA
            : VALIDATOR_FORMATS.POSTAL_CODE_US
        ),
    ],
  });

  useEffect(() => {
    setValidators((prevValidators) => ({
      ...prevValidators,
      PostalCode: [
        requiredValidator,
        (e) => fieldFormatValidator(e, postalCodeFormat),
      ],
    }));
  }, [data["Country"]]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      onSubmit(data);
    }
  };

  const validateForm = () => {
    let errorsBeforeSubmit = [];

    for (const validator in validators) {
      const errors = registerInput(validator, validators[validator])(data[validator]);
      errorsBeforeSubmit.push(
        errors
      );
      switch (validator) {
        case "Street1":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            address: errors,
          }));
          break;
        case "City":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            city: errors,
          }));
          break;
        case "Province":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            state: errors,
          }));
          break;
        case "Country":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            country: errors,
          }));
          break;
        case "PostalCode":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            zip: errors,
          }));
          break;
        default:
          break;
      }
    }

    let isValid = true;
    for (const field in errorsBeforeSubmit) {
      if (errorsBeforeSubmit[field].length > 0) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setInitialAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
    handleAdressInputsErrors(name, value);
  }

  const handleAdressInputsErrors = (name, value) => {
    switch (name) {
      case "address":
        data["Street1"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          address: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "city":
        data["City"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          city: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "state":
        data["Province"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          state: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "zip":
        data["PostalCode"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          zip: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "country":
        data["Country"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          country: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      default:
        break;
    }
  };


  return (
    <>
      <h1>CREATE CONTRACTOR</h1>
      <form noValidate id="contractor-form" onSubmit={handleSubmit}>
          <div className="name-input">
            <FormInputGlobal
              type="text"
              name="ContractorName"
              placeholder="Contractor name"
              required={true}
              validators={validators.ContractorName}
              maxLength={MAX_LENGTH.TEXT}
              label="Contractor name"
            />
          </div>
          <AddressAutocomplete
            initialAddress={initialAddress}
            onChange={handleAddressChange}
            addressErrors={addressErrors}
            onBlur={(e) => handleAdressInputsErrors(e.target.name, e.target.value)}
          />
          <Button text="Send" type="submit" />
      </form>
    </>
  );
};

export default ContractorForm;
