/**
 * Converts JavaScript boolean values and nested objects to their Python equivalent.
 *
 * @param {boolean|Array|object} input - The input value to be converted.
 * @return {boolean|Array|object} The converted value in Python format.
 */
export const convertBooleansForPython = (input) => {
  if (typeof input === "boolean") {
    return input ? "True" : "False";
  } else if (Array.isArray(input)) {
    return input.map(convertBooleansForPython);
  } else if (typeof input === "object" && input !== null) {
    const newObject = {};
    for (const key in input) {
      newObject[key] = convertBooleansForPython(input[key]);
    }
    return newObject;
  }
  return input;
};
