import React from "react";
import { FormProvider } from '../Form/FormProvider';
import BuildingForm from './BuildingForm';
import { BUILDING_REFRESH_RATE } from "../../constants/DefaultValues";

const BuildingFormProvider = ({item, onSubmit}) => {
    const initialValues = item
        ? {
            BuildingName: item.Name,
            BuildingPhone: item.Phone,
            Street1: item.Address,
            City: item.City,
            Province: item.Province,
            Country: item.Country,
            PostalCode: item.PostalCode,
            RefreshRateInSec : item.RefreshRateInSec,
            IsFetchingWeather: item.IsFetchingWeather
          }
        : {
            BuildingName: '',
            BuildingPhone: '',
            Street1: '',
            City: '',
            Province: '',
            Country: '',
            PostalCode: '',
            RefreshRateInSec: BUILDING_REFRESH_RATE,
            IsFetchingWeather: false
        };

    const originalValues = JSON.parse(JSON.stringify(initialValues));
    
    const handleSubmit = (data) => {
        if (!isEqualToInitialValue(data)) {
            data.PostalCode = data.PostalCode.toUpperCase();
            onSubmit(data);
        }
    };

    const isEqualToInitialValue = (data) => {
        return JSON.stringify(originalValues) === JSON.stringify(data);
    };

    const toggleFetchWeather = () => {
        initialValues.IsFetchingWeather = !initialValues.IsFetchingWeather;
    };

    return (
        <FormProvider initialValues={initialValues} onSubmit={(event) => handleSubmit(event)}>
            <BuildingForm item={item} onSubmit={handleSubmit} onToggleFetchWeather={toggleFetchWeather}/>
        </FormProvider>
    );
};

export default BuildingFormProvider;
