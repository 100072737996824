import React from 'react';
import './css/SidebarHeader.css'
import backArrowImg from '../../img/back-arrow.png'
import { useNavigate } from 'react-router-dom';
import { useSharedData } from "../../context/SharedProvider";

const SidebarHeader = (props) => {
    const navigate = useNavigate();
    const { buildingInfo } = useSharedData();

    function handleNavigateBack() {
        navigate("/buildings");
    }

    return (
        <div id='sidebar-header'>
            <div id='back-button' onClick={handleNavigateBack}>
                <img src={backArrowImg} alt='Back button' />
            </div>
            <div id='building-info'>
                {
                    buildingInfo
                        ? <>
                            <h1 data-testid='sidebar-building-name'>{buildingInfo.Name}</h1>
                            <p>{buildingInfo.Address}</p>
                        </>
                        : <>
                            <div className='skeleton'></div>
                            <div className='skeleton'></div>
                        </>
                }
            </div>
        </div>
    );
};

export default SidebarHeader;
