import React from 'react';
import './css/Button.css';

const Button = ({text, onClick, type, disabled, style, autoFocus, dataTestid}) => {
    return (
        <button
            disabled={disabled}
            data-testid={dataTestid}
            className="vidatech-button"
            onClick={onClick}
            type={type || "button"}
            style={style}
            autoFocus={autoFocus}
        >
            {text}
        </button>
    );
};

export default Button;