import React from 'react';
import './css/VidatechLoader.css';

const VidatechLoader = () => {
    return (
        <div className="loading-modal-overlay">
            <div id="loading-modal-content">
                <video id="loading-video" autoPlay loop muted>
                    <source src="/videos/loading-video.webm" type="video/webm" />
                    <source src="/videos/loading-video.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default VidatechLoader;