import React from "react";
import "./css/BackButton.css";
import backArrow from "../../img/back-arrow.svg";
import { useNavigate } from 'react-router-dom';

/**
 * Renders a back button component.
 *
 * @param {string} props.text - The text to display on the button.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @returns {JSX.Element} The back button component.
 */
const BackButton = (props) => {
  const navigate = useNavigate();

  function handleClick(e) {
    if (props.onClick) {
      props.onClick(e);
    }
    if (!e.defaultPrevented) {
      if (props.to) {
        navigate(props.to);
      } else {
        navigate(-1);
      }
    }
  }

  return (
    <button className='back-button' onClick={handleClick}>
      <div className='back-arrow'>
        <img src={backArrow} alt='back-arrow'></img>
      </div>
      {props.text &&
        <div className='text'>
          <p>{props.text}</p>
        </div>
      }
    </button>
  );
};

export default BackButton;
