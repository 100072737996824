import React, { useState, useEffect } from "react";
import "./css/FileExplorerItemDetails.css";
import folder from "../../img/folder.svg";
import Loader from "../../constants/Loader";
import { useSharedData } from "../../context/SharedProvider";
import { MAX_LENGTH } from "../../constants/DataValidation";
import errorImage from "../../img/error-image.svg";

const FileExplorerItemDetails = ({ selectedItem }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { downloadImage } = useSharedData();
    const [resolution, setResolution] = useState(null);

    useEffect(() => {
        if (selectedItem && !selectedItem.data) {
            setResolution(null);
            if (selectedItem.type !== "folder" && selectedItem.type !== "ttf") {
                const getImage = async () => {
                    setIsLoading(true);
                    const itemPath = selectedItem.type === "widget"
                        ? selectedItem.content.find(file => file.name.endsWith(".png")).path
                        : selectedItem.path;
                    try {
                        selectedItem.data = await downloadImage(itemPath);
                    } catch (error) {
                        selectedItem.data = errorImage;
                    } finally {
                        setIsLoading(false);
                    }
                };
                getImage();
            }
        }
    }, [selectedItem]);

    const formattedSize = (size) => {
        if (typeof size === "number") {
            if (size > 1024 * 1024) {
                return `${(size / (1024 * 1024)).toFixed(2)} Mo`;
            } else if (size > 1024) {
                return `${(size / 1024).toFixed(2)} Ko`;
            } else {
                return `${size} o`;
            }
        } else {
            return "N/A";
        }
    };

    return (
        <div
            data-testid="selected-item-details"
            id="selected-item-details"
        >
            <div>
                <div id="selected-item-preview">
                    { selectedItem
                        ? selectedItem.type === "folder"
                            ? <img src={folder} alt="folder icon" />
                            : selectedItem.type === "ttf"
                                ? <img src={require("../../img/font-file-preview.png")} alt="font file" />
                                : isLoading || !selectedItem.data
                                    ? <Loader />
                                    : <img src={selectedItem.data} onLoad={(e) => setResolution({width: e.target.naturalWidth, height: e.target.naturalHeight})} alt="file-preview" />
                        : <img src={require("../../img/item-preview.png")} alt="item" />
                    }
                </div>
                <p id="selected-item-name">
                    {selectedItem && selectedItem.name.length > MAX_LENGTH.FILE_EXPLORER_ITEM_NAME.DETAILS
                        ?   <span
                                data-tooltip={selectedItem.name}
                                data-position="bottom"
                            >
                                {selectedItem.name.substring(0, MAX_LENGTH.FILE_EXPLORER_ITEM_NAME.DETAILS) + "..."}
                            </span>
                        : selectedItem && selectedItem.name
                    }
                </p>
                <div className="property-options">
                    <h4 className="property-title">File informations</h4>
                    {selectedItem ?
                        <>
                            <div className="property-group">
                                <h5 className="property-name">Type</h5>
                                <p>{selectedItem && selectedItem.type}</p>
                            </div>
                            {selectedItem && !selectedItem.path.startsWith("/library/vidatech/") &&
                                <div className="property-group">
                                    <h5 className="property-name">Size</h5>
                                    <p>{selectedItem && formattedSize(selectedItem.size)}</p>
                                </div>
                            }
                            {selectedItem && (selectedItem.type === "jpg" || selectedItem.type === "png") &&
                                <div className="property-group">
                                    <p className="property-name">Resolution</p>
                                    {resolution && <p>{`${resolution.width} x ${resolution.height}`}</p>}
                                </div>
                            }
                            <div className="property-group">
                                <h5 className="property-name">Path</h5>
                                <p>
                                    {selectedItem && selectedItem.path.length > MAX_LENGTH.FILE_EXPLORER_ITEM_PATH
                                        ?   <span data-tooltip={selectedItem && selectedItem.path} data-position="bottom">
                                                {selectedItem.path.substring(0, MAX_LENGTH.FILE_EXPLORER_ITEM_PATH - 3) + "..."}
                                            </span>
                                        : selectedItem && selectedItem.path
                                    }
                                </p>
                            </div>
                        </>
                        :
                        <div className="property-group">
                            <p>Select your file to have his informations.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FileExplorerItemDetails;
