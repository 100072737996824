import React, { useState } from "react";
import SidebarHeader from "./SidebarHeader";
import "./css/Sidebar.css";
import InfoModal from "../Modal/InfoModal";
import Button from "../Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { CustomAttributes } from "../../constants/CustomAttributes";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { RenderUserSpecificMenu } from "../RenderUser/RenderUserSpecificMenu";

const Sidebar = (props) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const location = useLocation();
  const buildingPage = location.pathname.includes("buildings/");
  const buildingId = useParams().buildingId;
  const currentPage = useParams()["*"];
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  let userName = "";
  let role = "";
  

  const hasAccessContractor = RenderUserSpecificMenu(
    user,
    CustomAttributes.CONTRACTOR
  );
  
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  if (groups && groups.length > 0) {
    role = groups[0];
    userName = user.attributes.email;
  }

  function handleClickProfile(e) {
    e.stopPropagation();
    setShowProfileModal(!showProfileModal);
  }

  function handleClickAbout(e) {
    e.stopPropagation();
    setShowAboutModal(!showAboutModal);
  }

  async function handleLogOut() {
    try {
      await Auth.signOut();
      sessionStorage.removeItem("postLoginRedirect"); // Reset the flag on sign out
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }

  return (
    <div id="sidebar">
      {buildingPage && <SidebarHeader />}
      <div id="navigation-panel">
        {buildingPage ? (
          <ul>
            <li className={`menu-item ${currentPage === "products" && "selected"}`}>
              <Link data-testid="products-link" to={`/buildings/${buildingId}/products`}>
                <img
                  src={require("../../img/soustraction-6-1@1x.png")}
                  alt="product menu icon"
                />
                <span>PRODUCTS</span>
              </Link>
            </li>
            <li className={`menu-item ${currentPage === "visuals" && "selected"}`}>
              <Link data-testid="visual-link" to={`/buildings/${buildingId}/visuals`}>
                <img
                  src={require("../../img/picto-theme-4@1x.png")}
                  alt="visual menu icon"
                />
                <span>VISUAL</span>
              </Link>
            </li>
            <li className={`menu-item ${currentPage === "library" && "selected"}`}>
              <Link data-testid="library-link" to={`/buildings/${buildingId}/library`}>
                <img
                  src={require("../../img/picto-library.png")}
                  alt="visual menu icon"
                />
                <span>LIBRARY</span>
              </Link>
            </li>
            {/* <li className={`menu-item ${currentPage === "log" && "selected"}`}>
              <Link to={`/buildings/${buildingId}/${currentPage}`}> //buildings/${buildingId}/log`
                <img
                  src={require("../../img/groupe-518-1@1x.png")}
                  alt="log menu icon"
                />
                <span>LOG</span>
              </Link>
            </li> */}
          </ul>
        ) : (
          <ul>
            <li className={`menu-item ${currentPage === "buildings" && "selected"}`}>
              <Link to={`/buildings`}>
                <img
                  src={require("../../img/trac-414-11.png")}
                  alt="buildings menu icon"
                />
                <span>BUILDINGS</span>
              </Link>
            </li>
            {hasAccessContractor && (
              <li className={`menu-item ${currentPage === "firstRespondermanagement" && "selected"}`}>
                <Link to={`/firstRespondermanagement`}>
                  <img
                    src={require("../../img/soustraction-38-7.png")}
                    alt="mechanics menu icon"
                  />
                  <span>MECHANICS</span>
                </Link>
              </li>
            )}
            { groups && groups.length > 0
              && (groups.includes("VidatechAdmin") || groups.includes("Admin")) &&
                <li className={`menu-item ${currentPage === "usermanagement" && "selected"}`}>
                <Link to={`/usermanagement`}>
                  <img
                    src={require("../../img/picto-user.png")}
                    alt="user menu icon"
                  />
                  <span>USER</span>
                </Link>
              </li>
            }
            {groups && groups.length > 0 && groups.includes("VidatechAdmin") && 
              <li className={`menu-item ${currentPage === "vidatechadmin" && "selected"}`}>
                <Link to={`/vidatechadmin`}>
                  <img
                    src={require("../../img/soustraction-38-7.png")}
                    alt="vidatech admin menu icon"
                  />
                  <span>VIDATECH ADMIN</span>
                </Link>
              </li>
            }
          </ul>
        )}
      </div>
      <div className="sidebar-footer">
        {showProfileModal && (
          <div id="profile-modal">
            <InfoModal
              onClose={() => {
                setShowProfileModal(false);
              }}
            >
              <div id="user-modal">
                <img
                  src={require("../../img/soustraction-36-17.png")}
                  alt="profile"
                />
                <div id="user-info">
                  <p id="user-name">{userName}</p>
                  <p>{role}</p>
                </div>
              </div>
              <div className="user-modal-separator"></div>
              <Button text="Logout" onClick={handleLogOut} />
            </InfoModal>
          </div>
        )}
        <div id="profile" onClick={handleClickProfile}>
          <img
            src={require("../../img/soustraction-36-17.png")}
            alt="profile"
          />
          <div id="greetings">
            <p>Hi,</p>
            <p id="greetings-user-name">{userName}</p>
          </div>
        </div>
        {showAboutModal && (
          <div id="about-modal">
            <InfoModal
              onClose={() => {
                setShowAboutModal(false);
              }}
            >
              <div>
                <h2>CONTACT US</h2>
                <p>1 (418) 476-4802</p>
                <p>info@vidatech.ca</p>
                <a href="https://vidatechelevator.com/" target="_blank">
                  vidatechelevator.com
                </a>
              </div>
              <a
                href="https://www.linkedin.com/company/vidatech/"
                target="_blank"
                id="linked-in-link"
              >
                <img
                  src={require("../../img/icon-linkedin-1@1x.png")}
                  alt="linkedin logo"
                />
              </a>
            </InfoModal>
          </div>
        )}
        <div id="tools">
          <div id="about-button" onClick={handleClickAbout}>
            <span>?</span>
          </div>
          {/* <div
            id="language-button"
            onClick={() => alert("language button clicked")}
          >
            <p>EN /</p>
            <img src="/src/assets/language-arrow.png" alt="language arrow" />
            <p>FR</p>
          </div> */}
        </div>
        <div id="logo">
          <img
            src={require("../../img/logo-vidatech-final-couleur-240pk-1-1x-png@1x.png")}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
