import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listVisualsStorm, listModelsName } from "../../graphql/queries";
import { deleteVisualStorm, createVisualStorm } from "../../graphql/mutations";
import { StatusCodes } from "../../constants/StatusCodes";
// import {  } from "../../graphql/queries"; TODO: [ST2-506] add query for visuals
import Layout from '../Layout/Layout';
import { VISUAL_HEADERS } from "../../constants/TableHeaders";
import Loader from '../../constants/Loader';
import ItemsTable from '../../components/Table/ItemsTable';
import VisualMenuItem from '../../components/Visual/VisualMenuItem';
import Modal from '../../components/Modal/Modal';
import VisualFormProvider from '../../components/Visual/VisualFormProvider';
import './css/visual.css';

const Visual = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { buildingId } = useParams();
  const [visuals, setVisuals] = useState([]);
  const [createVisualModalOpen, setCreateVisualModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [models, setModels] = useState([]);

  useEffect(() => {
    getVisuals();
  }, []);
  
  async function getVisuals() {
    try {
      setIsLoading(true);
      await getModels();
      const values = await API.graphql(
        graphqlOperation(listVisualsStorm, {
          buildingID: buildingId,
        })
      );
      
      if (values.data.listVisualsStorm.statusCode !== StatusCodes.OK) {
        throw new Error("Error getting visuals");
      }
      
      const visualPaths = JSON.parse(values.data.listVisualsStorm.body);

      const visualModels = [];
      for (const visual of visualPaths) {
        const resolutionPattern = /_R_(\d+x\d+)$/;
        const visualName = visual.path.split("/", 1)[0];
        const match = visualName.match(resolutionPattern);
        if (!visualModels.some((model) => model.name === visualName.replace(resolutionPattern, ""))) {
          // const xmlString = await API.graphql(
          //   graphqlOperation(
          //     getVisualStorm,
          //     { input :
          //       {
          //         buildingID: buildingId,
          //         folderName: visualName,
          //       }
          //     }
          //   )
          // );

          // if (xmlString.data.getVisualStorm.statusCode !== StatusCodes.OK) {
          //   throw new Error("Error getting displays");
          // }
    
          // const xml = xmlString.data.getVisualStorm.body;
          // const displayConfig = parseXMLFile(xml);
          // console.log("displays value : ", displayConfig);

          visualModels.push(
            { 
              name: match ? visualName.replace(resolutionPattern, "") : visualName,
              // resolution: `${displayConfig.Screen["@_x_resolution"]} x ${displayConfig.Screen["@_y_resolution"]}`,
              // screenOrientation: displayConfig.Screen["@_rotation"],
              resolution: match ? match[1] : '',
              screenOrientation: '',
              positionIndicator: '',
              visualLinked: '',
              scheduleLinked: ''
              }
          );
        }
      }
      visualModels.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
      setVisuals(visualModels);
    } catch (e) {
      if (e.message) {
        setErrorMessage("An error occured while fetching visuals");
        setVisuals([]);
      }
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getModels() {
    try {
        const values = await API.graphql(graphqlOperation(listModelsName));
        setModels(values.data.listModels.items);
    } catch {
        setErrorModalOpen(true);
        setErrorMessage("An error occured while getting the models");
    }
  }
  // const parseXMLFile = (xml) => {
  //   const alwaysArray = [
  //     "Screen.Page",
  //     "Screen.Page.Image",
  //     "Screen.Page.Playlist",
  //     "Screen.Page.Travel_Info",
  //     "Screen.Page.Playlist.Image"
  //   ];
  //   const options = {
  //     // preserveOrder: true,
  //     ignoreAttributes: false,
  //     attributeNamePrefix: "@_",
  //     //name: is either tagname, or attribute name
  //     //jPath: upto the tag name
  //     isArray: (name, jpath, isLeafNode, isAttribute) => { 
  //       if( alwaysArray.indexOf(jpath) !== -1) return true;
  //   }
  //   };
  //   if (XMLValidator.validate(xml)) {
  //     const parser = new XMLParser(options);
  //     return parser.parse(xml);
  //   }
  // };

  const handleDelete = async (visualName) => {
    try {
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(deleteVisualStorm, { input: { 
          buildingID: buildingId,
          folderName: visualName,
         } })
      );
      if (
        result.data.deleteVisualStorm.statusCode !==
        StatusCodes.OK
      ) {
        throw new Error("Error deleting visual");
      }
      await getVisuals();
    } catch (error) {
      setErrorMessage("Error deleting visual.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (data) => {
    try {
      setIsLoading(true);
      //TODO : check if a visual with the same name already exists
      if (visuals.some((visual) => visual.name === data.name)) {
        throw new Error("A visual with the same name already exists");
      }
      setCreateVisualModalOpen(false);

      const input = {
        buildingID: buildingId,
        folderName: data.name,
        resolution: data.resolution,
        screenOrientation: data.screenOrientation,
      }

      const result = await API.graphql(
        graphqlOperation(createVisualStorm, { input: input })
      );
      if (
        result.data.createVisualStorm.statusCode !==
        StatusCodes.OK
      ) {
        throw new Error("Error creating visual");
      }
      await getVisuals();
    } catch (error) {
      setErrorMessage("Error creating visual.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSortBy = (header, sortOrder) => {
    const sortedVisuals = [...visuals].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[VISUAL_HEADERS[header]].localeCompare(b[VISUAL_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return b[VISUAL_HEADERS[header]].localeCompare(a[VISUAL_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
      }
    });
    setVisuals(sortedVisuals);
  };

  return (
    <Layout
      headerText="VISUAL"
      showSidebar={true}
      showFooter={false}
      headerButtonText='Create a visual'
      headerButtonDisabled={isLoading || models.length === 0}
      onHeaderButtonClick={() => setCreateVisualModalOpen(true)}>
      {isLoading ? <Loader /> :
        <ItemsTable
          headers={VISUAL_HEADERS}
          onSortBy={handleSortBy}
          >
          {visuals && visuals.map((item) => (
            <VisualMenuItem key={item.name} item={item} onDelete={handleDelete} />
          ))}
        </ItemsTable>
      }
      <div className='form-visual-modal'>
        <Modal
          isOpen={createVisualModalOpen}
          headerText='CREATE A VISUAL'
          onClose={() => setCreateVisualModalOpen(false)}
        >
          {!isLoading && <VisualFormProvider models={models} onSubmit={handleCreate} />}
        </Modal>
      </div>
      <div className="error-modal">
        <Modal
          isOpen={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
        >
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="warning sign"
            />
            <p>{errorMessage}</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};

export default Visual;
