import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./globals.css";
import "./styleguide.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { theme } from "./constants/AuthenticatorOptions";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AmplifyProvider theme={theme}>
      <App />
    </AmplifyProvider>
  </React.StrictMode>
);
reportWebVitals();
