import React, { useState, useContext } from "react";
import FormInputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import Button from "../Buttons/Button";
import FormSelectGlobal from "../Form/SelectGlobal";

import { RadioGlobal } from "../Form/RadioGlobal";
import {MAX_LENGTH, requiredValidator } from "../../constants/RequiredValidator";
import { SCREEN_ORIENTATION } from "../../constants/FormOptions";

const VisualForm = (props) => {
    const { data, registerInput } = useContext(FormContextGlobal);
    const [validators] = useState({
        name: [requiredValidator],
        modelId: [requiredValidator],
    });

    const modelsOptions = props.models.map((model) => ({
        text: `${model.Name} ${
          model.Description ? `(${model.Description})` : ""
        } (${model.Resolution.slice(2)})`,
        value: model.id,
    }));

    const handleToggleValue = (event) => {
        const { name, value } = event.target;
        data[name] = value;      
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validateForm()) {
          data.resolution = props.models.find(model => model.id === data.modelId).Resolution;
          props.onSubmit(data);
        }
      };

    const validateForm = () => {
        let errorsBeforeSubmit = [];
    
        for (const validator in validators) {
          errorsBeforeSubmit.push(registerInput(validator, validators[validator])(data[validator]));
        }
    
        let isValid = true;
        for (const field in errorsBeforeSubmit) {
          if (errorsBeforeSubmit[field].length > 0) {
            isValid = false;
            break;
          }
        }
    
        return isValid;
    };

    return (
        <form
            noValidate
            id="visual-form"
            onSubmit={(event) => handleSubmit(event)}
        >
            <FormInputGlobal
                type="text"
                name="name"
                maxLength={MAX_LENGTH.TEXT}
                placeholder="Visual name"
                validators={validators.name}
                label="Name"
                data-testid="visual-name-input"
            />
            <FormSelectGlobal
                label="Select a model"
                name="modelId"
                // value={data.resolution}
                validators={validators.resolution}
                optionsWithValue={modelsOptions}
            />
            <RadioGlobal
                name="screenOrientation"
                label="Screen orientation"
                value={data.screenOrientation}
                dataTestid="rotation-input"
                onChange={(event) => handleToggleValue(event)}
                options={[
                    {id: "portait", value: SCREEN_ORIENTATION.PORTRAIT, text: "Portrait"},
                    {id: "landscape", value: SCREEN_ORIENTATION.LANDSCAPE, text: "Landscape"},
                ]}
            />

            <Button dataTestid="create-visual-button" text="Create" type="submit" />
        </form>
    );
};

export default VisualForm;
