import React, { useState, useEffect } from "react";
import FirstResponderForm from "./FirstResponderForm";
import { withTranslation } from "react-i18next";

import { FormProvider } from "../Form/FormProvider";

const FirstResponderFormProvider = (props) => {
  const item = props.item;
  const [initialValues, setInitialValues] = useState( props.item
      ? {
          id: item.id || "",
          Number: item.Number || "",
          Name: item.Name || "",
          Availability: item.Availability || {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          },
        }
      : {
          id: "",
          Number: "",
          Name: "",
          Availability: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          },
        }
  );

  useEffect(() => {
    if (item && Object.keys(item).length > 0) {
      const availabilityItem = { ...item.Availability } || {};
      const availabilityValues = initializeAvailabilityValues(availabilityItem);

      setInitialValues({
        Name: item.Name || "",
        Number: item.Number || "",
        Availability: availabilityItem,
        ...availabilityValues
      });
    }
  }, [item]);

  const initializeAvailabilityValues = (availabilityItem) => {
    const availabilityValues = {};

      Object.keys(availabilityItem).forEach((day) => {
        availabilityItem[day].forEach((slot, index) => {
          const startFieldName = `${day}_${index}_start`;
          const endFieldName = `${day}_${index}_end`;
          availabilityValues[startFieldName] = slot.Start || "";
          availabilityValues[endFieldName] = slot.Finish || "";
        });
      });

    return availabilityValues;
  }

  const handleSubmit = (data) => {
    props.onSubmit(data);
  };

  const handleDataChanged = (data) => {
    const availabilityValues = initializeAvailabilityValues(data.Availability);
    setInitialValues(prevValues => ({
      ...prevValues,
      ...data,
      ...availabilityValues
    }));
  }

  return (
    <div>
      <FormProvider initialValues={initialValues}>
        <FirstResponderForm
          item={initialValues}
          onSubmit={handleSubmit}
          onDataChanged={handleDataChanged}
        ></FirstResponderForm>
      </FormProvider>
    </div>
  );
};
export default withTranslation()(FirstResponderFormProvider);
