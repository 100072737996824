export const TOOLTIP_MESSAGES = {
    IMAGE_WARNING: "Your item cannot be found in your library.",
    PLAYLIST_WARNING: "An image in your playlist cannot be found in your library.",
    ZONE_POSITION_INFO: "The reference point is at the top-left corner of the image.",
};
export const ERROR_MESSAGES = {
    UPLOAD_ERROR: "An error occured while uploading your file.",
    DOWNLOAD_ERROR: "An error occured while downloading your file.",
    DELETE_FILE : "An error occured while deleting your file.",
    FETCH_ERROR: "An error occured while fetching your images and paths.",
    SAVE_ERROR: "An error occured while saving your visual.",
    PUBLISH_ERROR: "An error occured while publishing your visual.",
    DELETE_FOLDER_ERROR: "An error occured while deleting your folder.",
    MISSING_DEFAULT_BUTTON_IMAGE: "An error occured while fetching the button images in the vidatech folder.",
    DOWNLOAD_FONT_ERROR: "Error downloading font from : ",
    ADD_FILE_TO_ZIP_ERROR: "An error occured adding the following file to zip: ",
    EXPIRED_RESSOURCE_URL: "The ressource URL has expired.",
    WIDGET_FOLDER_NOT_FOUND: "The widget folder is missing in your library.",
    WIDGET_FOLDER_EMPTY: "The widget folder is empty.",
    WIDGET_DEF_NOT_FOUND: "The widget definition file is missing.",
    FOLDER_EXISTS: "A folder with the same name already exists",
    INVALID_FOLDER_NAME: "The folder name is invalid",
    FILE_SIZE_EXCEEDED: "The file size exceeds the limit of 2Mb",
    STORAGE_LIMIT_EXCEEDED: "The storage limit has been reached. Please delete some files to free up space.",
};
export const WARNING_MESSAGES = {
    DELETE_FILE: "Are you sure you want to delete this file?",
    DELETE_FOLDER: "Are you sure you want to delete this folder?",
    FILE_USED_IN_VISUAL: "This file is used in one or more visuals. Are you sure you want to delete it?",
    FOLDER_CONTAINS_FILES_USED_IN_VISUAL: "This folder contains files used in a visual. Are you sure you want to delete it?",
    CONFIRM_SAVE_ERRORS: "There are some problems with your display:",
    CONFIRM_SAVE_BEFORE_LEAVING: "Save before leaving the visual editor.",
    SAVE_VISUAL_MISSING_IMAGES: "The display contains images that don't exist in your library anymore. Publishing or saving the display will remove them from the Visual.",
    DUPLICATE_BUTTON_FLOOR_ADDRESS: "Multiple buttons with the same floor address",
    BUTTON_OVERLAP: "Buttons are overlapping",
    BUTTON_IMAGE_MISSING: "There are issues with buttons that might affect the display.",
    BUTTON_FLOOR_ADDRESS_GAP: "There are gaps in the button floor addresses",
    BUTTON_ERRORS: "There are issues with Buttons that might affect the display.",
    FONT_MISSING: "The font file for this font family could not be found in your library",
    TEXT_ERRORS: "Some Text items have a problem with their font family. They will be deleted if you save or publish the visual.",
    WIDGET_ERRORS: "A widget is not available in your library.",
};

export const ERROR_TEXTS = {
    404: {
        title: "Page 404",
        message: "THE FLOOR YOU WERE LOOKING FOR IS LOST IN THE BUILDING!",
    },
    401: {
        title: "Page 401",
        message: "YOU NEED A FLOOR AUTHORIZATION.",
    }
}