import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { listImagesAndPathsStorm, listPageBuildingsByUser } from "../graphql/queries";
import {
  addImage,
  deleteImage,
  addFolder,
  deleteFolder
} from "../graphql/mutations";
import {
  downloadRessourceStorm,
  getDeleteRessourcesStorm,
} from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import Modal from '../components/Modal/Modal';
import { StatusCodes } from "../constants/StatusCodes";
import { FilesName } from '../constants/FilesName';
import { S3_URL_RESSOURCES_EXPIRATION_IN_MS } from '../constants/DefaultValues';

const SharedContext = createContext();

export const SharedProvider = ({ children }) => {
  const { buildingId: buildingID } = useParams();
  const navigate = useNavigate();
  const currentPage = useParams()["*"];
  const [sharedData, setSharedData] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [fileStructure, setFileStructure] = useState(null);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [isBusy, setIsBusy] = useState(false);
  const [isDownloadingImage, setIsDownloadingImage] = useState(false);
  const [buildingInfo, setBuildingInfo] = useState(null);

  useEffect(() => {
    if (!buildingInfo) {
      getBuildingInfo();
    }
    if ((currentPage === "library" || currentPage.includes("visuals/"))) {
      fetchImagesAndPaths();
    }
  }, [currentPage]);

  // Fetch images and paths every hour to avoid expiration of ressources url
  useEffect(() => {
    const interval = setInterval(() => {
      if ((currentPage === "library" || currentPage.includes("visuals/")) && !sharedData) {
        fetchImagesAndPaths();
      }
    }, S3_URL_RESSOURCES_EXPIRATION_IN_MS);
    return () => clearInterval(interval);
  }, []);

  const fetchImagesAndPaths = async () => {
    try {
      setIsBusy(true);
      const values = await API.graphql(
        graphqlOperation(listImagesAndPathsStorm, {
          buildingID: buildingID
        })
      );
      if (values.data.listImagesAndPathsStorm.statusCode !== StatusCodes.OK) {
        throw new Error("Error fetching images and paths");
      }
      const data = JSON.parse(values.data.listImagesAndPathsStorm.body);
      setSharedData(data);
      buildFileStructure(data);
    } catch (error) {
      setErrorMessage("Error fetching images and paths");
      setErrorModalOpen(true);
    } finally {
      setIsBusy(false);
    }
  };
  
  const buildFileStructure = (itemsAndPath) => {
    const calculateFolderSize = (folder) => {
      let size = 0;

      folder.content.forEach((item) => {
        if (item.type === 'folder' || item.type === 'widget') {
          size += calculateFolderSize(item);
        } else {
          if (!item.path.startsWith("/library/vidatech/")) { //Exclude files in /library/vidatech from the size calculation
            size += item.size || 0;
          }
        }
      });

      folder.size = size;
      return size;
    };

    const root = { name: '', path: '/', type: 'folder', content: [] };
  
    itemsAndPath.sort((a, b) => a.path.localeCompare(b.path)).forEach(({ path, data, size }) => {
      const parts = path.split('/').filter(Boolean);
      let currentFolder = root;

      parts.forEach((part, index) => {
        const isFile = index === parts.length - 1 && part.includes('.');
        const existingItem = currentFolder.content.find((item) => item.name === part);

        if (existingItem) {
          currentFolder = existingItem;
        } else {
          const itemName = path.split('/').pop();
          let itemPath = path;
          if (itemName !== part && !path.endsWith('/')) {
            itemPath = "/" + parts.slice(0, index + 1).join('/') + "/";
          }
          const newItem = isFile
            ? { name: part, path: path, type: part.split('.').pop(), data, size }
            : { name: part, path: itemPath, type: 'folder', content: [], size: 0 };

          currentFolder.content.push(newItem);
          currentFolder = newItem;
        }
      });
    });

    // set the type of the widgets
    const vidatechFolder = root.content[0].content.find(item => item.path === "/library/vidatech/");
    let widgetFolder = vidatechFolder.content.find(item => item.path === "/library/vidatech/widget/");
    if (widgetFolder) {
      widgetFolder.content.forEach((widgetType) =>
        widgetType.content.forEach((widget) => {
          if (widget.content.find(item => item.name === FilesName.WIDGET_DEFINITION)) {
            widget.type = "widget";
          }
        }));
    }

    if (root.content.length > 0) {
      // Calculate the size of folders and files
      root.content[0].size = calculateFolderSize(root.content[0]);
      setFileStructure(root.content[0]);
    } else {
      setFileStructure(root);
    }
  };

  async function getBuildingInfo() {
    try {
        const values = await API.graphql(
            graphqlOperation(listPageBuildingsByUser)
          );

        const buildings = values.data.listBuildingsByUser.items;
        
        const currentBuilding = buildings.find(building => building.id === buildingID);

        if (!currentBuilding) {
            navigate('/notfound', { replace: true });
        } else {
          setBuildingInfo({
            Name: currentBuilding.Name,
            Address: currentBuilding.Address.Street1
          })
        }
    } catch (error) {
        setBuildingInfo({
            Name: "",
            Address: ""
        })
    }
}

  const createFolder = async (folderName) => {
    try {
      const input = { buildingID, folderName }
      const result = await API.graphql(
        graphqlOperation(addFolder, { input: input }));
      if (result.data.addFolder.statusCode !== StatusCodes.OK) {
        throw new Error("Error adding folder");
      }
    } catch (error) {
      setErrorMessage("Error adding folder");
      setErrorModalOpen(true);
      fetchImagesAndPaths();
    }
  };

  const saveImage = async (image) => {
    let folderName = image.path.split("/").slice(0, -1).join("/");

    try {
      const input = {
        buildingID,
        folderName: folderName,
        image: image.base64,
        imageName: image.name,
        imageType: image.type,
      };
      const result = await API.graphql(
        graphqlOperation(addImage, { input: input }));
      if (result.data.addImage.statusCode !== StatusCodes.OK) {
        throw new Error("Error adding image");
      }
      //add file to sharedData
      setSharedData((prevData) => [...prevData, { path: image.fullPath, size: image.size, url: image.data}]);
    } catch (error) {
      setErrorMessage("Error adding image");
      setErrorModalOpen(true);
      fetchImagesAndPaths();
    }
  };

  const deleteItem = async (itemPath) => {
    try {
      const itemIsFolder = itemPath[itemPath.length - 1] === "/";
      const input = {
        buildingID
      };

      let result;
      if (itemIsFolder) {
        input.folderName = itemPath;
        result = await API.graphql(
          graphqlOperation(deleteFolder, { input: input }));
        if (result.data.deleteFolder.statusCode !== StatusCodes.OK) {
          throw new Error("Error deleting folder");
        }
      } else {
        input.folderName = itemPath.split("/").slice(0, -1).join("/");
        input.folderName = input.folderName === "" ? "" : input.folderName + "/";
        input.imageName = itemPath.split("/").pop();
        result = await API.graphql(
          graphqlOperation(deleteImage, { input: input })
        );

        if (result.data.deleteImage.statusCode !== StatusCodes.OK) {
          throw new Error("Error deleting file");
        }
      }
    } catch (error) {
      setErrorMessage(error.message); //TODO : check if message is displayed
      setErrorModalOpen(true);
      fetchImagesAndPaths();
    }
  };

  const downloadFont = async (path) => {
    setIsDownloadingImage(true);
    try {
      const input = {
        buildingID,
        path: path
      };
      const font = sharedData.find(item => item.path === path);
      if (font) {
        let amplifyUrl = font.url;
        if (!font.url) {
          const result = await API.graphql(
            graphqlOperation(downloadRessourceStorm, { input: input })
          );
          if (result.data.downloadRessourceStorm.statusCode !== StatusCodes.OK) {
            throw new Error("Error downloading font");
          }
          amplifyUrl = result.data.downloadRessourceStorm.body;
        }
        const response = await fetch(amplifyUrl);
        if (!response.ok) {
          throw new Error("Error downloading font from");
        }
        const fontData = await response.arrayBuffer();
        const fontBlob = new Blob([fontData]);
        const fontUrl = URL.createObjectURL(fontBlob);
        return fontUrl;
      } else {
        throw new Error("Font not found");
      }
    } catch (error) {
        throw new Error(`Error downloading font from ${path}.`); //TODO : check if error is caught
    } finally {
      setIsDownloadingImage(false);
    }
  };

  const getFontNames = () => {
    const fontPaths = sharedData.filter(item => item.path.startsWith("/library/vidatech/font/") && item.path.endsWith(".ttf"));
    const fontNames = fontPaths.map(item => item.path.split("/").pop().replace(".ttf", ""));
    return fontNames;
  };

  const downloadXml = async (path) => {
    try {
      let amplifyUrl = sharedData.find(item => item.path === path)?.url;
      if (!amplifyUrl) {
        const input = {
          buildingID,
          path: path
        };
        const result = await API.graphql(
          graphqlOperation(downloadRessourceStorm, { input: input })
        );
        if (result.data.downloadRessourceStorm.statusCode !== StatusCodes.OK) {
          throw new Error("Error downloading xml");
        }
        amplifyUrl = result.data.downloadRessourceStorm.body;
      }
      const response = await fetch(amplifyUrl);
      if (response.status !== 200) {
        throw new Error("Error downloading xml");
      }
      const xml = await response.text();
      return xml;
    } catch (error) {
      throw new Error(`Error downloading xml from ${path}.`);
    }
  };

  const downloadImage = async (path) => {
    setIsDownloadingImage(true);
    const image = sharedData.find((item) => item.path === path);
    let imageSource;
    try {
      if (!image) {
        const input = {
          buildingID,
          path: path
        };
        const result = await API.graphql(
          graphqlOperation(downloadRessourceStorm, { input: input })
        );
        if (result.data.downloadRessourceStorm.statusCode !== StatusCodes.OK) {
          throw new Error("Error downloading image");
        }
        imageSource = result.data.downloadRessourceStorm.body;
      } else {
        imageSource = image.url;
      }
      const response = await fetch(imageSource);
      if (response.status !== 200) {
        throw new Error("Error downloading image");
      }
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      if (image) {
        image.data = objectURL;
      }
      return objectURL;
    } catch (error) {
      throw new Error(`Error downloading image from ${path}.`); //TODO : check if error is caught
    } finally {
      setIsDownloadingImage(false);
    }
  };

  const getVisualsUsingRessource = async (deletedItem) => {
    try {
      let folderName = deletedItem.path.split("/").slice(0, -1).join("/");
      folderName = folderName === "" ? "" : folderName + "/";
      const input = {
        buildingID,
        folderName: folderName,
        imageName: deletedItem.type !== "folder" ? deletedItem.path.split("/").pop() : null,
      }

      const result = await API.graphql(
        graphqlOperation(getDeleteRessourcesStorm, { input: input })
      );
      if (result.data.getDeleteRessourcesStorm.statusCode === StatusCodes.BAD_REQUEST) {
        const visualsPaths = JSON.parse(result.data.getDeleteRessourcesStorm.body);
        return visualsPaths;
      } else if (result.data.getDeleteRessourcesStorm.statusCode === StatusCodes.NOT_FOUND) {
        return false;
      } else {
        throw new Error("Error checking if the image is used by a display");
      }
    } catch (error) {
      console.error("Error checking if the image is used by a display");
    }
  }

  const getFolder = (folderPath) => {
    let folder = fileStructure;
    if (!sharedData.find(item => item.path.startsWith(folderPath))) {
      folder = null;
    }
    else {
      const parts = folderPath.split('/').filter(Boolean);
      parts.shift();
      parts.forEach((part) => {
        folder = folder ? folder.content.find(item => item.name === part) : null;
      });
    }
    return folder;
  };

  const contextValue = {
    sharedData,
    fileStructure,
    buildingInfo,
    createFolder,
    getVisualsUsingRessource,
    deleteItem,
    saveImage,
    downloadImage,
    downloadXml,
    downloadFont,
    getFontNames,
    isDownloadingImage,
    isBusy,
    getFolder
  };

  return (
    <SharedContext.Provider value={contextValue}>
      {children}
      <Modal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}>
        <>
          <img
            src={require("../img/icon-material-warning@1x.png")}
            alt="warning sign"
          />
          <p>{errorMessage}</p>
        </>
      </Modal>
    </SharedContext.Provider>
  );
};

export const useSharedData = () => {
  const context = useContext(SharedContext);
  if (!context) {
    throw new Error('useSharedData must be used within a SharedProvider');
  }
  return context;
};