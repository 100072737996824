import React from "react";
import "./css/EditVisualRightPanel.css";
import ImageProperties from "./ImageProperties";
import PlaylistProperties from "./PlaylistProperties";
import ButtonProperties from "./ButtonProperties";
import PageProperties from "./PageProperties";
import { DISPLAY_ITEM_CATEGORY } from "../../constants/Fields";
import TravelInfoProperties from "./TravelInfoProperties";
import WidgetProperties from "./WidgetProperties";
import TextProperties from "./TextProperties";

const EditVisualRightPanel = ({
  selectedItem,
  pageProperties,
  onSelectedItemUpdated,
  onItemPropertyUpdated,
  category,
  onDeleteItem,
  onPlaylistImageClicked,
  onPagePropertyUpdated,
  buttons,
  onWidgetUpdated
}) => {

  let item = selectedItem && {
    ...selectedItem,
    x_value: String(selectedItem.x_value),
    y_value: String(selectedItem.y_value),
  }

  let itemPropertyComponent;

  switch (category) {
    case DISPLAY_ITEM_CATEGORY.IMAGE:
    case DISPLAY_ITEM_CATEGORY.BACKGROUND:
      itemPropertyComponent = (
        <ImageProperties
          selectedItem={selectedItem}
          onDeleteItem={onDeleteItem}
          onSelectedItemUpdated={onSelectedItemUpdated}
          onItemPropertyUpdated={onItemPropertyUpdated}
        />
      );
      break;
    case DISPLAY_ITEM_CATEGORY.PLAYLIST:
      if (selectedItem) {
        item.width = selectedItem.width;
        item.height = selectedItem.height;
      }
      itemPropertyComponent = (
        <PlaylistProperties
          selectedItem={selectedItem}
          onDeleteItem={onDeleteItem}
          onSelectedItemUpdated={onSelectedItemUpdated}
          onItemPropertyUpdated={onItemPropertyUpdated}
          onPlaylistImageClicked={onPlaylistImageClicked}
        />
      );
      break;
    case DISPLAY_ITEM_CATEGORY.BUTTON:
      itemPropertyComponent = (
        <ButtonProperties
          selectedItem={selectedItem}
          buttons={buttons}
          onSelectedItemUpdated={onSelectedItemUpdated}
          onItemPropertyUpdated={onItemPropertyUpdated}
        />
      );
      break;
    case DISPLAY_ITEM_CATEGORY.PAGE:
      itemPropertyComponent = (
        <PageProperties
          pageProperties={pageProperties}
          onPagePropertyUpdated={onPagePropertyUpdated}
        />
      );
      break;
    case DISPLAY_ITEM_CATEGORY.TRAVEL_INFO:
      selectedItem.digit = String(selectedItem.digit);
      itemPropertyComponent = (
        <TravelInfoProperties
          selectedItem={selectedItem}
          onItemPropertyUpdated={onItemPropertyUpdated}
          onSelectedItemUpdated={onSelectedItemUpdated}
        />
      );
      break;
    case (DISPLAY_ITEM_CATEGORY.WIDGET_METEO):
      itemPropertyComponent = (
        <WidgetProperties
          widget={selectedItem}
          widgetCategory={category}
          onItemPropertyUpdated={onItemPropertyUpdated}
          onWidgetUpdated={onWidgetUpdated}
        />
      );
      break;
    case(DISPLAY_ITEM_CATEGORY.WIDGET_HOUR):
      itemPropertyComponent = (
        <WidgetProperties
          widget={selectedItem}
          widgetCategory={category}
          onItemPropertyUpdated={onItemPropertyUpdated}
          onWidgetUpdated={onWidgetUpdated}
        />
      );
      break;
    case(DISPLAY_ITEM_CATEGORY.TEXT):
      itemPropertyComponent = 
        <TextProperties
          selectedItem={selectedItem}
          onSelectedItemUpdated={onSelectedItemUpdated}
        />
      break;
    default:
      break;
  }

  return (
    <div id="right-panel" className="edit-visual-pannel">
      { (selectedItem || category === DISPLAY_ITEM_CATEGORY.PAGE) &&
        itemPropertyComponent
      }
    </div>
  );
};

export default EditVisualRightPanel;
