import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Action from "../Action/Action";
import Modal from "../Modal/Modal";
import FirstResponderFormProvider from "./FirstResponderFormProvider";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const FirstResponderMenuItem = (props) => {
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const item = props.item;
  const schedule = item.Availability;

  const handleDelete = () => {
    props.onDelete(item.id);
    setDeleteModalOpen(false);
  };

  const handleUpdate = (data) => {
    data.id = item.id;
    props.onUpdate(data);
    setEditModalOpen(false);
  };

  const formatHours = (hoursArray) => {
    if (!hoursArray || hoursArray.length === 0) {
      return <div>Closed</div>;
    }

    return hoursArray.map((slot, index) => (
        <div key={index}>
          {slot.Start} - {slot.Finish}
        </div>
    ));
  };

  const actionOptions = [
    {
      text: "Edit",
      img: require("../../img/picto-edit-1.png"),
      onClickOption: () => setEditModalOpen(true),
    },
    {
      text: "Delete",
      img: require("../../img/picto-delete.png"),
      onClickOption: () => setDeleteModalOpen(true),
    },
  ];

  return (
    <>
      <tr className='responder-row' id={item.id}>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.Name}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>
              {
                item.Number?.length === 10 ? 
                `(${item.Number.slice(0, 3)}) ${item.Number.slice(3, 6)}-${item.Number.slice(6)}` 
                : item.Number
              }
            </div>
            <span className="separator"></span>
          </div>
        </td>
        {daysOfWeek.map((day) => (
          <td className="cell-container" key={day}>
            <div>
              <div className='table-cell'>{formatHours(schedule[day])}</div>
              <span className="separator"></span>
            </div>
          </td>
        ))}
        <td>
          <Action actionOptions={actionOptions}></Action>
          <div className='edit-responder-modal'>
            <Modal
              headerText={`EDIT RESPONDER - ${item.Name}`}
              isOpen={EditModalOpen}
              onClose={() => setEditModalOpen(false)}>
                <FirstResponderFormProvider
                  item={item}
                  onSubmit={handleUpdate}
                />
            </Modal>
          </div>
          <div className='confirm-modal'>
            <Modal
              isOpen={DeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              buttonText='Delete'
              buttonClick={handleDelete}>
                <>
                  <img
                    src={require("../../img/icon-material-warning@1x.png")}
                    alt='confirm delete'
                  />
                  <p>Are you sure you want to delete this responder?</p>
                </>
            </Modal>
          </div>
        </td>
      </tr>
    </>
  );
};

export default withTranslation()(FirstResponderMenuItem);
