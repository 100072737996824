import React, { useState, useRef, useEffect } from "react";
import errorImage from "../../img/error-image.svg";
import { PLAYLIST_MIN_SIZE } from "../../constants/DataValidation";
import { DISPLAY_XML_TYPE, DISPLAY_ITEM_CATEGORY, DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";
import {
    CORNER_SIZE,
    DEFAULT_TRAVEL_INFO_PATH,
    LANDSCAPE_VALUE,
    TEXT_FONT_DEFAULT_PATH,
    TRAVEL_INFO_ARROW_POSITION,
    TRAVEL_INFO_DEFAULT_DIGIT_WIDTH,
    TRAVEL_INFO_DEFAULT_HEIGHT,
    TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION
} from "../../constants/DefaultValues";


const Canvas = ({
    page,
    screenResolution,
    resolutionRatio,
    onItemClicked,
    onItemMoved,
    triggerRedraw,
    selectedItem,
    onSelectedItemUpdated,
    rotation,
    previewMode
}) => {
    const canvasRef = useRef();
    const canvasContainerRef = useRef();
    const [context, setContext] = useState();
    const [width, setWidth] = useState(480);
    const [height, setHeight] = useState(270);
    const [currentPage, setCurrentPage] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isHoveringOnCorner, setIsHoveringOnCorner] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);

    useEffect(() => {
        if (!page && context) {
            const scaledWidth = width * zoomLevel;
            const scaledHeight = height * zoomLevel;
            canvasRef.current.width = scaledWidth;
            canvasRef.current.height = scaledHeight;
            context.clearRect(0, 0, scaledWidth, scaledHeight);
        } else if (page) {
            if (page.Image) {
                initImagesSprites();
            }
            if (page.Playlist) {
                initPlaylistsImagesSprites();
            }
            if (page.Button) {
                initButtonsSprites();
            }
            if (page.Travel_Info) {
                initTravelInfoSprites();
            }
            if (page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
                initWidgetSprites(DISPLAY_ITEM_CATEGORY.WIDGET_METEO);
            }
            if (page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
                initWidgetSprites(DISPLAY_ITEM_CATEGORY.WIDGET_HOUR);
            }
        }
    }, [triggerRedraw]);

    useEffect(() => {
        setCurrentPage(page);
        if (page) {
            setWidth(page[DISPLAY_XML_ATTRIBUTE.ROTATION] === LANDSCAPE_VALUE ? screenResolution.x * resolutionRatio : screenResolution.y * resolutionRatio);
            setHeight(page[DISPLAY_XML_ATTRIBUTE.ROTATION] === LANDSCAPE_VALUE ? screenResolution.y * resolutionRatio : screenResolution.x * resolutionRatio);
            setContext(canvasRef.current.getContext("2d"));
            if (page.Image) {
                initImagesSprites();
            }
            if (page.Playlist) {
                initPlaylistsImagesSprites();
            }
            if (page.Button) {
                initButtonsSprites();
            }
            if (page.Travel_Info) {
                initTravelInfoSprites();
            }
            if (page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]) {
                initWidgetSprites(DISPLAY_ITEM_CATEGORY.WIDGET_METEO);
            }
            if (page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]) {
                initWidgetSprites(DISPLAY_ITEM_CATEGORY.WIDGET_HOUR);
            }
            if (context && currentPage && page["@_id"] !== currentPage["@_id"]) { //clears canvas when page is changed
                const scaledWidth = width * zoomLevel;
                const scaledHeight = height * zoomLevel;
                canvasRef.current.width = scaledWidth;
                canvasRef.current.height = scaledHeight;
                context.clearRect(0, 0, scaledWidth, scaledHeight);
            }
        }
    }, [page, resolutionRatio, rotation]);

    function initImagesSprites() {
        try {
            const newImages = [];
            for (const image of page.Image) {
                const img = new Image();
                if (image.unavailable) {
                    img.src = errorImage;
                } else {
                    img.src = image.data;
                }
                image.loaded = false;
                image.img = img;
                img.onload = () => {
                    image.loaded = true;
                    setCurrentPage({
                        ...page,
                        Image: [...newImages],
                    });
                }
                newImages.push(image);
            }
            setCurrentPage({
                ...page,
                Image: newImages,
            });
        } catch (error) {
            console.error("Error fetching image data:", error);
        }
    }

    function initPlaylistsImagesSprites() {
        try {
            const newPlaylists = [];
            for (const playlist of page.Playlist) {
                const newImages = [];
                for (const image of playlist.Image) {
                    const img = new Image();
                    if (image.unavailable) {
                        img.src = errorImage;
                    } else {
                        img.src = image.data;
                    }
                    image.loaded = false;
                    image.img = img;
                    img.onload = () => {
                        image.loaded = true;
                        setCurrentPage({
                            ...page,
                            Playlist: [...newPlaylists],
                        });
                    }
                    newImages.push(image);
                }
                playlist.Image = newImages;
                newPlaylists.push(playlist);
            }
            setCurrentPage({
                ...page,
                Playlist: newPlaylists,
            });
        } catch (error) {
            console.error("Error fetching image data:", error);
        }
    }

    function initButtonsSprites() {
        try {
            const newButtons = [];
            for (const button of page.Button) {
                const img = new Image();
                if (button.isPressed) {
                    img.src = button.pressedUnavailable ? errorImage : button.pressedData;
                } else {
                    img.src = button.unpressedUnavailable ? errorImage : button.unpressedData;
                }
                button.loaded = false;
                button.img = img;
                img.onload = () => {
                    button.loaded = true;
                    button.width = img.width;
                    button.height = img.height;
                    setCurrentPage({
                        ...page,
                        Button: [...newButtons],
                    });
                }
                newButtons.push(button);
            }
            setCurrentPage({
                ...page,
                Button: newButtons,
            });
        } catch (error) {
            console.error("Error fetching button data:", error);
        }
    }

    function initTravelInfoSprites() {
        try {
            const newTravelInfo = [];
            for (const travelInfo of page.Travel_Info) {
                const img = new Image();
                img.src = travelInfo.data;
                travelInfo.loaded = false;
                travelInfo.img = img;
                img.onload = () => {
                    travelInfo.loaded = true;
                    handleLoadedTravelInfo(travelInfo, newTravelInfo);
                };

                const arrowImg = new Image();
                const source = travelInfo.source.replace(DEFAULT_TRAVEL_INFO_PATH, "");
                const sourceWithoutExtension = source.substring(0, source.length - 4);
                const color = sourceWithoutExtension.split("_")[2];
                arrowImg.src = travelInfo.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                    ? `/img/travel_info/Arrow_${color}_Down.png`
                    : `/img/travel_info/Arrow_${color}.png`;
                travelInfo.arrowImg = arrowImg;
                arrowImg.onload = () => {
                    travelInfo.arrowLoaded = true;
                    handleLoadedTravelInfo(travelInfo, newTravelInfo);
                };

                newTravelInfo.push(travelInfo);
            }
            setCurrentPage({
                ...page,
                Travel_Info: newTravelInfo,
            });
        } catch (error) {
            console.error("Error fetching travel info data:", error);
        }
    }

    function handleLoadedTravelInfo(travelInfo, newTravelInfo) {
        if (travelInfo.loaded && travelInfo.arrowLoaded) {
            const fontRatio = travelInfo.source
                .replace(DEFAULT_TRAVEL_INFO_PATH, "")
                .split("_")[1] / TRAVEL_INFO_DEFAULT_HEIGHT;
            travelInfo.width = travelInfo.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                ? (travelInfo.img.width * (travelInfo.digit * 1 / 3)) * fontRatio
                : (travelInfo.img.width * (travelInfo.digit * 1 / 3) + travelInfo.arrowImg.width) * fontRatio;
            travelInfo.height = travelInfo.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                ? (travelInfo.img.height + (TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION * travelInfo.arrowImg.height)) * fontRatio
                : travelInfo.img.height * fontRatio;

            setCurrentPage(prevPage => ({
                ...prevPage,
                Travel_Info: [...newTravelInfo],
            }));
        }
    }


    function initWidgetSprites(WidgetCategory) {
        try {
            const newWidget = [];
            for (const widget of page[WidgetCategory]) {
                const img = new Image();
                if (widget.unavailable) {
                    img.src = errorImage;
                } else {
                    img.src = widget.data;
                }
                widget.loaded = false;
                widget.img = img;
                img.onload = () => {
                    widget.loaded = true;
                    setCurrentPage({
                        ...page,
                        [WidgetCategory]: [...newWidget],
                    });
                }
                newWidget.push(widget);
            }
            setCurrentPage({
                ...page,
                [WidgetCategory]: newWidget,
            });
        } catch (error) {
            console.error("Error fetching meteo data:", error);
        }
    }

    useEffect(() => {
        if (isReadyToDraw() && context) {
            drawItemsOnCanvas();
            if (!previewMode) {
                drawGrid();
            }
            if (canvasRef.current) {
                canvasRef.current.addEventListener("mousedown", handleMouseDown);
                document.addEventListener("mouseup", handleMouseUp);
            }
            return () => {
                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("mousedown", handleMouseDown);
                }
                document.removeEventListener("mouseup", handleMouseUp);
            }
        }
    }, [currentPage, zoomLevel, previewMode]);

    function isReadyToDraw() {
        let readyToDraw = currentPage !== undefined && context !== undefined;
        if (readyToDraw) {
            const unloadedImage = currentPage.Image?.find((img) => img.source && !img.loaded);
            readyToDraw = unloadedImage === undefined;
            const playlistWithUnloadedImage = currentPage.Playlist?.find(playlist => playlist.Image.find(image => image.source && !image.loaded));
            readyToDraw = readyToDraw && !playlistWithUnloadedImage;
            const unloadedButton = currentPage.Button?.find((button) => !button.loaded);
            readyToDraw = readyToDraw && unloadedButton === undefined;
            const unloadedTravelInfo = currentPage.Travel_Info?.find((travelInfo) => !travelInfo.loaded || !travelInfo.arrowLoaded);
            readyToDraw = readyToDraw && unloadedTravelInfo === undefined;
            const unloadedMeteo = currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]?.find((meteo) => !meteo.loaded);
            readyToDraw = readyToDraw && unloadedMeteo === undefined;
            const unloadedHourWidget = currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]?.find((hour) => !hour.loaded);
            readyToDraw = readyToDraw && unloadedHourWidget === undefined;
        }
        return readyToDraw;
    }

    useEffect(() => {
        if (currentPage) {
            canvasRef.current.addEventListener("mousemove", handleMouseMove);

            return () => {
                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("mousemove", handleMouseMove);
                }
            };
        }
    }, [context, isDragging, isHoveringOnCorner, currentPage, zoomLevel, previewMode]);

    useEffect(() => {
        if (selectedItem && currentPage) {
            setCurrentPage({
                ...currentPage,
                Image: [...page.Image],
                Playlist: [...page.Playlist],
                Button: [...page.Button],
                Travel_Info: [...page.Travel_Info],
                [DISPLAY_ITEM_CATEGORY.WIDGET_METEO]: [...page[DISPLAY_ITEM_CATEGORY.WIDGET_METEO]],
                [DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]: [...page[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]],
                [DISPLAY_ITEM_CATEGORY.TEXT]: [...page[DISPLAY_ITEM_CATEGORY.TEXT]],
            });
        }
    }, [selectedItem]);


    function handleMouseDown(e) {
        const mouseX = (e.clientX - canvasRef.current.getBoundingClientRect().left) / zoomLevel;
        const mouseY = (e.clientY - canvasRef.current.getBoundingClientRect().top) / zoomLevel;
        let clickedItemId;
        // Check which image was clicked
        const movableItems = [
            ...currentPage.Image,
            ...currentPage.Playlist,
            ...currentPage.Button,
            ...currentPage.Travel_Info,
            ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_METEO],
            ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR],
            ...currentPage[DISPLAY_ITEM_CATEGORY.TEXT]
        ];
        if (selectedItem && !movableItems.find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID])) {
            movableItems.push(selectedItem);
        }
        movableItems.sort((a, b) => a[DISPLAY_XML_ATTRIBUTE.ID] - b[DISPLAY_XML_ATTRIBUTE.ID]);
        movableItems.forEach((item) => {
            let itemWidth = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? item.img.width
                : item.width;
            let itemHeight = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? item.img.height
                : item.height;
            if (
                mouseX >= item.x_value * resolutionRatio && mouseX <= item.x_value * resolutionRatio + itemWidth * resolutionRatio &&
                mouseY >= item.y_value * resolutionRatio && mouseY <= item.y_value * resolutionRatio + itemHeight * resolutionRatio
            ) {
                clickedItemId = item[DISPLAY_XML_ATTRIBUTE.ID];
            }
        });
        if (clickedItemId !== undefined) {
            const selectedItemCategory = Object.keys(page).find(key => {
                if (Array.isArray(page[key])) {
                    const item = page[key].find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === clickedItemId);
                    return item;
                }
            });
            const itemClicked = movableItems.find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === clickedItemId);
            if (!previewMode) {
                setIsDragging(true);
                setOffsetX((e.clientX - itemClicked.x_value * resolutionRatio * zoomLevel));
                setOffsetY((e.clientY - itemClicked.y_value * resolutionRatio * zoomLevel));
                onItemClicked(selectedItemCategory, clickedItemId);
            } else {
                if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
                    onSelectedItemUpdated(itemClicked);
                }
            }
        } else {
            onItemClicked(null, null);
        }
        if (document.activeElement.tagName !== "INPUT") { // Allows focused input to be unfocused
            e.preventDefault(); // Prevents selecting text when dragging outisde the canvas
        }
    }

    function handleMouseUp(e) {
        setIsDragging(false);
    }

    function handleMouseMove(e) {
        if (isDragging && selectedItem?.type !== DISPLAY_XML_TYPE.BACKGROUND) {
            if (!isHoveringOnCorner) {
                moveSelectedItem(e);
            } else {
                resizeSelectedItem(e);
            }
        } else if (!isDragging) {
            handleHoveredItem(e);
        }
    }

    function moveSelectedItem(e) {
        if (selectedItem) {
            let movedItemWidth = (selectedItem.img && selectedItem.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? selectedItem.img.width
                : selectedItem.width;
            let movedItemHeight = (selectedItem.img && selectedItem.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? selectedItem.img.height
                : selectedItem.height;

            // // Adjust width and height according to zoomLevel
            // movedItemWidth *= zoomLevel;
            // movedItemHeight *= zoomLevel;

            let newX = Math.max(0, Math.min((e.clientX - offsetX) / zoomLevel, width - movedItemWidth * resolutionRatio));
            let newY = Math.max(0, Math.min((e.clientY - offsetY) / zoomLevel, height - movedItemHeight * resolutionRatio));

            if (e.type === "wheel") {
                newY = Math.max(0, Math.min(newY + e.deltaY, height - movedItemHeight * resolutionRatio));
            }
            const movedItem = {
                ...selectedItem,
                x_value: Math.round(newX / resolutionRatio),
                y_value: Math.round(newY / resolutionRatio)
            }
            updateCurrentPage(movedItem);
            onItemMoved(movedItem);
        }
    }

    function handleHoveredItem(e) {
        const mouseX = (e.clientX - canvasRef.current.getBoundingClientRect().left) / zoomLevel;
        const mouseY = (e.clientY - canvasRef.current.getBoundingClientRect().top) / zoomLevel;
        const movableItems = [
            ...currentPage.Image,
            ...currentPage.Playlist,
            ...currentPage.Button,
            ...currentPage.Travel_Info,
            ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_METEO],
            ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR],
            ...currentPage[DISPLAY_ITEM_CATEGORY.TEXT]
        ];
        let hoveredItem = findHoveredItem(mouseX, mouseY, movableItems);

        if (hoveredItem !== undefined) {
            updateCanvasCursor(hoveredItem, mouseX, mouseY);
        } else {
            canvasRef.current.style.cursor = "default";
        }
    }

    function updateCanvasCursor(hoveredItem, mouseX, mouseY) {
        const hoveredItemCategory = getItemCategory(hoveredItem);
        if (previewMode && hoveredItemCategory === DISPLAY_ITEM_CATEGORY.BUTTON) {
            canvasRef.current.style.cursor = "pointer";
        } else if (previewMode) {
            canvasRef.current.style.cursor = "default";
        } else if (hoveredItemCategory === DISPLAY_ITEM_CATEGORY.PLAYLIST) {
            const { x_value, y_value, img } = hoveredItem;
            let itemWidth = img ? img.width : hoveredItem.width;
            let itemHeight = img ? img.height : hoveredItem.height;
            const x_left = x_value * resolutionRatio;
            const x_right = x_left + itemWidth * resolutionRatio;
            const y_top = y_value * resolutionRatio;
            const y_bottom = y_top + itemHeight * resolutionRatio;

            const isOnTopLeftCorner = mouseX >= x_left && mouseX <= x_left + CORNER_SIZE && mouseY >= y_top && mouseY <= y_top + CORNER_SIZE;
            const isOnTopRightCorner = mouseX >= x_right - CORNER_SIZE && mouseX <= x_right && mouseY >= y_top && mouseY <= y_top + CORNER_SIZE;
            const isOnBottomLeftCorner = mouseX >= x_left && mouseX <= x_left + CORNER_SIZE && mouseY >= y_bottom - CORNER_SIZE && mouseY <= y_bottom;
            const isOnBottomRightCorner = mouseX >= x_right - CORNER_SIZE && mouseX <= x_right && mouseY >= y_bottom - CORNER_SIZE && mouseY <= y_bottom;

            if (isOnBottomLeftCorner) {
                canvasRef.current.style.cursor = "nesw-resize";
                setIsHoveringOnCorner("bottom-left");
            } else if (isOnBottomRightCorner) {
                canvasRef.current.style.cursor = "nwse-resize";
                setIsHoveringOnCorner("bottom-right");
            } else if (isOnTopLeftCorner) {
                canvasRef.current.style.cursor = "nwse-resize";
                setIsHoveringOnCorner("top-left");
            } else if (isOnTopRightCorner) {
                canvasRef.current.style.cursor = "nesw-resize";
                setIsHoveringOnCorner("top-right");
            } else {
                canvasRef.current.style.cursor = "move";
                setIsHoveringOnCorner(null);
            }

        } else {
            setIsHoveringOnCorner(null);

            if (hoveredItemCategory === DISPLAY_ITEM_CATEGORY.IMAGE && hoveredItem.type === DISPLAY_XML_TYPE.BACKGROUND) {
                canvasRef.current.style.cursor = "pointer";
            } else {
                canvasRef.current.style.cursor = "move";
            }
        }
    }

    function findHoveredItem(mouseX, mouseY, movableItems) {
        let hoveredItemId;
        if (selectedItem && !movableItems.find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID])) {
            movableItems.push(selectedItem);
        }
        movableItems.sort((a, b) => a[DISPLAY_XML_ATTRIBUTE.ID] - b[DISPLAY_XML_ATTRIBUTE.ID]);
        movableItems.forEach((item) => {
            let itemWidth = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? item.img.width
                : item.width;
            let itemHeight = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
                ? item.img.height
                : item.height;

            if (
                mouseX >= item.x_value * resolutionRatio && mouseX <= item.x_value * resolutionRatio + itemWidth * resolutionRatio &&
                mouseY >= item.y_value * resolutionRatio && mouseY <= item.y_value * resolutionRatio + itemHeight * resolutionRatio
            ) {
                hoveredItemId = item[DISPLAY_XML_ATTRIBUTE.ID];
            }
        });
        return movableItems.find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === hoveredItemId);
    }

    function getItemCategory(item) {
        return Object.keys(page).find(key => {
            if (Array.isArray(page[key])) {
                const foundItem = page[key].find(i => i[DISPLAY_XML_ATTRIBUTE.ID] === item[DISPLAY_XML_ATTRIBUTE.ID]);
                return foundItem;
            }
        });
    }

    function updateCurrentPage(movedItem) {
        const selectedItemCategory = getItemCategory(movedItem);
        setCurrentPage({
            ...currentPage,
            [selectedItemCategory]: currentPage[selectedItemCategory].map(item => item[DISPLAY_XML_ATTRIBUTE.ID] === movedItem[DISPLAY_XML_ATTRIBUTE.ID] ? movedItem : item)
        });
    }

    function resizeSelectedItem(e) {
        const mouseX = (e.clientX - canvasRef.current.getBoundingClientRect().left) / zoomLevel;
        const mouseY = (e.clientY - canvasRef.current.getBoundingClientRect().top) / zoomLevel;
        const selectedItemCategory = getItemCategory(selectedItem);

        let resizedItem;
        if (selectedItemCategory === DISPLAY_ITEM_CATEGORY.PLAYLIST) {
            const newX = Math.round(mouseX / resolutionRatio);
            const newY = Math.round(mouseY / resolutionRatio);
            const deltaX = newX - selectedItem.x_value;
            const deltaY = newY - selectedItem.y_value;

            switch (isHoveringOnCorner) {
                case "top-left":
                    resizedItem = {
                        ...selectedItem,
                        x_value: newX,
                        y_value: newY,
                        width: Math.max(PLAYLIST_MIN_SIZE, selectedItem.width - deltaX),
                        height: Math.max(PLAYLIST_MIN_SIZE, selectedItem.height - deltaY)
                    };
                    break;
                case "top-right":
                    resizedItem = {
                        ...selectedItem,
                        y_value: newY,
                        width: Math.max(PLAYLIST_MIN_SIZE, deltaX),
                        height: Math.max(PLAYLIST_MIN_SIZE, selectedItem.height - deltaY)
                    };
                    break;
                case "bottom-left":
                    resizedItem = {
                        ...selectedItem,
                        x_value: newX,
                        width: Math.max(PLAYLIST_MIN_SIZE, selectedItem.width - deltaX),
                        height: Math.max(PLAYLIST_MIN_SIZE, deltaY)
                    };
                    break;
                case "bottom-right":
                    resizedItem = {
                        ...selectedItem,
                        width: Math.max(PLAYLIST_MIN_SIZE, deltaX),
                        height: Math.max(PLAYLIST_MIN_SIZE, deltaY)
                    };
                    break;
                default:
                    resizedItem = selectedItem;
                    break;
            }
        } else {
            resizedItem = selectedItem;
        }

        if (resizedItem[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]) {
            updateCurrentPage(resizedItem);
        }
        onSelectedItemUpdated(resizedItem);
    }

    function drawItemsOnCanvas() {
        if (context) {
            const scaledWidth = width * zoomLevel;
            const scaledHeight = height * zoomLevel;
            canvasRef.current.width = scaledWidth;
            canvasRef.current.height = scaledHeight;
            context.clearRect(0, 0, scaledWidth, scaledHeight);
            context.save(); // Save current state
            context.scale(zoomLevel, zoomLevel); // Apply zoom level
            drawItems();
            if (selectedItem) {
                const items = [
                    ...currentPage.Image,
                    ...currentPage.Playlist,
                    ...currentPage.Button,
                    ...currentPage.Travel_Info,
                    ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_METEO],
                    ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR],
                    ...currentPage[DISPLAY_ITEM_CATEGORY.TEXT]
                ];
                const updatedSelectedItem = items.find(item => item[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
                if (!previewMode && updatedSelectedItem) {
                    drawOverlay(updatedSelectedItem);
                }
            }
            context.restore(); // Restore original state
        }
    }

    function drawItems() {
        try {
            const itemsToDraw = [
                ...currentPage.Image,
                ...currentPage.Playlist,
                ...currentPage.Button,
                ...currentPage.Travel_Info,
                ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_METEO],
                ...currentPage[DISPLAY_ITEM_CATEGORY.WIDGET_HOUR],
                ...currentPage[DISPLAY_ITEM_CATEGORY.TEXT]
            ];
            itemsToDraw.sort((a, b) => a[DISPLAY_XML_ATTRIBUTE.ID] - b[DISPLAY_XML_ATTRIBUTE.ID]); // items will be drawn in the order of their id
            itemsToDraw.forEach(item => {
                if (item.img && item.loaded) {
                    if (item.type === DISPLAY_XML_TYPE.TRAVEL_INFO) {
                        drawPositionIndicator(item);
                    } else {
                        drawTransparencyLayerBackground(item);
                        context.drawImage(
                            item.img,
                            item.x_value * resolutionRatio,
                            item.y_value * resolutionRatio,
                            item.img.width * resolutionRatio,
                            item.img.height * resolutionRatio
                        );
                    }
                } else if (item.Image) { // It's a playlist
                    drawTransparencyLayerBackground(item);
                    context.save();
                    context.beginPath();
                    context.rect(
                        item.x_value * resolutionRatio,
                        item.y_value * resolutionRatio,
                        item.width * resolutionRatio,
                        item.height * resolutionRatio
                    );
                    context.clip();
                    if (item.Image?.length > 0) {
                        const CURRENT_PLAYLIST_IMAGE_INDEX = item.selectedImageId
                            ? item.Image.findIndex(image => image[DISPLAY_XML_ATTRIBUTE.ID] === item.selectedImageId)
                            : 0;
                        context.drawImage(
                            item.Image[CURRENT_PLAYLIST_IMAGE_INDEX].img,
                            item.x_value * resolutionRatio,
                            item.y_value * resolutionRatio,
                            item.Image[CURRENT_PLAYLIST_IMAGE_INDEX].img.width * resolutionRatio,
                            item.Image[CURRENT_PLAYLIST_IMAGE_INDEX].img.height * resolutionRatio
                        );
                    }
                    context.restore();
                } else if (item.type !== DISPLAY_XML_TYPE.IMAGE && item.type !== DISPLAY_XML_TYPE.BACKGROUND) { // It's text
                    context.textBaseline = "top";

                    const fontSizeInPx = item.font_size * (96 / 72);
                    if (item.errors.length > 0) {
                        const fontSizeInPx = item.font_size * (96 / 72);
                        context.font = `${fontSizeInPx * resolutionRatio}px Arial`;
                        context.fillText(item.content, item.x_value * resolutionRatio, item.y_value * resolutionRatio);
                        const textMetrics = context.measureText(item.content);
                        item.width = textMetrics.width / resolutionRatio;
                        item.height = textMetrics.actualBoundingBoxDescent / resolutionRatio;
                    }
                    else if (item.fontFace.status === "loaded") {
                        let fontName = item.font_source.replace(TEXT_FONT_DEFAULT_PATH, "").replace(".ttf", "");
                        context.font = `${fontSizeInPx * resolutionRatio}px ${fontName}`;
                        const textMetrics = context.measureText(item.content);
                        const adjustedOffset = adjustFontX(item.font_size);
                        item.width = (textMetrics.width + adjustedOffset) / resolutionRatio;
                        item.height = textMetrics.actualBoundingBoxDescent / resolutionRatio;
                        drawTransparencyLayerBackground(item);
                        context.fillStyle = item.color;
                        context.fillText(
                            item.content,
                            (item.x_value + adjustedOffset) * resolutionRatio,
                            item.y_value * resolutionRatio
                        );
                    }
                }
                if (!previewMode && (!selectedItem || item[DISPLAY_XML_ATTRIBUTE.ID] !== selectedItem[DISPLAY_XML_ATTRIBUTE.ID])) {
                    drawItemBorders(item);
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    function drawTransparencyLayerBackground(item) {
        const background = currentPage.Image.find(image => image.type === DISPLAY_XML_TYPE.BACKGROUND && image.loaded);
        const width = item.img ? item.img.width : item.width;
        const height = item.img ? item.img.height : item.height;

        if (background) {
            context.drawImage(
                background.img,
                item.x_value, item.y_value,
                width, height,
                item.x_value * resolutionRatio, item.y_value * resolutionRatio,
                width * resolutionRatio, height * resolutionRatio
            );
        fillGreyArea(item, width, height, background);
        }
    }

    function adjustFontX(fontSize) {
        if (fontSize <= 8) return 3;
        else if (fontSize <= 10) return 3;
        else if (fontSize <= 12) return 3;
        else if (fontSize <= 16) return 6;
        else if (fontSize <= 20) return 7;
        else if (fontSize <= 24) return 7;
        else if (fontSize <= 28) return 9;
        else if (fontSize <= 36) return 11;
        else if (fontSize <= 48) return 13;
        else if (fontSize <= 72) return 17;

        return 0;
    }

    function drawItemBorders(item) {
        context.strokeStyle = (item.unavailable || item.errors?.length > 0) ? "rgb(255, 0, 0, 1)" : "rgb(82, 166, 40, 1)";
        context.lineWidth = 1;
        context.setLineDash([5, 5]);
        let itemWidth = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
            ? item.img.width
            : item.width;
        let itemHeight = (item.img && item.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
            ? item.img.height
            : item.height;

        context.strokeRect(
            (item.x_value) * resolutionRatio,
            (item.y_value) * resolutionRatio,
            (itemWidth) * resolutionRatio,
            (itemHeight) * resolutionRatio
        );
    }

    function drawPositionIndicator(item) {
        let sx = 0;
        let sy = 0;
        let digitWidth = item.img.width / 3;
        const numberOfDigits = parseInt(item.digit);
        const source = item.source.replace(DEFAULT_TRAVEL_INFO_PATH, "");
        const sourceWithoutExtension = source.substring(0, source.length - 4);
        const fontSize = sourceWithoutExtension.split("_")[1];
        const fontRatio = fontSize / item.img.height;
        const dWidth = (digitWidth * numberOfDigits * fontRatio) * resolutionRatio;
        const dHeight = (item.img.height * fontRatio) * resolutionRatio;

        let sWidth = digitWidth * numberOfDigits;
        let sHeight = TRAVEL_INFO_DEFAULT_HEIGHT;

        let dx = item.arrow_position === TRAVEL_INFO_ARROW_POSITION.LEFT
            ? (item.x_value + (digitWidth * fontRatio)) * resolutionRatio
            : item.x_value * resolutionRatio;
        let dy = item.y_value * resolutionRatio;

        const background = currentPage.Image.find(image => image.type === DISPLAY_XML_TYPE.BACKGROUND && image.loaded);
        //draw background behind the travel info
        let bkgDWidth = item.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
            ? dWidth / resolutionRatio
            : item.width;
        let bkgDx = item.arrow_position === TRAVEL_INFO_ARROW_POSITION.LEFT
            ? item.x_value * resolutionRatio
            : dx;

        if (background) {
            context.drawImage(
                background.img,
                bkgDx / resolutionRatio, dy / resolutionRatio,
                bkgDWidth, item.height,
                bkgDx, dy,
                item.width * resolutionRatio, item.height * resolutionRatio
            );
            fillGreyArea(item, item.width, item.height, background);
        } else {
            context.fillStyle = "#9e9e9e";
            context.fillRect(
                dx, dy,
                dWidth, dHeight
            );
        }

        context.drawImage(
            item.img,
            sx, sy,
            sWidth, sHeight,
            dx, dy,
            dWidth, dHeight
        );

        const arrowWidth = (item.arrowImg.width * fontRatio) * resolutionRatio;
        let arrowHeight = (item.arrowImg.height * fontRatio) * resolutionRatio;
        let arrowX = item.x_value * resolutionRatio;
        let arrowY = item.y_value * resolutionRatio;

        if (item.arrow_position === TRAVEL_INFO_ARROW_POSITION.RIGHT) {
            arrowX = (item.x_value + (TRAVEL_INFO_DEFAULT_DIGIT_WIDTH * numberOfDigits * fontRatio)) * resolutionRatio;
        }

        if (item.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN) {
            arrowX = (item.x_value + item.width / 2) * resolutionRatio - (item.arrowImg.width / 2 * resolutionRatio * fontRatio);
            const yCentered = (item.y_value + (TRAVEL_INFO_DEFAULT_HEIGHT * fontRatio)) * resolutionRatio;
            arrowY = yCentered;

            let arrowSy = ((TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION / 2) * item.arrowImg.height);
            let arrowSHeight = item.arrowImg.height - (TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION * item.arrowImg.height);
            let arrowDHeight = (item.arrowImg.height - TRAVEL_INFO_DOWN_ARROW_HEIGHT_PROPORTION * item.arrowImg.height) * resolutionRatio * fontRatio;

            context.drawImage(
                item.arrowImg,
                0, arrowSy,
                item.arrowImg.width, arrowSHeight,
                arrowX, arrowY,
                arrowWidth, arrowDHeight
            );
        } else {
            context.drawImage(
                item.arrowImg,
                arrowX, arrowY,
                arrowWidth, arrowHeight,
            );
        }
    }

    function fillGreyArea(item, width, height, background) {
        let greyX, greyY, greyW, greyHeight;
    
        const imgWidth = background.img.width;
        const imgHeight = background.img.height;
    
        if (item.x_value + width > imgWidth) {
            greyX = item.x_value > imgWidth
                ? item.x_value * resolutionRatio
                : (item.x_value + (imgWidth - item.x_value)) * resolutionRatio;
            greyY = item.y_value * resolutionRatio;
    
            greyW = item.x_value > imgWidth
                ? width * resolutionRatio
                : (width - (imgWidth - item.x_value)) * resolutionRatio;
            greyHeight = height * resolutionRatio;
    
            context.fillStyle = "#9e9e9e";
            context.fillRect(greyX, greyY, greyW, greyHeight);
        }
    
        if (item.y_value + height > imgHeight) {
            if (item.y_value > imgHeight) {
                greyY = item.y_value * resolutionRatio;
                greyHeight = height * resolutionRatio;
            } else {
                greyY = (item.y_value + (imgHeight - item.y_value)) * resolutionRatio;
                greyHeight = (height - (imgHeight - item.y_value)) * resolutionRatio;
            }
    
            greyX = item.x_value * resolutionRatio;
            greyW = width * resolutionRatio;
    
            context.fillStyle = "#9e9e9e";
            context.fillRect(greyX, greyY, greyW, greyHeight);
        }
    }

    function drawGrid() {
        const scaledWidth = width * zoomLevel;
        const scaledHeight = height * zoomLevel;
        context.strokeStyle = "rgb(200, 200, 200, 0.5)";
        context.lineWidth = 0.5;
        context.setLineDash([]);
        context.beginPath();
        for (let x = 0; x < scaledWidth; x += 20) {
            context.moveTo(x, 0);
            context.lineTo(x, scaledHeight);
        }
        for (let y = 0; y < scaledHeight; y += 20) {
            context.moveTo(0, y);
            context.lineTo(scaledWidth, y);
        }
        context.stroke();
        // context.restore(); // Restore original state
    }

    function drawOverlay(overlayedItem) {
        context.strokeStyle = (overlayedItem.unavailable || overlayedItem.errors?.length > 0) ? "rgb(194, 17, 35, 0.5)" : "rgb(82, 166, 40, 0.5)";
        context.fillStyle = (overlayedItem.unavailable || overlayedItem.errors?.length > 0) ? "rgb(194, 17, 35, 0.5)" : "rgb(82, 166, 40, 0.5)";
        context.setLineDash([]);

        let overlayedItemWidth = (overlayedItem.img && overlayedItem.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
            ? overlayedItem.img.width
            : overlayedItem.width;
        let overlayedItemHeight = (overlayedItem.img && overlayedItem.type !== DISPLAY_XML_TYPE.TRAVEL_INFO)
            ? overlayedItem.img.height
            : overlayedItem.height;

        context.strokeRect(
            (overlayedItem.x_value) * resolutionRatio,
            (overlayedItem.y_value) * resolutionRatio,
            (overlayedItemWidth) * resolutionRatio,
            (overlayedItemHeight) * resolutionRatio
        );
        context.fillRect(
            (overlayedItem.x_value) * resolutionRatio,
            (overlayedItem.y_value) * resolutionRatio,
            (overlayedItemWidth) * resolutionRatio,
            (overlayedItemHeight) * resolutionRatio
        );

        if (overlayedItem.Image) {
            drawResizeHandles(overlayedItem);
        }
    }

    function drawResizeHandles(item) {
        context.fillStyle = "blue";
        context.fillRect(item.x_value * resolutionRatio, item.y_value * resolutionRatio, CORNER_SIZE, CORNER_SIZE); // top-left corner
        context.fillRect((item.x_value + item.width) * resolutionRatio - CORNER_SIZE, item.y_value * resolutionRatio, CORNER_SIZE, CORNER_SIZE); // top-right corner
        context.fillRect(item.x_value * resolutionRatio, (item.y_value + item.height) * resolutionRatio - CORNER_SIZE, CORNER_SIZE, CORNER_SIZE); // bottom-left corner
        context.fillRect((item.x_value + item.width) * resolutionRatio - CORNER_SIZE, (item.y_value + item.height) * resolutionRatio - CORNER_SIZE, CORNER_SIZE, CORNER_SIZE); // bottom-right corner
    }

    // Function to handle zoom in
    const zoomIn = () => {
        if (zoomLevel < 4) {
            setZoomLevel(prevZoom => prevZoom + 0.1); // Increase zoom level by 0.1
        }
    };

    // Function to handle zoom out
    const zoomOut = () => {
        if (zoomLevel > 0.25) {
            setZoomLevel(prevZoom => prevZoom - 0.1); // Decrease zoom level by 0.1, ensuring it doesn't go below 0.1
        }
    };

    const handleWheel = (e) => {
        if (e.ctrlKey) {
            e.preventDefault();
            if (e.deltaY > 0) {
                zoomOut();
            } else {
                zoomIn();
            }
        } else if (isDragging) {
            setIsDragging(false);
        }
    };

    useEffect(() => {
        canvasContainerRef.current.addEventListener('wheel', function (event) {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        }, { passive: false });
    }, []);

    return (
        <div onWheel={handleWheel} style={{ position: "relative" }} ref={canvasContainerRef} id="canvas-container">
            <canvas data-testid="canvas" width={width} height={height} ref={canvasRef} id="canvas">Preview of the visual</canvas>
            <div id="zoom-level">
                <div id="zoom-controls">
                    <div id="zoom-buttons">
                        <button onClick={zoomIn}>+</button>
                        <button onClick={zoomOut}>-</button>
                    </div>
                    <p>{(zoomLevel * 100).toFixed(0)}%</p>
                </div>
            </div>
        </div>
    );
};

export default Canvas;
